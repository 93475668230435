import reparentCustomer from 'react';
import OnboardingDashboardCard from './OnboardingDashboardCard';
import AssetStatesRow from './AssetStatesRow';
import TrainingCountsRow from './TrainingCountsRow';
import AssetTrainingHistoryCard from './AssetTrainingHistoryCard';
import TrainingFaultsCard from './TrainingFaultsCard';
import Loader from '../../Loader/Loader';


function SharedOnboardingDashboard(props) {
    const { loading, showShowAllBtn, showAllTraining, assetType, onboardInfo, assetStateCounts, 
        onAssetsNeedTrainingClick, onTrainingReportClick, onAssetsTrainedClick, onTrainingHistoryClick, 
        onTrainingFaultsClick, onShowAllClick } = props;

    let assetsTrainedContent = <div className="row no-gutters card-row" style={{ textAlign: 'center' }}>
        <div className="col" style={{ minHeight: '40px' }}>
            <div className="row no-gutters dashboard-val" style={{ justifyContent: 'center' }}>
                <div className="col">
                    <div className="dashboard-number" style={{ fontSize: '2.25rem', paddingTop: '.25rem' }}>
                        {assetStateCounts.operational}
                    </div>
                </div>

            </div>
            <div>
                <small style={{ color: "transparent", fontSize: '80%', textAlign: 'center' }}>placeholder</small>
            </div>
        </div>
    </div>;

    let falsePositiveFaultsContent = <div className="row no-gutters card-row" style={{ textAlign: 'center', margin: '8px 0px' }}>
        <div className="col" style={{ minHeight: '40px' }}>
            <div style={{ margin: '0px 20px' }}>
                <table style={{ width: '100%', marginBottom: '0' }}>

                    <thead>
                        <tr className="alerts-table-row">
                            <th className="table-shrink" style={{ textAlign: 'left', padding: '0px 30px 10px' }}>
                                FAULT
                            </th>
                            <th className="table-shrink" style={{ textAlign: 'center', padding: '0px 0px 10px' }}>
                                #
                            </th>
                            <th className="table-shrink" style={{ textAlign: 'left', padding: '0px 10px 10px 40px' }}>
                                % (Trained past 24hrs)
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr className="alerts-table-row">
                            <td style={{ textAlign: 'left', padding: '8px 3px 8px 30px' }}> Contact Failure </td>
                            <td style={{ textAlign: 'center', padding: '8px 3px' }}> 4 </td>
                            <td style={{ textAlign: 'left', padding: '8px 3px 8px 40px' }}> 13% </td>
                        </tr>

                        <tr className="alerts-table-row">
                            <td style={{ textAlign: 'left', padding: '8px 3px 8px 30px' }}> Amp higher than... </td>
                            <td style={{ textAlign: 'center', padding: '8px 3px' }}> 3 </td>
                            <td style={{ textAlign: 'left', padding: '8px 3px 8px 40px' }}> 5% </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>;
    
    let contents = loading ?
            <Loader />
            : 
            <div>
                <AssetStatesRow title={`${assetType} Asset States`} assetStateCounts={assetStateCounts} onAssetsNeedTrainingClick={onAssetsNeedTrainingClick} />
                <div className="row" style={{ padding: '0px 30px 0px' }}>
                    <TrainingCountsRow onboardInfo={onboardInfo} onTrainingReportClick={onTrainingReportClick}/>
                    <div className="col-6" style={{ display: 'grid' }}>
                        <div>
                            <OnboardingDashboardCard title={`${assetType} Assets Trained`} content={assetsTrainedContent} onClick={onAssetsTrainedClick} isGraphCard={false}/>
                        </div>
                    </div>
                </div>
                <div className="row" style={{ padding: '0px 30px', height: '100%' }}>
                    <AssetTrainingHistoryCard assetType={assetType} onboardInfo={onboardInfo} onTrainingHistoryClick={onTrainingHistoryClick}/>
                    <TrainingFaultsCard assetType={assetType} onboardInfo={onboardInfo} onTrainingFaultsClick={onTrainingFaultsClick}/>
                </div>
            </div>;

        let showAllBtn = showShowAllBtn ?
            <div className="btn-right" style={{ padding: '25px 0px 0px 30px' }}>
                <button className={showAllTraining ? "primary-btn btn-large" : "secondary-btn btn-large"} style={{ width: 150 + 'px' }} onClick={onShowAllClick}>Show All</button>
            </div>
            : null;

    return (
        <div sx={(theme)=>({
            [theme.breakpoints.down('md')]: {
                marginLeft: '-225px !important',
            },
        })}>
            <div>
                <div className="row no-gutters">
                    <div className="col">
                        <h2 className="pageTitle dashboard-title" id="tabelLabel" >
                            {assetType} Onboarding
                        </h2>
                    </div>
                    <div className="col">
                        {showAllBtn}
                    </div>
                </div>

                {contents}

            </div>
        </div>
    );
}

export default SharedOnboardingDashboard;