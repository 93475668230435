/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import { PageView, Event } from "../GoogleAnalytics";
import PartnerService from "../../services/partner.service";
import { Equipment } from "../../componentObjects";
import SharedOnboardingDashboard from './Dashboards/SharedOnboardingDashboard';
import {withRouter} from "../Routing/withRouter";

const COLORS = ['#0033a0', '#3ed3c2', '#ff7555', '#ff9900', '#7d8ee2'];
const ASSET_TYPE = 'Air Conditioner'

class ACOnboarding extends Component {
    static displayName = ACOnboarding.name;
    _isMounted = false;

    constructor(props) {
        super(props);

        this.loadInfo = this.loadInfo.bind(this);
        this.populateInfo = this.populateInfo.bind(this);
        this.onTrainingReportClick = this.onTrainingReportClick.bind(this);
        this.onTrainingFaultsClick = this.onTrainingFaultsClick.bind(this);
        this.onShowAllClick = this.onShowAllClick.bind(this);

        this.state = {
            loading: false,
            onboardInfo: {},
            assetStateCounts: {},
            orgId: 1,
            showAllTraining: true,//false,
            showShowAllBtn: false
        }
    }

    componentDidMount() {
        this._isMounted = true;
        PageView();
        this.loadInfo();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.currentPartnerId !== this.props.currentPartnerId) {
            this.loadInfo();
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
    }

    loadInfo() {
            this.setState({ loading: true }, async () => {
                let orgId = this.props.currentPartnerId;
                let showBtn = true;
                let showAll = false;
                if (this.props.loggedInOrg !== "LifeWhere") {
                    showBtn = false;
                }
                else if (this.props.showAllTraining) {
                    showAll = true;
                    orgId = 1;
                }

                this.setState({
                    orgId: orgId,
                    showAllTraining: showAll,
                    showShowAllBtn: showBtn
                }, async () => {
                    await this.populateInfo();
                })
            });
    }

    async populateInfo() {
        console.log("populateInfo(), orgId: " + this.state.orgId);
        await PartnerService.getonboardinginfo(this.state.orgId, Equipment.AIR_CONDITIONER)
            .then(response => {
                if (this._isMounted) {
                    if (response != null && response !== {}) {
                        this.setState({ onboardInfo: response, assetStateCounts: response.assetStateCounts }, () => {
                            this.setState({ loading: false });
                        });
                    }
                    else {
                        this.setState({ loading: false });
                    }
                }

            })
            .catch(e => {
                console.log(e);
            });
    }

    onShowAllClick() {
        Event("Show All Clicked", "User clicked Show All in AC Onboarding Dashboard", "Show All Clicked");
        this.setState({ loading: true, showAllTraining: !this.state.showAllTraining }, () => {
            this.props.setShowAllTraining(this.state.showAllTraining);
            this.setState({ orgId: this.state.showAllTraining ? 1 : this.props.currentPartnerId }, async () => {
                await this.populateInfo();
            });
        });
    }

    onTrainingReportClick() {
        Event("Training Report Clicked", "User clicked the AC Training Report", "Training Report Clicked");
        this.props.setActivePath("/acAssetTrainingReview");
        this.props.navigate("/acAssetTrainingReview");
    }

    onTrainingFaultsClick() {
        Event("Training Faults Clicked", "User clicked the AC Training Faults", "Training Faults Clicked");
        this.props.setActivePath("/acTrainingAlertCountReview");
        this.props.navigate("/acTrainingAlertCountReview");
    }

    onChangePage(title) {
        this.setState({ pageName: title });
    }

    render() {

        return (
            <SharedOnboardingDashboard assetType={ASSET_TYPE} onboardInfo={this.state.onboardInfo} assetStateCounts={this.state.assetStateCounts} 
            loading={this.state.loading} showAllTraining={this.state.showAllTraining} showShowAllBtn={this.state.showShowAllBtn} 
            onAssetsNeedTrainingClick={this.onAssetsNeedTrainingClick} onTrainingReportClick={this.onTrainingReportClick} onAssetsTrainedClick={this.onAssetsTrainedClick} 
            onTrainingHistoryClick={this.onTrainingHistoryClick} onTrainingFaultsClick={this.onTrainingFaultsClick} onShowAllClick={this.onShowAllClick} />

        );
    }
}

export default withRouter(ACOnboarding);