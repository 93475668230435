import React, {useState} from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';


function OnboardingDashboardCard(props) {
    const { title, content, onClick, isGraphCard } = props;

    let cardContent = isGraphCard ? 
                <Card elevation={4} style={{ height: '100%' }}>
                    <CardActionArea style={{ height: '100%' }}>
                        <CardContent style={{ padding: '10px 0px', textAlign: 'center' }}>
                            <h6 style={{ paddingLeft: '15px' }}>{title}</h6>
                            {content}
                        </CardContent>
                    </CardActionArea>
                </Card> : 
                <Card elevation={4}>
                    <CardActionArea style={{ height: '100%' }}>
                        <CardContent style={{ padding: '10px 0px' }}>
                            <h6 style={{ paddingLeft: '15px' }}>{title}</h6>
                            {content}
                        </CardContent>
                    </CardActionArea>
                </Card>

    return (
        <div className="row no-gutters" style={{ marginBottom: '20px' }} onClick={onClick}>
            <div className="col">
                {cardContent}
            </div>
        </div>
    );
}

export default OnboardingDashboardCard;