import React, { useState, useEffect, Component } from 'react';
import { Spinner, Image } from 'react-bootstrap';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import DatePicker from 'react-datepicker';
import MailjetService from '../../services/mailjet.service';
import memoize from 'memoize-one';
import moment from 'moment';
import LongArrowUpIcon from '@mui/icons-material/ArrowUpward';
import LongArrowDownIcon from '@mui/icons-material/ArrowDownward';
import Pagination from '@mui/material/Pagination';
import WarningModal from './WarningModal'
import EmailHistoryModal from './EmailHistoryModal'
import Loader from "../Loader/Loader";

const itemsPerPage = 100;
const maxMessageCount = 1000;
const noAlerts = "No Alerts!";
const noEmailFound = "No Contact Found for Searched Email Address!"

const getCellBackgroundColor = (value) => {
    if (value === 'sent') {
        return <div className="db-sky-blue-bg2 mailjet-email-status">Delivered</div>;
    } else if (value === 'opened') {
        return <div className="db-green-bg2 mailjet-email-status">Opened</div>;
    } else if (value === 'softbounced') {
        return <div className="db-pink-bg2 mailjet-email-status">Soft-bounced</div>;
    } else if (value === 'hardbounced') {
        return <div className="db-purple-bg2 mailjet-email-status">Hard-bounced</div>;
    } else if (value === 'queued') {
        return <div className="db-gray-bg2 mailjet-email-status">Queued</div>;
    } else if (value === 'blocked') {
        return <div className="db-tan-bg2 mailjet-email-status">Blocked</div>;
    } else if (value === 'spam') {
        return <div className="db-salmon-bg2 mailjet-email-status">Spam</div>;
    } else if (value === 'unsub') {
        return <div className="db-maroon-bg2 mailjet-email-status">Unsubscribed</div>;
    } else if (value === 'deferred') {
        return <div className="db-violet-bg2 mailjet-email-status">Retrying</div>;
    } else {
        return <div className="db-red-bg2 mailjet-email-status">{value}</div>;
    }
};

function EmailStatusTable(props) {
    const { classes, isLoading, messages, senders, isMounted } = props;
    const [emptyTableMessage, setEmptyTableMessage] = useState("No Emails");
    const [isShowHistoryModal, setIsShowHistoryModal] = useState(false);
    const [singleMessageObj, setSingleMessageObj] = useState({});
    const [historyObj, setHistoryObj] = useState({});
    const [historyLoading, setHistoryLoading] = useState(false);
    const [columnSort, setColumnSort] = useState({
        enabled: true,
        type: "TO",
        reverse: false
    });

    const getSenderEmail = (id) => {
        var senderObj = senders.find(a => a.id == id);
        if (senderObj !== null && senderObj !== undefined) {
            return senderObj.email;
        }
        else {
            return "";
        }
    }

    const showHistoryModal = (index, messageId) => {
        if (!isShowHistoryModal) {
            setHistoryLoading(true);
            setIsShowHistoryModal(!isShowHistoryModal);

            MailjetService.getmessagehistory(messageId)
                .then(response1 => {
                    if (isMounted) {
                        let sortedMessages = maintainMessageSorting();
                        setSingleMessageObj(sortedMessages[index]);
                        setHistoryObj(response1);
                        setHistoryLoading(false);
                    }
                })
                .catch(e => {
                    console.log(e);
                });
        }
        else {
            setIsShowHistoryModal(!showHistoryModal);
        }
        window.scrollTo(0, 0);
    }

    const sortColumn = (emailList, col, isReverse) => {
        let colAlerts = [];
        let emails = emailList !== null && emailList !== undefined ? emailList : [];
        emails = emails.filter(function (el) { return el; });

        if (col === "TO") {
            colAlerts = emails.sort((a, b) => {
                const nameA = a?.contactAlt;
                const nameB = b?.contactAlt;

                return nameA.localeCompare(nameB);
            });
        }
        else if (col === "FROM") {
            colAlerts = emails.sort((a, b) => {
                const nameA = this.getSenderEmail(a?.senderID);
                const nameB = this.getSenderEmail(b?.senderID);

                return nameA.localeCompare(nameB);
            });
        }
        else if (col === "SUBJECT") {
            colAlerts = emails.sort((a, b) => {
                const stringA = a?.subject;
                const stringB = b?.subject;

                return stringA.localeCompare(stringB);
            });
        }
        else if (col === "DATE") {
            colAlerts = emails.sort((a, b) => {
                const dateA = new Date(a.arrivedAt);
                const dateB = new Date(b.arrivedAt);
                return dateB - dateA;
            });
        }
        else if (col === "STATUS") {
            colAlerts = emails.sort((a, b) => {
                const stringA = a.status;
                const stringB = b.status;

                return stringA.localeCompare(stringB);
            });
        }

        if (isReverse) {
            colAlerts = colAlerts.reverse();
        }

        return colAlerts;
    }

    const onColumnClick = (selectedCol) => {
        const colSort = columnSort;
        let newSort = colSort;
        let emails = messages;
        let colAlerts = [];
        let colAltered = false;

        if (colSort.enabled) {
            if (colSort.type == selectedCol) {
                if (colSort.reverse) {
                    newSort = {
                        enabled: false,
                        type: null,
                        reverse: false
                    };
                    colAlerts = sortColumn(emails, "TO", false); /* default sort by is start time */
                    colAltered = false;
                }
                else {
                    newSort = {
                        enabled: true,
                        type: selectedCol,
                        reverse: true
                    };
                    colAlerts = sortColumn(emails, selectedCol, true);
                    colAltered = true;
                }
            }
            else {
                newSort = {
                    enabled: true,
                    type: selectedCol,
                    reverse: false
                };
                colAlerts = sortColumn(emails, selectedCol, false);
                colAltered = true;
            }
        }
        else {
            newSort = {
                enabled: true,
                type: selectedCol,
                reverse: false
            };
            colAlerts = sortColumn(emails, selectedCol, false);
            colAltered = true;
        }

        setColumnSort(newSort);

        //this.setState({ columnSort: newSort, columnAlertList: colAlerts }, () => {
        //    this.setState({ columnAltered: colAltered });
        //});
    }

    const maintainMessageSorting = () => {
        let columnSortType = "TO";
        if (columnSort.type !== null) columnSortType = columnSort.type;
        var colMessages = sortColumn(messages, columnSortType, columnSort.reverse);
        return colMessages
    }

    const getDate = (dateTime) => {
        var d = new Date(dateTime);
        var hr = d.getHours() < 10 ? "0" : "";
        var min = d.getMinutes() < 10 ? "0" : "";
        var sec = d.getSeconds() < 10 ? "0" : "";
        var month = d.getMonth() + 1;

        var date = month + "/" + d.getDate() + "/" + d.getFullYear() + " " + d.toLocaleTimeString('en-US');

        if (dateTime == "" || dateTime == null)
            date = "";

        return (date);
    }

    const renderTable = () => {
        let tableEmails = maintainMessageSorting();

        tableEmails = tableEmails.filter(function (el) { return el; });

        let emailsContent = tableEmails !== null && tableEmails !== undefined && tableEmails.length ?
            <tbody>
                {tableEmails?.map((email, index) =>
                    [<tr className="alerts-table-row" key={`${email?.id}-tableRow`} data={index} value={index} onClick={() => showHistoryModal(index, email?.id)}>
                        <td style={{ padding: '16px 0px 16px 10px', borderLeft: '1px solid #ebeef0' }}>
                            {email?.contactAlt}
                        </td>
                        <td>{getSenderEmail(email?.senderID)}</td>
                        <td>{email?.subject}</td>
                        <td>{getDate(email?.arrivedAt)}</td>
                        <td className="alerts-td-right">
                            {getCellBackgroundColor(email?.status)}
                        </td>
                    </tr>
                    ])}

            </tbody>
            : <tbody>
                <tr>
                    <td>{emptyTableMessage}</td>
                </tr>
            </tbody>;

        let arrowIcon = columnSort.enabled ?
            (columnSort.reverse ?
                <LongArrowDownIcon style={{ height: '16px', width: '15px', paddingBottom: '2px' }} />
                : <LongArrowUpIcon style={{ height: '16px', width: '15px', paddingBottom: '2px' }} />)
            : null;

        let toIcon = columnSort.enabled && columnSort.type === "TO" ?
            <span style={{ height: '15px', paddingLeft: '3px' }}>
                {arrowIcon}
            </span>
            : null;
        let fromIcon = columnSort.enabled && columnSort.type === "FROM" ?
            <span style={{ height: '15px', paddingLeft: '3px' }}>
                {arrowIcon}
            </span>
            : null;
        let subjectIcon = columnSort.enabled && columnSort.type === "SUBJECT" ?
            <span style={{ height: '15px', paddingLeft: '3px' }}>
                {arrowIcon}
            </span>
            : null;
        let dateIcon = columnSort.enabled && columnSort.type === "DATE" ?
            <span style={{ height: '15px', paddingLeft: '3px' }}>
                {arrowIcon}
            </span>
            : null;
        let statusIcon = columnSort.enabled && columnSort.type === "STATUS" ?
            <span style={{ height: '15px', paddingLeft: '3px' }}>
                {arrowIcon}
            </span>
            : null;

        return (
            <div style={{ margin: '20px 30px' }}>

                <div>
                    <table className="table-hover" style={{ width: '100%' }}>

                        <thead>
                            <tr className="alerts-table-row">
                                <th style={{ padding: '0px 0px 0px 10px' }}>
                                    <div onClick={() => onColumnClick("TO")}>
                                        <span style={{ cursor: 'pointer' }}>TO</span>
                                        {toIcon}
                                    </div>
                                </th>
                                <th>
                                    <div onClick={() => onColumnClick("FROM")}>
                                        <span style={{ cursor: 'pointer' }}>FROM</span>
                                        {fromIcon}
                                    </div>
                                </th>
                                <th>
                                    <div onClick={() => onColumnClick("SUBJECT")}>
                                        <span style={{ cursor: 'pointer' }}>SUBJECT</span>
                                        {subjectIcon}
                                    </div>
                                </th>
                                <th>
                                    <div onClick={() => onColumnClick("DATE")}>
                                        <span style={{ cursor: 'pointer' }}>DATE</span>
                                        {dateIcon}
                                    </div>
                                </th>

                                <th>
                                    <div onClick={() => onColumnClick("STATUS")}>
                                        <span style={{ cursor: 'pointer' }}>STATUS</span>
                                        {statusIcon}
                                    </div>
                                </th>
                                <th className="table-shrink" style={{ textAlign: 'center', padding: '10px 10px 10px 5px' }}></th>
                            </tr>
                        </thead>

                        {emailsContent}

                    </table>
                </div>
            </div>
        );
    }

    let content = isLoading ?
        <Loader />
        : renderTable();

    return (
        <div>
            {content}
            <EmailHistoryModal show={isShowHistoryModal} onClick={showHistoryModal} loading={historyLoading} emailHistory={historyObj} getStatus={getCellBackgroundColor} emailDetails={singleMessageObj} formatDate={getDate}> </EmailHistoryModal>
        </div>
    );
}

export default EmailStatusTable;