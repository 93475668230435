/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, {useState} from 'react';
import "../../CSS/DataModal.css";
import { Spinner, Image } from 'react-bootstrap';
import {Event} from "../GoogleAnalytics";
import GetAppIcon from '@mui/icons-material/GetApp';
import MenuIcon from "@mui/icons-material/Menu";
import LongArrowDownIcon from "@mui/icons-material/ArrowDownward";
import LongArrowUpIcon from "@mui/icons-material/ArrowUpward";
import DatePicker from 'react-datepicker';
import DataFromDatePicker from '../DataFromDatePicker/DataFromDatePicker';

const csvBaseName = "MonthlyTriageSummary";
const currentDate = new Date();
const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

function convertToCSV(objArray) {
    const array = [Object.keys(objArray[0])].concat(objArray.map(obj => Object.values(obj)));
    return array.map(row => row.join(',')).join('\n');
};

function downloadCSV(data, date) {
    Event("Download Data Clicked", "User clicked download data button on Triage Alerts Monthly Summary", "Download Data Clicked");
    var filenameWithDate = months[date.getMonth()] + "_" + csvBaseName;
    const csvContent = "data:text/csv;charset=utf-8," + encodeURIComponent(convertToCSV(data));
    const link = document.createElement("a");
    link.setAttribute("href", csvContent); // Set csvContent directly
    link.setAttribute("download", filenameWithDate || "data.csv");
    document.body.appendChild(link);
    link.click();
};

function MonthlySummaryModal(props) {
    const { classes, show, startTime, endTime, monthData, loading, onClick, _isMounted, updateData } = props;
    const [dateSelected, setDateSelected] = useState(currentDate);
    const onModalClick = (e) => {
        onClick && onClick(e);
    };

    const onCancelClick = () => {

    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const year = date.getFullYear();

        // Pad single-digit month and day with leading zero
        const formattedMonth = month < 10 ? `0${month}` : month;
        const formattedDay = day < 10 ? `0${day}` : day;

        return `${formattedMonth}/${formattedDay}/${year}`;
    }

    // let alertTypeHeader =
    //     <div style={{ height: '24px', display: 'flex', alignItems: 'center', justifyContent: "space-between" }}>
    //         <div style={{ display: "flex", margin: "0 auto", border: "2px solid #ebeef0" }}>
    //                     <span style={{}}>
    //                         <div style={{ textAlign: 'left' }}>
    //                             <div style={{ margin: '10px 10px 0 10px' }}><b>ASSET TYPE:</b></div>
    //                         </div>
    //                         <div style={{ textAlign: 'center' }}>
    //                             <div style={{ margin: '0 10px 10px 10px' }}></div>
    //                         </div>
    //                     </span>
    //         </div>
    //         <div style={{ display: "flex", margin: "0 auto", border: "2px solid #ebeef0" }}>
    //                     <span style={{}}>
    //                         <div style={{ textAlign: 'left' }}>
    //                             <div style={{ margin: '10px 10px 0 10px' }}><b>DATE RANGE:</b></div>
    //                         </div>
    //                         <div style={{ textAlign: 'center' }}>
    //                             <div style={{ margin: '0 10px 10px 10px' }}></div>
    //                         </div>
    //                     </span>
    //         </div>
    //     </div>;
    let handleChange = date => {
        setDateSelected(date);
        let yearSelected = date.getFullYear();
        let monthSelected = date.getMonth() + 1;
        updateData(monthSelected, yearSelected);
    }

    let alertTypeHeader = <DataFromDatePicker dateSelected={dateSelected} handleChange={handleChange} />

    let triageContent = loading ?
        <div style={{display: "block"}}>
            <div className="loading-wrapper">
                <div className="modal-body"><Spinner animation="border" variant="light"/></div>
            </div>
        </div>
        :
        monthData.length ?
            <tbody>
            {monthData.map((entry, index) =>
                [<tr className="alerts-table-row" key={`${entry.assetType}-tableRow`} data={index} value={index}>
                    <td style={{padding: '16px 0px 16px 10px', borderLeft: '1px solid #ebeef0'}}>
                        {entry.assetType}
                    </td>
                    <td>{entry.escalatedAlerts}</td>
                    <td>{entry.falsePositiveAlerts}</td>
                    <td>{entry.totalAlerts}</td>
                    <td>{entry.triageTime}</td>
                    <td>{entry.averageAssetCount.toFixed(0)}</td>
                    <td>{entry.escalatedPerAsset.toFixed(2)}</td>
                    <td>{entry.falsePositivePerAsset.toFixed(2)}</td>
                    <td>{entry.totalPerAsset.toFixed(2)}</td>
                    <td className="alerts-td-right">{entry.triageMinutesPerAsset.toFixed(2)}</td>
                </tr>
                ])}

            </tbody>
            : <tbody>
            <tr>
                <td>{"No Triage Metrics"}</td>
            </tr>
            </tbody>;

    return (
        <div className="modal" style={{display: show ? "block" : "none"}}>
            <div className="modal-wrapper"
                 style={{
                     transform: show ? 'translateY(0vh)' : 'translateY(-100vh)',
                     opacity: show ? '1' : '0'
                 }}>

                <div className="modal-header">
                    <h3>Triage Monthly Summary Table</h3>
                    <button className="primary-btn btn-small" 
                            onClick={() => downloadCSV(monthData, dateSelected)}>
                        <GetAppIcon />
                    </button>

                    <span className="close-modal-btn" onClick={e => {
                        onModalClick(e);
                        onCancelClick();
                    }}>×</span>
                </div>

                <div className="modal-body">
                    <br/>
                    {alertTypeHeader}
                    <br/><br/>
                    <table className="table-hover" style={{width: '100%'}}>

                        <thead>
                        <tr className="alerts-table-row">
                            <th style={{padding: '0px 0px 0px 10px'}}>
                                <span style={{cursor: 'pointer'}}>ASSET TYPE</span>
                            </th>
                            <th>
                                <span style={{cursor: 'pointer'}}>ESCALATED</span>
                            </th>
                            <th>
                                <span style={{cursor: 'pointer'}}>FALSE POSITIVES</span>
                            </th>
                            <th>
                                <span style={{cursor: 'pointer'}}>TOTAL</span>
                            </th>
                            <th>
                                <span style={{cursor: 'pointer'}}>TRIAGE MINUTES</span>
                            </th>
                            <th>
                                <span style={{cursor: 'pointer'}}>AVG ASSETS</span>
                            </th>
                            <th>
                                <span style={{cursor: 'pointer'}}>ESC/ASSET</span>
                            </th>
                            <th>
                                <span style={{cursor: 'pointer'}}>FP/ASSET</span>
                            </th>
                            <th>
                                <span style={{cursor: 'pointer'}}>ALERTS/ASSET</span>
                            </th>
                            <th>
                                <span style={{cursor: 'pointer'}}>TRIAGE TIME/ASSET</span>
                            </th>
                            <th className="table-shrink"
                                style={{textAlign: 'center', padding: '10px 10px 10px 5px'}}></th>
                        </tr>
                        </thead>
                        {triageContent}
                    </table>
                </div>

                <div className="modal-footer">
                    <button className="primary-btn btn-small" onClick={e => {
                        onModalClick(e);
                        onCancelClick();
                    }}>Close
                    </button>
                </div>
            </div>
        </div>
    );
}

export default MonthlySummaryModal;