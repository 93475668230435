/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import "../../CSS/DataModal.css";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import FullLoader from "../Loader/FullLoader";

/*const options = [
    {
        role: "OTA",
        roleId: "11"
    },
    {
        role: "Technician",
        roleId: "13"
    },
    {
        role: "Scheduler",
        roleId: "14"
    },
    {
        role: "Partner Admin",
        roleId: "15"
    }
];*/

const animatedComponents = makeAnimated();

class AlertTagModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            tagListIds: []
        };

        this.onAddAlertTags = this.onAddAlertTags.bind(this);
        this.onChangeAlertTags = this.onChangeAlertTags.bind(this);
    }

    onClick = e => {
        this.props.onClick && this.props.onClick(e);
    };

    onCancelClick() {

    };

    onChangeAlertTags = (selectedOptions) => {
        var options = [];

        selectedOptions.forEach(option => {
            options = options.concat(option.id.toString());
        });

        this.setState({ tagListIds: options });
    }

    onAddAlertTags() {
        this.props.onAddAlertTags(this.state.tagListIds);
    }

    getOptionLabel = (option) => {
        return (option.name);
    }

    getOptionValue = (option) => {
        return (option.id);
    }

    render() {
        let alertTagsLoading = this.props.modalLoading ?
            <FullLoader/>
            : <div>{null}</div>

        if (!this.props.show) {
            return null;
        }
        else {
            return (
                <div className="modal" style={{ display: this.props.show ? "block" : "none" }}>
                    <div className="modal-wrapper"
                        style={{
                            transform: this.props.show ? 'translateY(0vh)' : 'translateY(-100vh)',
                            opacity: this.props.show ? '1' : '0'
                        }}>

                        <div className="modal-header">
                            <h3>Add Alert Tags</h3>
                            <span className="close-modal-btn" onClick={e => { this.onClick(e); this.onCancelClick(); }}>×</span>
                        </div>

                        <div className="modal-body">

                            <div className="row no-gutters">
                                <div className="col-2 offset-1">
                                    <br />
                                    <label htmlFor="description">Select Alert Tags</label>
                                </div>
                                <div className="col-8">
                                    <br />
                                    <div>
                                        <form>
                                            <Select 
                                                closeMenuOnSelect={true}
                                                components={animatedComponents}
                                                styles={{ menuPortal: (base) => ({ ...base, zIndex: 99999999999 }) }}
                                                menuPortalTarget={document.body}
                                                isMulti
                                                options={this.props.alertTags}
                                                getOptionLabel={this.getOptionLabel}
                                                getOptionValue={this.getOptionValue}
                                                onChange={this.onChangeAlertTags}
                                                placeholder="Select Tags..."
                                            />
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <br/>
                        </div>


                        <div className="modal-footer">
                            <button className="secondary-btn btn-small" onClick={e => { this.onClick(e); this.onCancelClick(); }}>Cancel</button>
                            <button className="primary-btn btn-small" onClick={this.onAddAlertTags}>Add Alert Tags</button>
                        </div>

                    </div>
                    {alertTagsLoading}

                </div>
            );
        }
    }
}

export default AlertTagModal;