import React, { useState, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { Event, PageView, ModalView, Timing } from "../GoogleAnalytics";
import AccountService from "../../services/account.service";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ConfirmPasswordModal from "../Account/ConfirmUser/ConfirmPasswordModal";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import UserDeviceList from "../DeletedSystems/UserDeviceList";
import ConfirmDeleteSystemModal from "../Account/ConfirmDeleteSystemModal";
import SelectHomeDataModal from "../DeletedSystems/SelectHomeDataModal";
import ArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Tooltip from "@mui/material/Tooltip";
import HelpIcon from "@mui/icons-material/Help";
import LongArrowDownIcon from "@mui/icons-material/ArrowDownward";
import LongArrowUpIcon from "@mui/icons-material/ArrowUpward";
import {Cell, Legend, Pie, PieChart, ResponsiveContainer} from "recharts";
import {COLORS} from "../../componentObjects";
import InstallService from "../../services/install.service";
import InstallFailuresService from "../../services/installfailures.service";
import Loader from "../Loader/Loader";

const ASC = "asc";
const DESC = "desc";
const TECHNICIAN = "TECHNICIAN";
const TOTAL_INSTALLS = "TOTAL_INSTALLS";
const FAILED_INSTALLS = "FAILED_INSTALLS";
function FailedInstalls(props) {

    const {classes, isLoading, currentPartnerId, _isMounted} = props;
    const [isMounted, setIsMounted] = useState(false);
    const [installsReport, setInstallsReport] = useState([]);
    const [loading, setLoading] = useState(true); 
    const [technicianDetailLoading, setTechLoading] = useState(false);

    const [isSelected, setIsSelected] = useState(-1);
    const [selectedReport, setSelectedReport] = useState({});
    const [columnSort, setColumnSort] = useState({ column: "", direction: ASC });

    const fetchInstallsReport = async (partnerId) => {
        try {
            await InstallFailuresService.gettechinstallreports(partnerId)
                .then(response => {
                    if (response !== null && response !== undefined) {
                        const data = response.data;
                        setInstallsReport(data);
                        setLoading(false);
                    }
                })
                .catch(e => {
                    console.log(e);
                })
                PageView();
        } catch (error) {
            console.error('Error fetching installs report:', error);
            setLoading(false);
        }
    };
    
    useEffect(() => {
        setIsMounted(true);
        fetchInstallsReport(currentPartnerId).then();

        return () => {
            setIsMounted(false);
        };
    }, []);
    
    useEffect(() => {
        setIsSelected(-1);
        setSelectedReport({});
        fetchInstallsReport(currentPartnerId).then();
    }, [currentPartnerId]);

    const onTechnicianClick = (index) => {
        if (isSelected !== index) {
            setIsSelected(index);
            setSelectedReport(installsReport[index]);
        }
        else {
            setIsSelected(-1);
            setSelectedReport({});
        }
    };

    const iconFunction = (columnName)  => {
        let arrowIcon = columnSort.direction === ASC ?
            <LongArrowDownIcon style={{ height: '16px', width: '15px', paddingBottom: '2px' }} />
            : <LongArrowUpIcon style={{ height: '16px', width: '15px', paddingBottom: '2px' }} />;

        return columnSort.column === columnName ?
            <span style={{ height: '15px', paddingLeft: '3px' }}>
                {arrowIcon}
            </span>
            : null;
    }

    const onColumnClick = (column) => {
        const newDirection = columnSort.direction === ASC ? DESC : ASC;
        setColumnSort({ column: column, direction: newDirection });
        sortTable(column);
    };

    const sortTable = (column) => {
        //let sortedList = [...installsReport];

        if (column === TECHNICIAN) {
            installsReport.sort((a, b) => {
                const aValue = a.technicianName.toLowerCase();
                const bValue = b.technicianName.toLowerCase();

                if (aValue < bValue) {
                    return columnSort.direction === ASC ? -1 : 1;
                }

                if (aValue > bValue) {
                    return columnSort.direction === ASC ? 1 : -1;
                }

                return 0;
            });
        } else if (column === TOTAL_INSTALLS) {
            installsReport.sort((a, b) =>
                columnSort.direction === ASC ? a.totalInstalls - b.totalInstalls : b.totalInstalls - a.totalInstalls
            );
        }
        else if (column === FAILED_INSTALLS) {
            installsReport.sort((a, b) =>
                columnSort.direction === ASC ? a.failedInstalls - b.failedInstalls : b.failedInstalls - a.failedInstalls
            );
        }

        setInstallsReport(installsReport);
    };
    
    const getExpandContent = (index, alert) => {
        let expandedLoader = technicianDetailLoading ?
            <Loader />
            : null;

        if (isSelected === index) {
            return (
                <tr className="expanded" key={`expandedContent-${index}`} style={{ backgroundColor: 'white !important' }}>
                    <td colSpan={4} style={{ color: 'unset' }}>
                        <div className="row">
                            <div className="col-4 offset-4">
                                <h6>Install Failure Types</h6>
                                <ResponsiveContainer height={325} style={{ marginBottom: '15px' }}>
                                    <PieChart width={200} height={300} /*onMouseEnter={this.onPieEnter}*/>
                                        <Pie
                                            data={selectedReport?.failureList}
                                            innerRadius={60}
                                            outerRadius={80}
                                            fill="#8884d8"
                                            paddingAngle={5}
                                            dataKey="typeCount"
                                            opacity="65%"
                                            label
                                        >
                                            {selectedReport?.failureList !== undefined && selectedReport?.failureList !== null &&
                                                (selectedReport?.failureList.map((entry, index) => (
                                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                )))

                                            }
                                        </Pie>

                                        <Legend verticalAlign="bottom" iconType="circle" iconSize={6} wrapperStyle={{ opacity: '75%', padding: '0px 10px' }}/>
                                    </PieChart>
                                </ResponsiveContainer>
                            </div>
                            <div className="col-4"></div>
                            {expandedLoader}
                        </div>
                    </td>
                </tr>
            );
        }
        else
            return (<tr>{null}</tr>);
    }
    
    let installsTableContent = installsReport !== null && installsReport !== undefined && installsReport.length ?
        <tbody>
        {installsReport.map((report, index) =>
            [<tr className="alerts-table-row" key={`${index}-tableRow`} data={index} value={index}
                 onClick={() => onTechnicianClick(index)}>
                <td style={{textAlign: 'center', borderLeft: '1px solid #ebeef0' }}>{report.technicianName}</td>
                <td style={{textAlign: 'center'}}>{report.totalInstalls}</td>
                <td style={{textAlign: 'center'}}>{report.failedInstalls}</td>
                <td className="alerts-td-right">
                    {isSelected === index ?
                        <ArrowUpIcon className="db-blue-reverse" style={{borderRadius: '12px'}}/> :
                        <ArrowDownIcon className="db-blue db-blue-bg" style={{borderRadius: '12px'}}/>}
                </td>
            </tr>,
                isSelected === index && (getExpandContent(index, report))
            ])}
        </tbody>
        : <tbody>
        <tr className={"alerts-table-row"}>
            <td colSpan={5} style={{
                borderLeft: '1px solid #ebeef0',
                borderRight: '1px solid #ebeef0'
            }}>{isLoading ? "Loading Active Alerts..." : "No Install Reports"}</td></tr>
        </tbody>;

    let installsTable =
        <div style={{margin: '20px 30px'}}>
            <div>
                <table className="table-hover" style={{width: '100%', tableLayout: 'fixed'}}>
                    <thead>
                    <tr className="alerts-table-row" style={{textAlign: 'center'}}>
                        <th>
                            <div onClick={() => onColumnClick("TECHNICIAN")}>
                                <span style={{cursor: 'pointer'}}>TECHNICIAN</span>
                                {iconFunction(TECHNICIAN)}
                            </div>
                        </th>
                        <th>
                            <div onClick={() => onColumnClick("TOTAL_INSTALLS")}>
                                <span style={{cursor: 'pointer'}}>TOTAL INSTALLS</span>
                                {iconFunction(TOTAL_INSTALLS)}
                            </div>
                        </th>
                        <th>
                            <div onClick={() => onColumnClick("FAILED_INSTALLS")}>
                                <span style={{cursor: 'pointer'}}>FAILED INSTALLS</span>
                                {iconFunction(FAILED_INSTALLS)}
                            </div>
                        </th>
                    </tr>
                    </thead>
                    {installsTableContent}
                </table>
            </div>
        </div>

    let contents = loading
        ? <Loader />
        :
        <div>
            {installsTable}
        </div>;

    return (
        <div sx={(theme) => ({
            [theme.breakpoints.down('md')]: {
                marginLeft: '-225px !important',
            },
        })}>
            <div className='infoPage'>
                <h3 className="pageTitle" id="tabelLabel">Failed Installs Report</h3>
                {contents}
            </div>
        </div>
    );
}

export default FailedInstalls;