import React, {useState} from 'react';
import OnboardingDashboardCard from './OnboardingDashboardCard';


function TrainingFaultsCard(props) {
    const { assetType, onboardInfo, onTrainingFaultsClick } = props;
    const title = 'Asset Training Report (24 hours)';


    const getPercentage = (count) => {
        let total = onboardInfo.totalFaults;
        let percentage = (count / total) * 100;
        percentage = percentage.toFixed(2);

        return (percentage + "%");
    }

    let trainingFaultsContent = <div className="row no-gutters card-row" style={{ textAlign: 'center', margin: '8px 0px' }}>
        <div className="col" style={{ minHeight: '40px' }}>
            <div style={{ margin: '0px 20px' }}>
                <table style={{ width: '100%', marginBottom: '0' }}>

                    <thead>
                        <tr className="alerts-table-row">
                            <th className="table-shrink" style={{ textAlign: 'left', padding: '0px 30px 10px' }}>
                                FAULT
                            </th>
                            <th className="table-shrink" style={{ textAlign: 'center', padding: '0px 0px 10px' }}>
                                #
                            </th>
                            <th className="table-shrink" style={{ textAlign: 'left', padding: '0px 10px 10px 40px' }}>
                                % (trained past 24hrs)
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        {onboardInfo.trainingFaults !== undefined && onboardInfo.trainingFaults.map((fault, index) =>
                            <tr key={fault.failureId} data={index} className="alerts-table-row">
                                <td style={{ textAlign: 'left', padding: '8px 3px 8px 30px' }}> {fault.name} </td>
                                <td style={{ textAlign: 'center', padding: '8px 3px' }}> {fault.count} </td>
                                <td style={{ textAlign: 'left', padding: '8px 3px 8px 40px' }}> {getPercentage(fault.count)} </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    </div>;

    return (
        <div className="col-6">
            <OnboardingDashboardCard title={`${assetType} Training Faults`} content={trainingFaultsContent} onClick={onTrainingFaultsClick} isGraphCard={false} />
        </div>
    );
}

export default TrainingFaultsCard;