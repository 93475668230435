/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import http from "../http-common";

const controller = "/customer";
class CustomerService {

    async get(id) {
        const response = await http.get(`${controller}?organizationId=` + id);
        const data = await response.data;
        return data;
    }

    async getcustomerlistplus(id, type, customers) {
        const response = await http.post(`${controller}/getcustomerlistplus?orgId=${id}&type=${type}`, customers);
        const data = response;
        return data;
    }

    async getmapcustomer(id) {
        const response = await http.post(`${controller}/getmapcustomer?orgId=${id}`);
        const data = response;
        return data;
    }

    async getmappoints(partnerOrgId, customerOrgId, homeAssetId) {
        const response = await http.post(`${controller}/getmappoints?partnerOrganizationId=${partnerOrgId}&organizationId=${customerOrgId}&homeAssetId=${homeAssetId}`, null);
        const data = response;
        return data;
    }

    async searchassets(classId, propertyName, orgId = null) {
        const response = await http.post(`${controller}/searchassets?classId=${classId}&propertyName=${propertyName}&orgId=${orgId}`);
        const data = response;
        return data;
    }

    async getorgdetail(orgId) {
        const response = await http.post(`${controller}/getorgdetail?orgId=${orgId}`);
        const data = response;
        return data;
    }
}

export default new CustomerService();
