import React, {useState} from 'react';
import DatePicker from 'react-datepicker';

function DataFromDatePicker(props) {
    const { handleChange, dateSelected } = props;

    return (
        <div style={{ textAlign: 'center' }}>
            <div style={{ marginLeft: '10px' }}>Data From:</div>
            <DatePicker
                customInput={<input className="txt-detail" style={{ minWidth: '200px', width: '15%', textAlign: "center" }} />}
                selected={dateSelected}
                onChange={handleChange}
                dateFormat="MM/yyyy"
                showMonthYearPicker
                popperPlacement="bottom"
                popperProps={{
                    positionFixed: true
                }}
            />
        </div>
    );
}

export default DataFromDatePicker;