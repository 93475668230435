/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import "../../CSS/DataModal.css";
import FullLoader from "../Loader/FullLoader";

class PublishResultModal extends Component {

    onClick = e => {
        this.props.onViewInTT();
        this.props.onClick && this.props.onClick(e);
    };

    onClose = e => {
        this.props.onClick && this.props.onClick(e);
    }

    render() {

        if (!this.props.show) {
            return null;
        }
        else {
            let bodyContents = this.props.loading ?
                <FullLoader/>
                : <div>
                    <br />
                    <h5 style={{ padding: '0px 70px' }}>
                        {this.props.isPublishSuccess ? "Successfully published Asset!" : "Failed to publish Asset!"}
                     </h5>
                    <br />
                </div>;

            return (
                <div className="modal" style={{ display: this.props.show ? "block" : "none" }}>
                    <div className="modal-wrapper"
                        style={{
                            transform: this.props.show ? 'translateY(0vh)' : 'translateY(-100vh)',
                            opacity: this.props.show ? '1' : '0'
                        }}>

                        <div className="modal-header">
                            <h3>Publish Asset Result</h3>
                            <span className="close-modal-btn" onClick={e => { this.onClose(e); }}>×</span>
                        </div>

                        <div className="modal-body" style={{ minHeight: '100px' }}>
                            {bodyContents}
                        </div>

                        <div className="modal-footer">
                            <button className="secondary-btn btn-small" onClick={e => { this.onClick(e); }}>View in TwinThread</button>
                            <button type='button' className="primary-btn btn-small" onClick={this.props.onContinue}>Continue</button>
                        </div>
                    </div>
                </div>
            );
        }
    }
}


export default PublishResultModal;
