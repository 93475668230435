import React, { useState, useEffect } from 'react';
import { Event, PageView, ModalView, Timing } from "../GoogleAnalytics";
import Loader from "../Loader/Loader";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import BaseAlertPage from "../LWTriageAlerts/BaseAlertPage";
import FurnaceOnboarding from "./FurnaceOnboarding";
import ACOnboarding from "./ACOnboarding";
import AirHandlerOnboarding from "./AirHandlerOnboarding";

function OperationalDashboard(props) {
    const {isLoading, currentPartnerId, _isMounted, setActivePath, loggedInOrg, showAllTraining, setShowAllTraining} = props;
    
    return (
        <div sx={(theme) => ({
            [theme.breakpoints.down('md')]: {
                marginLeft: '-225px !important',
            },
        })}>
            <div className='infoPage'>
                <Tabs className="pad-top-20">
                    <TabList className="pad-left-30">
                        <Tab>Furnace</Tab>
                        <Tab>Air Conditioner</Tab>
                        <Tab>Air Handler</Tab>
                    </TabList>

                    <TabPanel>
                        <FurnaceOnboarding setActivePath={setActivePath} loggedInOrg={loggedInOrg} currentPartnerId={currentPartnerId} showAllTraining={showAllTraining} setShowAllTraining={setShowAllTraining}/>
                    </TabPanel>
                    <TabPanel>
                        <ACOnboarding setActivePath={setActivePath} loggedInOrg={loggedInOrg} currentPartnerId={currentPartnerId} showAllTraining={showAllTraining} setShowAllTraining={setShowAllTraining}/>
                    </TabPanel>
                    <TabPanel>
                        <AirHandlerOnboarding setActivePath={setActivePath} loggedInOrg={loggedInOrg} currentPartnerId={currentPartnerId} showAllTraining={showAllTraining} setShowAllTraining={setShowAllTraining}/>
                    </TabPanel>
                </Tabs>
            </div>
        </div>
    )
}

export default OperationalDashboard;