/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import { Spinner } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import AccountService from '../../services/account.service';
import AlertService from '../../services/alerts.service';
import ScheduleService from '../../services/schedule.service';
import HomeService from '../../services/home.service';
import memoize from "memoize-one";
import moment from "moment";

import { jsHouse, jsOpportunity, jsTechnician, jsDetails, jsCustomer } from "../../componentObjects";
import { Event, Timing } from "../GoogleAnalytics";
import Loader from "../Loader/Loader";

class BaseScheduleRepairs extends Component {

    constructor(props) {
        super(props);

        this.changeRadio = this.changeRadio.bind(this);
        this.getOpportunities = this.getOpportunities.bind(this);
        this.onOpportunityClick = this.onOpportunityClick.bind(this);
        this.reloadTable = this.reloadTable.bind(this);

        this.onCancelClick = this.onCancelClick.bind(this);
        this.onBookClick = this.onBookClick.bind(this);
        this.onCloseClick = this.onCloseClick.bind(this);
        this.onUpdateClick = this.onUpdateClick.bind(this);

        this.onChangeScheduleDate = this.onChangeScheduleDate.bind(this);
        this.onChangeScheduleTime = this.onChangeScheduleTime.bind(this);
        this.onChangeScheduleDateTime = this.onChangeScheduleDateTime.bind(this);
        this.onChangeTechnician = this.onChangeTechnician.bind(this);
        this.onChangeVerification = this.onChangeVerification.bind(this);
        this.onChangeJobValue = this.onChangeJobValue.bind(this);
        this.onChangeNotes = this.onChangeNotes.bind(this);
        this.onChangeClosedTime = this.onChangeClosedTime.bind(this);
        this.onChangeStatus = this.onChangeStatus.bind(this);

        this.closeOpportunity = this.closeOpportunity.bind(this);
        this.getRepairsCallback = this.getRepairsCallback.bind(this);

        this.state = {
            currentAccount: {
                parentAccountId: ""
            },
            currentPartner: {
                users: [
                    {
                        userId: null,
                        firstName: "",
                        lastName: "",
                        email: null,
                        isActive: null,
                        lastLogin: null,
                        roleList: null,
                        roleListId: [],
                        roleListIdStr: null
                    }
                ]
            },
            beginStr: "",
            endStr: "",
            loading: false,
            opportunityLoading: false,
            tableLoading: false,
            tableViewType: this.props.tableViewType || "Unscheduled",
            isSelected: -1,
            selectedTechnicianNumber: -1,
            selectedTechnician: jsTechnician,
            scheduledTime: null,
            closedTime: null,
            selectedVerification: "",
            notes: "",
            technicianList: [],
            opportunityList: [],
            unscheduledList: [],
            scheduledList: [],
            closedList: [],
            currentHome: jsHouse,
            currentOpportunity: jsOpportunity,
            currentOpportunityDetails: jsDetails,
            currentOpportunityCustomer: jsCustomer,
            message: "",
            scheduledDateTime: ""
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this.getOpportunities(this.props.currentCustomerId);
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    memOpportunities = memoize(this.getOpportunities);

    changeRadio(e) {
        let x = e.target.value;
        let list = null;
        Event("Scheduling Radio Clicked", "User clicked a radio button in a scheduling screen", "Scheduling Radio Clicked");

        if (x === "Unscheduled")
            list = this.state.unscheduledList;
        else if (x === "Scheduled")
            list = this.state.scheduledList;
        else if (x === "Closed")
            list = this.state.closedList;

        this.setState({
            tableViewType: x,
            tableLoading: true,
            isSelected: -1,
            opportunityList: list,
            currentOpportunityCustomer: jsCustomer,
            currentHome: jsHouse,
            currentOpportunity: jsOpportunity,
            currentOpportunityDetails: jsDetails,
            selectedTechnician: jsTechnician,
            selectedTechnicianNumber: -1
        });
    }

    onChangeScheduleDate(e) {
        let date = e.target.value;
        let curr = this.state.currentOpportunity.scheduledForStr.split("T");
        let currTime = curr[1];

        let newStr = date.concat("T", currTime);

        this.setState(prevState => ({
            currentOpportunity: {
                ...prevState.currentOpportunity,
                scheduledForTimeStr: newStr,
                scheduledForStr: newStr
            },
            currentOpportunityDetails: {
                ...prevState.currentOpportunityDetails,
                scheduledFor: newStr
            }
        }));
    }

    onChangeScheduleTime(e) {
        let time = e.target.value;
        let curr = this.state.currentOpportunity.scheduledForStr.split("T");
        let currDate = curr[0];

        let newStr = currDate.concat("T", time);

        this.setState(prevState => ({
            currentOpportunity: {
                ...prevState.currentOpportunity,
                scheduledForTimeStr: newStr,
                scheduledForStr: newStr
            },
            currentOpportunityDetails: {
                ...prevState.currentOpportunityDetails,
                scheduledFor: newStr
            }
        }));
    }

    onChangeScheduleDateTime(e) {
        let str = moment(e).format();
        let newStr = str.replace("Z", "");
        let dt = moment(e).toDate();
        this.setState(prevState => ({
            currentOpportunity: {
                ...prevState.currentOpportunity,
                scheduledForTimeStr: newStr,
                scheduledForStr: newStr
            },
            currentOpportunityDetails: {
                ...prevState.currentOpportunityDetails,
                scheduledFor: newStr
            },
            scheduledDateTime: dt
        }));
    }

    onChangeTechnician(e) {
        const techId = e.target.value;
        let tech = this.state.currentPartner.users.find(u => u.userId === techId);
        let name = tech.firstName + " " + tech.lastName;

        this.setState(prevState => ({
            currentOpportunity: {
                ...prevState.currentOpportunity,
                assignedToId: parseInt(techId),
                assignedToName: name
            },
            currentOpportunityDetails: {
                ...prevState.currentOpportunityDetails,
                assignedToId: parseInt(techId)
            }
        }));
    }

    onChangeVerification(e) {
        const verify = (e.target.value === "true");

        this.setState(prevState => ({
            currentOpportunity: {
                ...prevState.currentOpportunity,
                isFalsePositive: verify
            },
            currentOpportunityDetails: {
                ...prevState.currentOpportunityDetails,
                isFalsePositive: verify
            }
        }));
    }

    onChangeJobValue(e) {
        const impact = e.target.value;

        this.setState(prevState => ({
            currentOpportunity: {
                ...prevState.currentOpportunity,
                impact: impact
            },
            currentOpportunityDetails: {
                ...prevState.currentOpportunityDetails,
                impactEstimate: parseInt(impact),
                impactType: "Money",
                impactTypeId: 1
            }
        }));
    }

    onChangeNotes(e) {
        const notes = e.target.value;

        this.setState(prevState => ({
            currentOpportunity: {
                ...prevState.currentOpportunity,
                comment: notes
            },
            currentOpportunityDetails: {
                ...prevState.currentOpportunityDetails,
                comment: notes
            }
        }));
    }

    onChangeClosedTime(e) {
        let time = e.target.value;

        this.setState(prevState => ({
            currentOpportunity: {
                ...prevState.currentOpportunity,
                closedTimeStr: time,
                closedOnStr: time
            }
        }));
    }

    onChangeStatus(e) {
        let status = e.target.value;

        this.setState(prevState => ({
            currentOpportunity: {
                ...prevState.currentOpportunity,
                status: status
            },
            currentOpportunityDetails: {
                ...prevState.currentOpportunityDetails,
                status: status
            }
        }));
    }

    onCancelClick() {
        Event("Cancel Opportunity Clicked", "User clicked button to cancel changes to opportunity", "Cancel Opportunity Changes Clicked");
        this.setState({ currentOpportunity: jsOpportunity, currentOpportunityDetails: jsDetails, message: "Changes canceled!", scheduledDateTime: "" }, () => {
            this.setState({ currentOpportunity: this.state.opportunityList[this.state.isSelected] }, () => {
                this.setState({ currentOpportunityDetails: this.state.currentOpportunity.details });
                if (this.state.currentOpportunity.scheduledForTimeStr !== null && this.state.currentOpportunity.scheduledForTimeStr !== "" && this.state.currentOpportunity.scheduledForTimeStr !== undefined) {
                    let dt = new Date(this.state.currentOpportunity.scheduledForTimeStr);
                    this.setState({ scheduledDateTime: dt });
                }
            })
        });

        setTimeout(() => {
            this.setState({
                message: ""
            });
        }, 3000);
    }

    async onBookClick() {
        let errorMsg = "";

        if (this.state.currentOpportunity.scheduledForTimeStr === "") {
            if (this.state.currentOpportunity.assignedToId === "" || this.state.currentOpportunity.assignedToId == null)
                errorMsg = "Scheduled time and technician are required to book.";
            else errorMsg = "Scheduled time is required to book.";
        }
        else if (this.state.currentOpportunity.assignedToId === "" || this.state.currentOpportunity.assignedToId == null)
            errorMsg = "Technician is required to book.";

        if (errorMsg !== "") {
            this.setState({ message: errorMsg });

            setTimeout(() => {
                this.setState({
                    message: ""
                });
            }, 3000);
        }
        else {
            this.setState({ opportunityLoading: true });
            await ScheduleService.updateopportunity(this.state.currentOpportunity.scheduledForTimeStr, this.state.currentOpportunityDetails.assignedToId, this.state.currentOpportunityDetails)
                .then(response => {
                    if (response.data === true) {
                        this.setState({
                            message: "The opportunity was booked successfully!"
                        }, () => {
                            Event("Book Opportunity Clicked", "User clicked book opportunity button", "Book Opportunity Clicked");
                            this.reloadTable();
                        });
                    }
                    else {
                        this.setState({
                            message: "Failed to book the opportunity!",
                            opportunityLoading: false 
                        });
                    }

                    setTimeout(() => {
                        this.setState({
                            message: ""
                        });
                    }, 3000);
                })
                .catch(e => {
                    console.log(e);
                });
        }
    }

    onCloseClick() {
        let errorMsg = "";
        let errorFields = [];

        if (this.state.currentOpportunity.scheduledForTimeStr === "")
            errorFields.push("Scheduled Time");
        if (this.state.currentOpportunity.assignedToId === "" || this.state.currentOpportunity.assignedToId == null)
            errorFields.push("Technician");
        if (this.state.currentOpportunity.isFalsePositive == null)
            errorFields.push("Verification");
        if (this.state.currentOpportunity.impact === "" || this.state.currentOpportunity.impact == null)
            errorFields.push("Job Value");

        errorMsg = errorFields.join(", ");

        if (errorMsg !== "") {
            var txt = " is ";
            if (errorFields.length > 1) txt = " are ";
            this.setState({ message: errorMsg + txt + "required to close opportunity." });

            setTimeout(() => {
                this.setState({
                    message: ""
                });
            }, 3000);
        }
        else {
            this.setState(prevState => ({
                currentOpportunityDetails: {
                    ...prevState.currentOpportunityDetails,
                    status: "Closed"
                }
            }), () => {
                this.closeOpportunity();
            });
        }
    }

    async closeOpportunity() {
        this.setState({ opportunityLoading: true });
        await ScheduleService.updateopportunity(this.state.currentOpportunity.scheduledForTimeStr, this.state.currentOpportunityDetails.assignedToId, this.state.currentOpportunityDetails)
            .then(response => {
                if (response.data === true) {
                    this.setState({
                        message: ""
                    }, async () => {
                            if (this.state.currentOpportunity.sourceId !== null && this.state.currentOpportunity.sourceId !== undefined)
                                await AlertService.closealert(parseInt(this.state.currentOpportunity.sourceId))
                                    .then(response2 => {
                                        if (response2.data === true) {
                                            this.setState({
                                                message: "The opportunity and associated alert were closed successfully!"
                                            });
                                            Event("Close Opportunity Clicked", "User clicked close opportunity button", "Close Opportunity Clicked");

                                            this.reloadTable();
                                        }
                                    });
                            else
                                this.setState({ message: "Failed to close the associated alert for the opportunity!", opportunityLoading: false });
                    });
                }
                else {
                    this.setState({
                        message: "Failed to close the opportunity!",
                        opportunityLoading: false
                    });
                }

                setTimeout(() => {
                    this.setState({
                        message: ""
                    });
                }, 3000);
            })
            .catch(e => {
                console.log(e);
            });
    }

    async onUpdateClick() {
        this.setState({ opportunityLoading: true });
        await ScheduleService.updateopportunity(this.state.currentOpportunity.scheduledForTimeStr, this.state.currentOpportunityDetails.assignedToId, this.state.currentOpportunityDetails)
            .then(response => {
                if (response.data === true) {
                    this.setState({
                        message: "The opportunity was updated successfully!"
                    });
                    Event("Update Opportunity Clicked", "User clicked update opportunity button", "Update Opportunity Clicked");
                    this.reloadTable();
                }
                else {
                    this.setState({
                        message: "Failed to update the opportunity!",
                        opportunityLoading: false
                    });
                }

                setTimeout(() => {
                    this.setState({
                        message: ""
                    });
                }, 3000);
            })
            .catch(e => {
                console.log(e);
            });
    }

    async onOpportunityClick(index) {
        let x = index;
        //var startTime = performance.now();
        Event("Opportunity Clicked", "User clicked on an opportunity in the table", "Opportunity Clicked");

        this.setState({ currentOpportunity: jsOpportunity, currentOpportunityDetails: jsDetails, currentOpportunityCustomer: jsCustomer, currentHome: jsHouse, selectedTechnician: jsTechnician, opportunityLoading: true, isSelected: index }, () => {
            this.setState({ currentOpportunity: this.state.opportunityList[x] }, async () => {
                await ScheduleService.getopportunitydetails(this.state.currentOpportunity.caseId)
                    .then(response => {
                        if (this._isMounted)
                            this.setState({ currentOpportunityDetails: response.data }, async () => {
                                await AccountService.get(this.state.currentOpportunityDetails.organizationId, false)
                                    .then(response2 => {
                                        if (this._isMounted)
                                            this.setState({ currentOpportunityCustomer: response2.data }, async () => {
                                                await AccountService.getpartnerinfo(this.state.currentOpportunityCustomer.parentAccountId, true)
                                                    .then(response3 => {
                                                        if (this._isMounted)
                                                            this.setState({ currentPartner: response3.data }, () => {
                                                                this.setState({ technicianList: this.state.currentPartner.users.filter(user => user.roleList.includes("Technician")) });
                                                            });
                                                    });
                                            });
                                    });
                                await HomeService.get(this.state.currentOpportunityDetails.organizationId)
                                    .then(response4 => {
                                        if (this._isMounted)
                                            this.setState({ currentHome: response4.data[0], opportunityLoading: false });
                                });
                                if (this.state.currentOpportunityDetails.scheduledFor !== null && this.state.currentOpportunityDetails.scheduledFor !== "" && this.state.currentOpportunityDetails.scheduledFor !== undefined) {
                                    let dt = new Date(this.state.currentOpportunityDetails.scheduledFor.match(/\d+/)[0] * 1);
                                    this.setState({ scheduledDateTime: dt });
                                }
                            });
                    }).catch(e => {
                        console.log(e);
                    });
            });
        });
    }

    async getOpportunities(id) {
        var startTime = performance.now();
        var now = moment();
        var nowStr = moment(now).format();
        var beforeStr = moment(now).add(-3, "months").format();
        this.setState({
            beginStr: beforeStr,
            endStr: nowStr,
            loading: true,
            isSelected: -1,
            currentOpportunity: jsOpportunity,
            currentOpportunityDetails: jsDetails,
            currentOpportunityCustomer: jsCustomer,
            tableViewType: this.state.tableViewType
        }, async () => {
                if (this.props.isCurrentJob) {
                    this.props.getRepairs(this.getRepairsCallback);
                }
                else {
                    await ScheduleService.getopportunities(beforeStr, nowStr)
                        .then(response3 => {
                            if (this._isMounted)
                                this.setState({ opportunityList: response3.data, selectedTechnician: jsTechnician, loading: false }, () => {
                                    this.setState({ unscheduledList: this.state.opportunityList["Unscheduled"], scheduledList: this.state.opportunityList["Scheduled"], closedList: this.state.opportunityList["Closed"], opportunityList: this.state.opportunityList[this.state.tableViewType] });
                                    var elapsedTime = performance.now() - startTime;
                                    Timing("Opportunity List Loading", "loading", elapsedTime, "Opportunity Table List Loading");
                                });
                        })
                        .catch(e => {
                            console.log(e);
                        });
                }
        });
    }

    async getRepairsCallback() {
        var now = moment();
        var nowStr = moment(now).format();
        var beforeStr = moment(now).add(-3, "months").format();
        await ScheduleService.getuseropportunities(beforeStr, nowStr, this.props.alertList)
            .then(response3 => {
                if (this._isMounted)
                    this.setState({ opportunityList: response3.data, selectedTechnician: jsTechnician, loading: false }, () => {
                        this.setState({ scheduledList: this.state.opportunityList["Scheduled"], opportunityList: this.state.opportunityList["Scheduled"] });
                    });
            })
            .catch(e => {
                console.log(e);
            });
    }

    async reloadTable() {
        this.setState({ opportunityLoading: true, isSelected: -1, currentOpportunity: jsOpportunity, currentOpportunityDetails: jsDetails, currentOpportunityCustomer: jsCustomer, selectedTechnician: jsTechnician, selectedTechnicianNumber: -1, currentHome: jsHouse, scheduledDateTime: "" }, async () => {
            await ScheduleService.getopportunities(this.state.beginStr, this.state.endStr)
                .then(response => {
                    if (this._isMounted)
                        this.setState({ opportunityList: response.data, technicianList: this.state.currentPartner.users.filter(user => user.roleList.includes("Technician")), opportunityLoading: false }, () => {
                            this.setState({ unscheduledList: this.state.opportunityList["Unscheduled"], scheduledList: this.state.opportunityList["Scheduled"], closedList: this.state.opportunityList["Closed"], opportunityList: this.state.opportunityList[this.state.tableViewType] })
                        });
                }).catch(e => {
                    console.log(e);
                });
        });
    }

    getCurrOppDate(str) {
        /*var d = new Date(str);
        d.setMonth(d.getMonth() + 1);
        var month = d.getMonth() == 0 ? 12 : d.getMonth();
        var date = month + "/" + d.getDate() + "/" + d.getFullYear();*/

        let curr = str.split("T");
        let date = curr[0];

        return (date);
    }

    getCurrOppTime(str) {
        /*var d = new Date(str);
        var time = d.toLocaleTimeString('en-US');*/

        let curr = str.split("T");
        let time = curr[1];

        return (time);
    }

    getDate(str) {
        var date = "";
        if (moment(str).isValid()) {
            return moment(str).format("l h:mm A");
        }
        return (date);
    }

    convertDate(str) {
        var d = new Date(str);

        return d;
    }

    renderTable(tableViewType) {
        let table = null;

        let unscheduledContent = this.state.unscheduledList.length ?
            <tbody>
                {this.state.unscheduledList.map((opportunity, index) =>
                    <tr key={opportunity.caseId} data={index} onClick={() => { this.onOpportunityClick(index) }} className={this.state.isSelected === index ? "tableSelected" : ""}>
                        <td>{opportunity.number}</td>
                        <td>{opportunity.priorityStr}</td>
                        <td>{opportunity.subject}</td>
                        <td>{this.getDate(opportunity.createdOnTimeStr)}</td>
                    </tr>
                )}
            </tbody>
            : <tbody>
                <tr><td>No Unscheduled Opportunities</td></tr>
            </tbody>;

        let scheduledContent = this.state.scheduledList !== null && this.state.scheduledList !== undefined && this.state.scheduledList.length ?
            <tbody>
                {this.state.scheduledList.map((opportunity, index) =>
                    <tr key={opportunity.caseId} data={index} onClick={() => { this.onOpportunityClick(index) }} className={this.state.isSelected === index ? "tableSelected" : ""}>
                        <td>{opportunity.number}</td>
                        <td>{opportunity.priorityStr}</td>
                        <td>{opportunity.subject}</td>
                        <td>{this.getDate(opportunity.createdOnTimeStr)}</td>
                        <td>{this.getDate(opportunity.scheduledForTimeStr)}</td>
                    </tr>
                )}
            </tbody>
            : <tbody>
                <tr><td>No Scheduled Opportunities</td></tr>
            </tbody>;

        let closedContent = this.state.closedList.length ?
            <tbody>
                {this.state.closedList.map((opportunity, index) =>
                    <tr key={opportunity.caseId} data={index} onClick={() => { this.onOpportunityClick(index) }} className={this.state.isSelected === index ? "tableSelected" : ""}>
                        <td>{opportunity.number}</td>
                        <td>{opportunity.priorityStr}</td>
                        <td>{opportunity.subject}</td>
                        <td>{this.getDate(opportunity.createdOnTimeStr)}</td>
                        <td>{this.getDate(opportunity.closedTimeStr)}</td>
                        <td>{opportunity.impact}</td>
                    </tr>
                )}
            </tbody>
            : <tbody>
                <tr><td>No Closed Opportunities</td></tr>
            </tbody>;

        switch (tableViewType) {
            case "Unscheduled": default:
                table =
                    <div style={{ height: '500px', overflow: "auto" }}>
                        <table className='table table-striped table-bordered table-sm table-hover' aria-labelledby="tabelLabel">
                            <thead>
                                <tr >
                                    <th>Number</th>
                                    <th>Priority</th>
                                    <th>Issue</th>
                                    <th>Created On</th>
                                </tr>
                            </thead>
                            {unscheduledContent}
                        </table>
                    </div>;
                break;
            case "Scheduled":
                table =
                    <div style={{ height: '510px', overflow: "auto" }}>
                        <table className='table table-striped table-bordered table-sm table-hover' aria-labelledby="tabelLabel">
                            <thead>
                                <tr >
                                    <th>Number</th>
                                    <th>Priority</th>
                                    <th>Issue</th>
                                    <th>Created On</th>
                                    <th>Scheduled For</th>
                                </tr>
                            </thead>
                            {scheduledContent}
                        </table>
                    </div>;
                break;
            case "Closed":
                table =
                    <div style={{ height: '545px', overflow: "auto" }}>
                        <table className='table table-striped table-bordered table-sm table-hover' aria-labelledby="tabelLabel">
                            <thead>
                                <tr >
                                    <th>Number</th>
                                    <th>Priority</th>
                                    <th>Issue</th>
                                    <th>Created On</th>
                                    <th>Closed On</th>
                                    <th>Job Value</th>
                                </tr>
                            </thead>
                            {closedContent}
                        </table>
                    </div>;
                break;
        }

        return table;
    }

    renderForm(tableViewType) {
        let form = null;

        switch (tableViewType) {
            case "Unscheduled": default:
                form =
                    <small>
                        <div className="row no-gutters">
                            <div className="col-4">
                                <p className="semibold">Scheduled:</p>
                            </div>
                            <div className="col-8">
                                <DatePicker
                                    customInput={<input className="txt-detail" style={{ paddingLeft: '2px' }} />}
                                    id="scheduleDatetime"
                                    name="scheduleDateTime"
                                    selected={this.state.scheduledDateTime}
                                    onChange={date => this.onChangeScheduleDateTime(date)}
                                    placeholderText="--Select Date/Time--"
                                    dateFormat="MM/dd/yyyy h:mm:ss aa"
                                    timeIntervals={15}
                                    showTimeSelect
                                    popperPlacement="top-end"
                                    popperProps={{
                                        positionFixed: true
                                    }}
                                />
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-4">
                                <p className="semibold">Assign To:</p>
                            </div>
                            <div className="col">
                                <select className="txt-detail" value={this.state.currentOpportunity.assignedToId == null ? "" : this.state.currentOpportunity.assignedToId} onChange={this.onChangeTechnician} style={{ paddingLeft: '2px', paddingRight: '18px' }}>
                                    <option value="" disabled defaultValue hidden>--Select Technician--</option>
                                    {this.state.currentPartner.users.map((tech, index) =>
                                        <option key={`${index}-${tech.userId}`} id={`${index}`} value={tech.userId}>{tech.lastName + ", " + tech.firstName + ` (${tech.userId})`}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                        <div className="row no-gutters lastItem">
                            <div className="col-10">
                                <br />
                            </div>
                            <div className="col">
                                <div className="btn-right">
                                    <button className="secondary-btn btn-small" onClick={this.onCancelClick}>Cancel</button>
                                    <button className="secondary-btn btn-small" onClick={this.onBookClick}>Book</button>
                                </div>
                            </div>
                        </div>
                    </small>;
                break;
            case "Scheduled":
                form =
                    <small>
                        <div className="row no-gutters">
                            <div className="col-4">
                                <p className="semibold">Scheduled:</p>
                            </div>
                            <div className="col-8">
                                <DatePicker
                                    customInput={<input className="txt-detail" style={{ paddingLeft: '2px' }} />}
                                    id="scheduleDatetime"
                                    name="scheduleDateTime"
                                    selected={this.state.scheduledDateTime}
                                    onChange={date => this.onChangeScheduleDateTime(date)}
                                    placeholderText="--Select Date/Time--"
                                    dateFormat="MM/dd/yyyy h:mm:ss aa"
                                    timeIntervals={15}
                                    showTimeSelect
                                    popperPlacement="top-end"
                                    popperProps={{
                                        positionFixed: true
                                    }}
                                />
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-4">
                                <p className="semibold">Assign To:</p>
                            </div>
                            <div className="col">
                                <select className="txt-detail" value={this.state.currentOpportunity.assignedToId == null ? "" : this.state.currentOpportunity.assignedToId} onChange={this.onChangeTechnician} style={{ paddingLeft: '2px', paddingRight: '18px' }}>
                                    <option value="" disabled defaultValue hidden>--Select Technician--</option>
                                    {this.state.currentPartner.users.map((tech, index) =>
                                        <option key={`${index}-${tech.userId}`} id={`${index}-${tech.userId}`} value={tech.userId}>{tech.lastName + ", " + tech.firstName + ` (${tech.userId})`}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-4">
                                <p className="semibold">Verification:</p>
                            </div>
                            <div className="col">
                                <select className="txt-detail" value={this.state.currentOpportunity.isFalsePositive == null ? "" : this.state.currentOpportunity.isFalsePositive} onChange={this.onChangeVerification}>
                                    <option key="1" id="1" defaultValue value="">Unverified</option>
                                    <option key="2" id="2" value={false}>Verified</option>
                                    <option key="3" id="3" value={true}>False Positive</option>
                                </select>
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-4">
                                <p className="semibold">Job Value:</p>
                            </div>
                            <div className="col">
                                <input
                                    type="number"
                                    className="txt-detail"
                                    id="jobValue"
                                    name="jobValue"
                                    placeholder="Dollars($)"
                                    value={this.state.currentOpportunityDetails.impactEstimate == null ? "" : String(this.state.currentOpportunityDetails.impactEstimate)}
                                    onChange={this.onChangeJobValue}
                                />
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-4">
                                <p className="semibold">Notes:</p>
                            </div>
                            <div className="col">
                                <input
                                    type="text"
                                    className="txt-detail"
                                    id="notes"
                                    name="notes"
                                    value={this.state.currentOpportunity.comment == null ? "" : this.state.currentOpportunity.comment}
                                    onChange={this.onChangeNotes}
                                />
                            </div>
                        </div>
                        <div className="row no-gutters lastItem">
                            <div className="col-10">
                                <br />
                            </div>
                            <div className="col">
                                <div className="btn-right">
                                    <button className="secondary-btn btn-small" onClick={this.onCancelClick}>Cancel</button>
                                    <button className="secondary-btn btn-small" onClick={this.onUpdateClick}>Update</button>
                                    <button className="secondary-btn btn-small" onClick={this.onCloseClick}>Close</button>
                                </div>
                            </div>
                        </div>
                    </small>;
                break;
            case "Closed":
                form =
                    <small>
                        <div className="row no-gutters">
                            <div className="col-4">
                                <p className="semibold">Scheduled:</p>
                            </div>
                            <div className="col-8">
                                <DatePicker
                                    customInput={<input className="txt-detail" style={{ paddingLeft: '2px' }} />}
                                    id="scheduleDatetime"
                                    name="scheduleDateTime"
                                    selected={this.state.scheduledDateTime}
                                    onChange={date => this.onChangeScheduleDateTime(date)}
                                    dateFormat="MM/dd/yyyy h:mm:ss aa"
                                    timeIntervals={15}
                                    showTimeSelect
                                    popperPlacement="top-end"
                                    popperProps={{
                                        positionFixed: true
                                    }}
                                />
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-4">
                                <p className="semibold">Assign To:</p>
                            </div>
                            <div className="col">
                                <select className="txt-detail" value={this.state.currentOpportunity.assignedToId == null ? "" : this.state.currentOpportunity.assignedToId} onChange={this.onChangeTechnician} style={{ paddingLeft: '2px', paddingRight: '18px' }}>
                                    <option value="" disabled defaultValue hidden>--Select Technician--</option>
                                    {this.state.currentPartner.users.map((tech, index) =>
                                        <option key={`${index}-${tech.userId}`} id={`${index}-${tech.userId}`} value={tech.userId}>{tech.lastName + ", " + tech.firstName + ` (${tech.userId})`}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-4">
                                <p className="semibold">Verification:</p>
                            </div>
                            <div className="col">
                                <select className="txt-detail" value={this.state.currentOpportunity.isFalsePositive == null ? "" : this.state.currentOpportunity.isFalsePositive} onChange={this.onChangeVerification}>
                                    <option key="1" id="1" defaultValue value="">Unverified</option>
                                    <option key="2" id="2" value={false}>Verified</option>
                                    <option key="3" id="3" value={true}>False Positive</option>
                                </select>
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-4">
                                <p className="semibold">Job Value:</p>
                            </div>
                            <div className="col">
                                <input
                                    type="number"
                                    className="txt-detail"
                                    id="jobValue"
                                    name="jobValue"
                                    placeholder="Dollars($)"
                                    value={this.state.currentOpportunityDetails.impactEstimate == null ? "" : String(this.state.currentOpportunityDetails.impactEstimate)}
                                    onChange={this.onChangeJobValue}
                                />
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-4">
                                <p className="semibold">Notes:</p>
                            </div>
                            <div className="col">
                                <input
                                    type="text"
                                    className="txt-detail"
                                    id="notes"
                                    name="notes"
                                    value={this.state.currentOpportunity.comment == null ? "" : this.state.currentOpportunity.comment}
                                    onChange={this.onChangeNotes}
                                />
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-4">
                                <p className="semibold">Status:</p>
                            </div>
                            <div className="col">
                                <select className="txt-detail" value={this.state.currentOpportunity.status} onChange={this.onChangeStatus}>
                                    <option key="1" id="1" value="New">New</option>
                                    <option key="2" id="2" value="Assigned">Assigned</option>
                                    <option key="3" id="3" value="Scheduled">Scheduled</option>
                                    <option key="4" id="4" value="Working">Working</option>
                                    <option key="5" id="5" value="Closed">Closed</option>
                                    <option key="6" id="6" value="Resolved">Resolved</option>
                                </select>
                            </div>
                        </div>
                        <div className="row no-gutters lastItem">
                            <div className="col-10">
                                <br />
                            </div>
                            <div className="col">
                                <div className="btn-right">
                                    <button className="secondary-btn btn-small" onClick={this.onCancelClick}>Cancel</button>
                                    <button className="secondary-btn btn-small" onClick={this.onUpdateClick}>Update</button>
                                </div>
                            </div>
                        </div>
                    </small>;
                break;
        }

        return form;
    }

    renderOpportunities() {
        const { classes } = this.props;

        let opportunityLoader = this.state.opportunityLoading
            ? <Loader />
            : null;

        let radios = this.props.isCurrentJob ?
            null
            : <div className="radio-container">
                <span>
                    <input type="radio" value="Unscheduled" onChange={e => this.changeRadio(e)} checked={this.state.tableViewType === "Unscheduled"} name="tableViewType" />
                    &nbsp; Unscheduled &nbsp;&nbsp;
                            <input type="radio" value="Scheduled" onChange={e => this.changeRadio(e)} checked={this.state.tableViewType === "Scheduled"} name="tableViewType" />
                    &nbsp; Scheduled &nbsp;&nbsp;
                            <input type="radio" value="Closed" onChange={e => this.changeRadio(e)} checked={this.state.tableViewType === "Closed"} name="tableViewType" />
                    &nbsp; Closed &nbsp;&nbsp;
                            </span>
            </div>;

        let customerPanel = this.props.isCurrentJob ?
            null
            : <div className="grey-container">
                <h5 className="sectionTitle">Customer</h5>
                <small>
                    <div className="row no-gutters">
                        <div className="col-4">
                            <p className="semibold">Customer:</p>
                        </div>
                        <div className="col">
                            <p>{this.state.currentOpportunityCustomer.name}</p>
                        </div>
                    </div>
                    <div className="row no-gutters">
                        <div className="col-4">
                            <p className="semibold">Phone:</p>
                        </div>
                        <div className="col">
                            <p>{this.state.currentOpportunityCustomer.phone}</p>
                        </div>
                    </div>
                    <div className="row no-gutters">
                        <div className="col-4">
                            <p className="semibold">Email:</p>
                        </div>
                        <div className="col">
                            <p>{this.state.currentOpportunityCustomer.familyMembers !== null && this.state.currentOpportunityCustomer.familyMembers !== undefined
                                ? this.state.currentOpportunityCustomer.familyMembers[0] !== undefined ? this.state.currentOpportunityCustomer.familyMembers[0].email : "" : ""}</p>
                        </div>
                    </div>
                    <hr />
                    <div className="row no-gutters">
                        <div className="col-4">
                            <p className="semibold">Address:</p>
                        </div>
                        <div className="col">
                            <p>{this.state.currentHome !== null && this.state.currentHome !== undefined ?
                                this.state.currentHome.address : ""}</p>
                        </div>
                    </div>
                    <div className="row no-gutters">
                        <div className="col-4">
                            <p className="semibold">City:</p>
                        </div>
                        <div className="col">
                            <p>{this.state.currentHome !== null && this.state.currentHome !== undefined ?
                                this.state.currentHome.city : ""}</p>
                        </div>
                    </div>
                    <div className="row no-gutters">
                        <div className="col-4">
                            <p className="semibold">State:</p>
                        </div>
                        <div className="col">
                            <p>{this.state.currentHome !== null && this.state.currentHome !== undefined ?
                                this.state.currentHome.state : ""}</p>
                        </div>
                    </div>
                    <div className="row no-gutters lastItem">
                        <div className="col-4">
                            <p className="semibold">Zip:</p>
                        </div>
                        <div className="col">
                            <p>{this.state.currentHome !== null && this.state.currentHome !== undefined ?
                                this.state.currentHome.zip : ""}</p>
                        </div>
                    </div>
                </small>
            </div>;

        return (
            <div className="row no-gutters">
                <div className="col-sm-12 col-md-8">
                    <div className="white-container">
                        <h5 className="sectionTitle">Leads</h5>
                        {radios}
                        <div className="table-container-2">
                            {this.renderTable(this.state.tableViewType)}
                        </div>
                    </div>
                    <br />
                    <p className="status-msg">{this.state.message}</p>
                </div>
                <div className="col-sm-12 col-md-4">
                    {customerPanel}
                    <div className="grey-container">
                        <h5 className="sectionTitle">LifeWhere</h5>
                        {this.renderForm(this.state.tableViewType)}
                    </div>
                </div>
                {opportunityLoader}
            </div>
        );
    }

    render() {
        const { classes } = this.props;

        let contents = this.state.loading
            ? <Loader />
            : this.renderOpportunities();

        return (
            <div>
                {contents}
            </div>
        );
    }
}

export default BaseScheduleRepairs;