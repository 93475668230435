import React, {useState} from 'react';
import { trainingTooltips } from "../../../componentObjects";
import IconTooltip from '@mui/material/Tooltip';
import OnboardingDashboardCard from './OnboardingDashboardCard';


function AssetStatesRow(props) {
    const { title, assetStateCounts, onAssetsNeedTrainingClick } = props;

    let assetsNeedTrainContent = <div className="row no-gutters card-row" style={{ textAlign: 'center' }}>
            <div className="col" style={{ minHeight: '40px', borderRight: '1px solid lightgrey'  }}>
                <div className="row no-gutters dashboard-val" style={{ justifyContent: 'center'}}>
                    <IconTooltip  placement="top" title={trainingTooltips.new}
                                                enterDelay={600} leaveDelay={200} >
                        <div className="col">
                            <div className="dashboard-number db-num3" >
                                {assetStateCounts.new}
                            </div>
                        </div>
                    </IconTooltip>
                </div>
                <div>
                    <small style={{ color: "dimGray", fontSize: '80%', textAlign: 'center' }}>New</small>
                </div>
            </div>

            <div className="col" style={{ minHeight: '40px', borderRight: '1px solid lightgrey' }}>
                <div className="row no-gutters dashboard-val" style={{ justifyContent: 'center' }}>
                    <IconTooltip  placement="top" title={trainingTooltips.awaitingData}
                                                enterDelay={600} leaveDelay={200} >
                        <div className="col" style={{ textAlign: 'center' }}>
                            <div className="dashboard-number db-num3">
                                {assetStateCounts.awaitingData}
                            </div>
                        </div>
                    </IconTooltip>
                </div>
                <div>
                    <small style={{ color: "dimGray", fontSize: '80%', textAlign: 'center' }}>Awaiting Data</small>
                </div>
            </div>

            <div className="col" style={{ minHeight: '40px', borderRight: '1px solid lightgrey' }}>
                <div className="row no-gutters dashboard-val" style={{ justifyContent: 'center' }}>
                    <IconTooltip  placement="top" title={trainingTooltips.training}
                                                enterDelay={600} leaveDelay={200} >
                        <div className="col" style={{ textAlign: 'center' }}>
                            <div className="dashboard-number db-num3">
                                {assetStateCounts.training}
                            </div>
                        </div>
                    </IconTooltip>
                </div>
                <div>
                    <small style={{ color: "dimGray", fontSize: '80%', textAlign: 'center' }}>Training</small>
                </div>
            </div>

            <div className="col" style={{ minHeight: '40px', borderRight: '1px solid lightgrey' }}>
                <div className="row no-gutters dashboard-val" style={{ justifyContent: 'center' }}>
                    <IconTooltip  placement="top" title={trainingTooltips.trained}
                                                enterDelay={600} leaveDelay={200} >
                        <div className="col" style={{ textAlign: 'center' }}>
                            <div className="dashboard-number db-num3">
                                {assetStateCounts.trained}
                            </div>
                        </div>
                    </IconTooltip>
                </div>
                <div>
                    <small style={{ color: "dimGray", fontSize: '80%', textAlign: 'center' }}>Trained</small>
                </div>
            </div>

            <div className="col" style={{ minHeight: '40px', borderRight: '1px solid lightgrey' }}>
                <div className="row no-gutters dashboard-val" style={{ justifyContent: 'center' }}>
                    <IconTooltip  placement="top" title={trainingTooltips.operational}
                                                enterDelay={600} leaveDelay={200} >
                        <div className="col" style={{ textAlign: 'center' }}>
                            <div className="dashboard-number db-num3">
                                {assetStateCounts.operational}
                            </div>
                        </div>
                    </IconTooltip>
                </div>
                <div>
                    <small style={{ color: "dimGray", fontSize: '80%', textAlign: 'center' }}>Operational</small>
                </div>
            </div>

            <div className="col" style={{ minHeight: '40px', borderRight: '1px solid lightgrey' }}>
                <div className="row no-gutters dashboard-val" style={{ justifyContent: 'center' }}>
                    <IconTooltip  placement="top" title={trainingTooltips.reprovision}
                                                enterDelay={600} leaveDelay={200} >
                        <div className="col" style={{ textAlign: 'center' }}>
                            <div className="dashboard-number db-num3">
                                {assetStateCounts.reprovision}
                            </div>
                        </div>
                    </IconTooltip>
                </div>
                <div>
                    <small style={{ color: "dimGray", fontSize: '80%', textAlign: 'center' }}>Reprovision</small>
                </div>
            </div>

            <div className="col" style={{ minHeight: '40px' }}>
                <div className="row no-gutters dashboard-val" style={{ justifyContent: 'center' }}>
                    <IconTooltip  placement="top" title={trainingTooltips.retrain}
                                                enterDelay={600} leaveDelay={200} >
                        <div className="col" style={{ textAlign: 'center' }}>
                            <div className="dashboard-number db-num3">
                                {assetStateCounts.retrain}
                            </div>
                        </div>
                    </IconTooltip>
                </div>
                <div>
                    <small style={{ color: "dimGray", fontSize: '80%', textAlign: 'center' }}>Retrain</small>
                </div>
            </div>
        </div>;

    return (
        <div className="row" style={{ padding: '30px 30px 0px' }}>
            <div className="col" style={{ display: 'grid' }}>
                <OnboardingDashboardCard title={title} content={assetsNeedTrainContent} onClick={onAssetsNeedTrainingClick} isGraphCard={false}/>
            </div>
        </div>
    );
}

export default AssetStatesRow;