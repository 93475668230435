/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import "../../CSS/DataModal.css";
import FullLoader from "../Loader/FullLoader";

class CancelModal extends Component {

    onClick = e => {
        this.props.onClick && this.props.onClick(e);
    };

    render() {

        if (!this.props.show) {
            return null;
        }
        else {
            let loader = this.props.loading ?
                <FullLoader/>
                : <div>{null}</div>;

            let tableContent = this.props.deviceContracts ?
                <tbody>
                    {this.props.deviceContracts.map((asset, index) =>
                        <tr className="alerts-table-row cancel-table" key={`${asset.deviceId}-tableRow`} data={index} value={index} onClick={() => this.onDeviceRowClick(index)}>
                            <td style={{ borderLeft: '1px solid #ebeef0' }}>{asset.deviceId}</td>
                            <td>{asset.start}</td>
                            <td>{asset.end}</td>
                            <td>{asset.duration}</td>
                            <td>{asset.remaining}</td>
                            <td style={{ borderRight: '1px solid #ebeef0' }}>{asset.amountToBeCharged}</td>
                        </tr>
                )}
                </tbody>
                : <tbody>
                    <tr>
                        <td>No Devices to Show!</td>
                    </tr>
                </tbody>;

            return (
                <div className="modal" style={{ display: this.props.show ? "block" : "none" }}>
                    <div className="modal-wrapper"
                        style={{
                            transform: this.props.show ? 'translateY(0vh)' : 'translateY(-100vh)',
                            opacity: this.props.show ? '1' : '0'
                        }}>

                        <div className="modal-header">
                            <h3>Cancel Subscription</h3>
                            <span className="close-modal-btn" onClick={e => { this.onClick(e); }}>×</span>
                        </div>

                        <div className="modal-body">
                            <br />
                            <p style={{ padding: '0px 70px' }}>
                                You are about to remove customer <b>{this.props.customerName}</b> from the System. This action cannot be reversed are you sure you want to do this?
                            </p>
                            <br />
                            <div className="table-container-2">
                                <table className='table-striped table-bordered table-sm table-hover' aria-labelledby="tabelLabel" style={{ width: '100%' }}>
                                    <thead>
                                        <tr className="alerts-table-row cancel-table">
                                            <th>Device ID</th>
                                            <th>Contract Start Date</th>
                                            <th>Contract End Date</th>
                                            <th>Contract Duration</th>
                                            <th>Contract Remaining</th>
                                            <th>Amount To Be Charged</th>
                                        </tr>
                                    </thead>
                                    {tableContent}
                                </table>
                            </div>
                            {loader}
                        </div>

                        <div className="modal-footer">
                            <button className="secondary-btn btn-small" onClick={e => { this.onClick(e); }}>No</button>
                            <button type='button' className="primary-btn btn-small" onClick={this.props.onCancel}>Yes</button>
                        </div>
                    </div>
                </div>
            );
        }
    }
}


export default CancelModal;