import React from "react";
import {Spinner} from "react-bootstrap";

const Loader = ({children}) => {

    return (
        <div className={["loading"].join(' ')} style={{display: "block"}} sx={(theme) => ({
            marginLeft: '225px',
            [theme.breakpoints.down('md')]: {
                marginLeft: '-225px !important',
                width: `calc(100% + 450px)`,
            },
        })}>
            <div className="loading-wrapper">
                {children}
                <div className="modal-body"><Spinner animation="border" variant="light"/></div>
            </div>
        </div>
    );
}

export default Loader