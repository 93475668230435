/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React from 'react';
import { Image } from 'react-bootstrap';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';

class SearchCustomer extends React.Component {

    constructor(props) {
        super(props);

        this.onSearchTypeChange = this.onSearchTypeChange.bind(this);
        this.handleOnSearch = this.handleOnSearch.bind(this);
        this.handleOnSelect = this.handleOnSelect.bind(this);

        this.state = {
            searchType: "name",
            inputValue: "",
            autocompletedItems: []
        }
    }

    async onSearchTypeChange(e) {
        var str = e.target.value;

        if (str === "email" || str === "address") {
            //this.props.setCustomerList(str);
        }

        //todo create a callback for loading effecting the search bar ? (set a bool to change the disabled & placeholder text below)

        this.props.setSearchType(str);
    }

    onFocus = () => {

    }

    handleOnSearch = (string, results) => {
        if (results !== undefined && results.length > 0) {
            this.setState({ inputValue: string, autocompletedItems: results });
        }
    };

    handleOnSelect = (e) => {
        this.setState({ inputValue: "", autocompletedItems: [] }, () => {
            if (e !== undefined) {
                this.props.onCustomerSearch(e);
            }
        });
    }

    componentDidUpdate() {
        document.querySelector(".search-container input").value = "";
    }

    render() {
        let searchStr = this.props.searchType;
        let customersRemapped = this.props.customers.map(customer => ({ id: customer.organizationId, name: customer.name, phone: customer.phone, organizationId: customer.organizationId }));

        return (
            <div className="sidebar-search-container search-container">
                <span className="sidebar-select-message search-message">
                    <select className="sidebar-search-select" value={this.props.searchType} onChange={this.onSearchTypeChange}>
                        <option key="1" id="1" value="name">Search Name &nbsp;</option>
                        <option key="2" id="2" value="phone">Search Phone # &nbsp;</option>
                    </select>
                </span>
                <div>
                    <span>
                        <span>
                            <ReactSearchAutocomplete
                                items={customersRemapped}
                                fuseOptions={{ threshold: "0.2", distance: "2", ignoreLocation: true, keys: [searchStr] }}
                                onSelect={this.handleOnSelect}
                                onFocus={this.onFocus}
                                onSearch={this.handleOnSearch}
                                inputSearchString={this.state.inputValue}
                                placeholder={"Search Customers"}
                                styling={{
                                    height: '38px',
                                    borderRadius: '.3em'
                                }}
                                />
                        </span>
                    </span>
                </div>
            </div>
        );
    }
}

SearchCustomer.defaultProps= {
        customers: [],
        customerName: null,
        onCustomerSearch: null,
        setCustomerList: [],
        searchType: null,
        setSearchType: null,
        emailLoaded: false,
        addressLoaded: false
};
export default SearchCustomer;