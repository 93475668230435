/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import { Image, Spinner, Form } from 'react-bootstrap';
import AccountService from "../../../services/account.service";
import ConfirmationModal from "../../ConfirmationModal/ConfirmationModal";
import UpdateImageModal from './UpdateImageModal';
import { PageView, Event, ModalView } from "../../GoogleAnalytics";

import memoize from "memoize-one";
import {withRouter} from "../../Routing/withRouter";
import Loader from "../../Loader/Loader";

const jsAccount = {
    firstName: null,
    lastName: null,
    email: null,
    picture: null
};

const jsUploadResults = {
    id: null,
    picture: null,
    data: null
};

class UserAccount extends Component {
    static displayName = UserAccount.name;
    _isMounted = false;

    constructor(props) {
        super(props);
        this.getAccount = this.getAccount.bind(this);
        this.onChangeName = this.onChangeName.bind(this);
        this.onChangeLastName = this.onChangeLastName.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onSave = this.onSave.bind(this);
        this.openCancelModal = this.openCancelModal.bind(this);
        this.showCancelModal = this.showCancelModal.bind(this);
        this.openSaveModal = this.openSaveModal.bind(this);
        this.showSaveModal = this.showSaveModal.bind(this);
        this.onUpdateLogo = this.onUpdateLogo.bind(this);
        this.openLogoModal = this.openLogoModal.bind(this);
        this.showLogoModal = this.showLogoModal.bind(this);
        this.onLogoUpload = this.onLogoUpload.bind(this);
        this.updateAccount = this.updateAccount.bind(this);
        this.handleLogoChange = this.handleLogoChange.bind(this);

        this.onContinueWithoutSaving = this.onContinueWithoutSaving.bind(this);
        this.onSaveThenContinue = this.onSaveThenContinue.bind(this);
        this.setPathCallback = this.setPathCallback.bind(this);

        this.state = {
            currentAccount: localStorage.getItem("currentAccount") || jsAccount,
            uploadLogoResults: jsUploadResults,
            currentLogo: localStorage.getItem("currentLogo") || null,
            updateLogo: null,
            updateLogoPath: null,
            logoBytes: [],
            logoFileStorage: {
                name: '',
                byteArray: [],
                binaryString: ''
            },
            message: "",
            loading: true,
            showCancelConfirm: false,
            showSaveConfirm: false,
            showLogoUpdate: false,
            modalMessage: "",
            modalTitle: "",
            unsavedChanges: false,
            showUnsavedModal: null
        };
    }

    getStorageValue(key, defaultValue, isJson = false) {
        const saved = localStorage.getItem(key);
        var value = saved !== null ? saved : defaultValue;
        if (isJson && value !== defaultValue && value !== "") {
            try {
                value = JSON.parse(value);
            }
            catch(e) {
                console.log(e);
                console.log(`error parsing value: ${value}`);
            }
        }
        return value;
    }

    componentDidMount() {
        this._isMounted = true;
        PageView();
        let unsavedChanges = this.getStorageValue("unsavedChanges", false);
        if (unsavedChanges === 'true' || unsavedChanges === true) {
            let account = this.getStorageValue("currentAccount", jsAccount, true);
            let logo = this.getStorageValue("currentLogo", null, true);
            let updateLogo = this.getStorageValue("updateLogo", null, true);

            this.setState({ unsavedChanges: unsavedChanges, currentAccount: account, currentLogo: logo, updateLogo: updateLogo, loading: false });
        }
        else
            this.getAccount();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    memAccount = memoize(this.getAccount);

    onChangeName(e) {
        const newName = e.target.value;

        this.setState(function (prevState) {
            return {
                unsavedChanges: true,
                currentAccount: {
                    ...prevState.currentAccount,
                    firstName: newName
                }
            };
        }, () => {
            localStorage.setItem("unsavedChanges", true);
            localStorage.setItem("currentAccount", JSON.stringify(this.state.currentAccount));
        });
    }

    onChangeLastName(e) {
        const newName = e.target.value;
        this.setState(function (prevState) {
            return {
                unsavedChanges: true,
                currentAccount: {
                    ...prevState.currentAccount,
                    lastName: newName
                }
            };
        }, () => {
            localStorage.setItem("unsavedChanges", true);
            localStorage.setItem("currentAccount", JSON.stringify(this.state.currentAccount));
        });
    }

    showCancelModal() {
        this.setState({
            showCancelConfirm: !this.state.showCancelConfirm
        }, () => {
                if (this.state.showCancelConfirm)
                    ModalView("cancelConfirm");
        });
        window.scrollTo(0, 0);
    }

    openCancelModal() {
        this.setState({ modalMessage: "Are you sure you want to undo all changes?", modalTitle: "Undo Changes" }, () => {
            this.showCancelModal();
        });
    }

    showSaveModal() {
        this.setState({
            showSaveConfirm: !this.state.showSaveConfirm
        }, () => {
                if (this.state.showSaveConfirm)
                    ModalView("userAccount/saveConfirm");
        });
        window.scrollTo(0, 0);
    }

    openSaveModal() {
        this.setState({ modalMessage: "Are you sure you want to save all changes?", modalTitle: "Save Changes" }, () => {
            this.showSaveModal();
        });
    }

    async onSave() {
        this.setState({ loading: true, showSaveConfirm: !this.state.showSaveConfirm }, async () => {
            if (this.state.updateLogo !== null) {
                await this.onUpdateLogo();
            }
            else {
                await this.updateAccount();
            }
        });
    }

    onCancel() {
        this.setState({
            updateLogo: null,
            updateLogoPath: null,
            currentAccount: this.props.currentAccount,
            showCancelConfirm: !this.state.showCancelConfirm,
            message: "Changes undone successfully!"
        }, () => {
            localStorage.setItem("unsavedChanges", false);
            localStorage.setItem("showUnsavedModal", false);
            localStorage.setItem("currentAccount", JSON.stringify(this.props.currentAccount));
            localStorage.setItem("updateLogo", null);
        });

        Event("User Cancel", "User clicked cancel button in user account screen", "Cancel User Account Changes Clicked");

        setTimeout(() => {
            this.setState({
                message: ""
            });
        }, 3000);
    }

    onSaveThenContinue() {
        this.setState({ showUnsavedModal: false, loading: true }, () => {
            localStorage.setItem("showUnsavedModal", false);
            let account = this.getStorageValue("currentAccount", jsAccount);
            if (account !== jsAccount)
                account = JSON.parse(account);
            let updateLogo = this.getStorageValue("updateLogo", null);
            if (updateLogo !== null)
                updateLogo = JSON.parse(updateLogo);
            
            this.setState({ currentAccount: account, updateLogo: updateLogo }, async () => {
                if (this.state.updateLogo !== null) {
                    await this.onUpdateLogo(true);
                }
                else {
                    await this.updateAccount(true);
                }
            });
        })
    }

    onContinueWithoutSaving() {
        this.setState({ loading: false });
        localStorage.setItem("unsavedChanges", false);
        localStorage.setItem("showUnsavedModal", false);

        this.props.onContinueFromUserAccount(this.state.currentAccount, this.setPathCallback);
    }

    setPathCallback() {
        let nextPath = this.getStorageValue("nextPath", null);
        localStorage.setItem("currentAccount", JSON.stringify(this.props.currentAccount));
        this.props.navigate(nextPath);
    }

    showLogoModal() {
        this.setState({
            showLogoUpdate: !this.state.showLogoUpdate
        }, () => {
                if (this.state.showLogoUpdate)
                    ModalView("imageUpload");
        });
        window.scrollTo(0, 0);
    }

    openLogoModal() {
        this.setState({ modalMessage: "Choose new Logo to upload (PNG, JPG):", modalTitle: "Update Logo" }, () => {
            this.showLogoModal();
        });
    }

    onLogoUpload() {
        Event("User Update Picture", "User clicked update button to upload new user account picture", "Update User Picture Clicked");
        this.setState({
            showLogoUpdate: !this.state.showLogoUpdate
        });
    }

    async onUpdateLogo(goNextPage = false) {
        AccountService.uploadaccountimage(this.state.logoFileStorage)
            .then(response => {
                if (this._isMounted) {
                    this.setState({
                        uploadLogoResults: response.data
                    }, () => {
                            this.setState(prevState => ({
                                currentAccount: {
                                    ...prevState.currentAccount,
                                    picture: this.state.uploadLogoResults.data
                                }
                            }), async () => {
                                    localStorage.setItem("currentAccount", JSON.stringify(this.state.currentAccount));
                                    await this.updateAccount(goNextPage);
                            });
                    });
                }
            })
            .catch(e => {
                console.log(e);
            });
    }

    handleLogoChange(file) {
        this.setState({ updateLogo: file, updateLogoPath: file.name });
        let reader = new FileReader();
        var fileByteArray = [];
        var binaryString = "";
        reader.readAsArrayBuffer(file);
        reader.onloadend = function (e) {
            if (e.target.readyState === FileReader.DONE) {
                var arrayBuffer = e.target.result,
                    array = new Uint8Array(arrayBuffer),
                    binaryString = new Uint8Array(arrayBuffer).reduce(
                        function (data, byte) {
                            return data + String.fromCharCode(byte);
                        },
                        ''
                    );
                console.log(binaryString);

                for (var i = 0; i < array.length; i++) {
                    fileByteArray.push(array[i]);
                }
            }
        }
        this.setState({ logoFileStorage: { name: file.name, byteArray: fileByteArray, binaryString: binaryString }, logoBytes: fileByteArray });
    }

    async updateAccount(goToNextPage = false) {
        await AccountService.updateuserinfo(this.props.loggedInCustomerOrganizationId, this.state.currentAccount)
            .then(response => {
                if (this._isMounted) {
                    if (response.data === true) {
                        this.setState({
                            message: "Account updated successfully!",
                            loading: false
                        }, () => {
                                localStorage.setItem("unsavedChanges", false);
                                localStorage.setItem("showUnsavedModal", false);
                                //localStorage.setItem("currentAccount", jsAccount);
                                if (goToNextPage)
                                    this.onContinueWithoutSaving();
                                else 
                                    this.props.updateAccountInfo(this.state.currentAccount);
                        });

                        Event("User Update", "User clicked save button in user account screen", "Save User Account Changes");
                    }
                    else {
                        this.setState({
                            message: "Failed to update account!",
                            loading: false
                        });
                    }

                    
                }
            })
            .catch(e => {
                console.log(e);
            });
    }

    async getAccount() {
        let account = this.props.currentAccount;
        if (account.userId === null)
            account.userId = this.props.userId.toString();
        this.setState({ currentAccount: account, loading: false }, () => {
            localStorage.setItem("unsavedChanges", false);
            localStorage.setItem("currentAccount", JSON.stringify(this.state.currentAccount));
        });
    }

    renderAccount() {
        return (
            <div>
                <div className="row" style={{ display: 'flex' }}>
                    <div className="col-sm-12 col-md-10 col-lg-6">
                        <div className="white-container"> 
                            <div className="row no-gutters table-container">
                                <div className="col-12">
                                <Form>
                                    <Form.Group className="mb-3" controlId="name">
                                        <Form.Label>First Name</Form.Label>
                                            <Form.Control type="text" placeholder="Enter first name" value={this.state.currentAccount.firstName == null ? "" : this.state.currentAccount.firstName} onChange={this.onChangeName} />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="lastname">
                                        <Form.Label>Last Name</Form.Label>
                                            <Form.Control type="text" placeholder="Enter last name" value={this.state.currentAccount.lastName == null ? "" : this.state.currentAccount.lastName} onChange={this.onChangeLastName} />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Email address</Form.Label>
                                            <Form.Control type="email" placeholder="Enter email" value={this.state.currentAccount.email == null ? "" : this.state.currentAccount.email} readOnly />
                                    </Form.Group>
                                    </Form>
                                </div>
                                <div className="col">
                                    <span>Picture:</span>
                                </div>
                            </div>
                            <div className="row no-gutters table-container">
                                <div className="col">
                                    <Image src={this.state.updateLogo != null ? URL.createObjectURL(this.state.updateLogo) : this.state.currentAccount.picture == null ? require("../../../img/NoImage2.png") : "data:image/png;base64," + this.state.currentAccount.picture} style={{ height: 6 + "em", padding: 10 + "px" }} />
                                </div>
                                <div className="col">
                                    <button
                                        type="button"
                                        className="secondary-btn btn-small btn-right"
                                        onClick={this.openLogoModal}
                                        style={{ marginRight: '0px' }}
                                    >
                                        Update
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col">
                        <p className="status-msg">{this.state.message}</p>
                        <br />
                        <br />
                        <br />
                     </div>
                </div>
            </div>
        );
    }

    render() {
        const { classes } = this.props;

        let updateLoader = this.state.updateLoading ?
            <Loader />
            : <div></div>;

        let contents = this.state.loading ?
            <Loader />
            : this.renderAccount();

        return (
            <div sx={(theme)=>({
                [theme.breakpoints.down('md')]: {
                    marginLeft: '-225px !important',
                },
            })}>
                <div className="infoPage">
                    <div className="row no-gutters">
                        <div className="col">
                            <h3 className="pageTitle" id="tableLabel">User Account</h3>
                        </div>
                        <div className="col pageTitle">
                            <div className="btn-right">
                                <button type="button" className="secondary-btn btn-large" onClick={this.openCancelModal}>Cancel</button>
                                <button type="button" className="primary-btn btn-large" onClick={this.openSaveModal}>Save</button>
                            </div>
                        </div>
                    </div>
                    {contents}
                    {updateLoader}
                    <ConfirmationModal show={this.state.showCancelConfirm} onYes={this.onCancel} onClick={this.showCancelModal} message={this.state.modalMessage} title={this.state.modalTitle}></ConfirmationModal>
                    <ConfirmationModal show={this.state.showSaveConfirm} onYes={this.onSave} onClick={this.showSaveModal} message={this.state.modalMessage} title={this.state.modalTitle}></ConfirmationModal>
                    <ConfirmationModal 
                        show={this.state.showUnsavedModal == null ? this.getStorageValue("showUnsavedModal", false) === 'true' : this.state.showUnsavedModal} 
                        onYes={this.onSaveThenContinue} 
                        onClick={this.onContinueWithoutSaving} 
                        message={"You have unsaved changes, would like to save before leaving?"} 
                        title={"Unsaved Changes"}></ConfirmationModal>
                    <UpdateImageModal show={this.state.showLogoUpdate} onYes={this.onLogoUpload} onClick={this.showLogoModal} handleImageChange={this.handleLogoChange} message={this.state.modalMessage} title={this.state.modalTitle}></UpdateImageModal>
                </div>
            </div>
        );
    }
}

export default withRouter(UserAccount);