import React from "react";
import {Spinner} from "react-bootstrap";

const FullLoader = ({children}) => {

    return (
        <div className="loading"
             style={{display: "block", marginLeft: '0', width: '100%', backgroundColor: 'rgba(0,0,0,0.65)'}}>
            <div className="loading-wrapper"
                 style={{display: "flex", justifyContent: "center", alignItems: "center", width: '100%', top: '15%'}}>
                <div className="loading-body" style={{display: 'block'}}>
                    <Spinner animation="border" variant="light"/>
                    {children}
                </div>
            </div>
        </div>
    );
}

export default FullLoader