/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component, forwardRef } from 'react';
import { Spinner } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Pagination from '@mui/material/Pagination';
import LongArrowUpIcon from '@mui/icons-material/ArrowUpward';
import LongArrowDownIcon from '@mui/icons-material/ArrowDownward';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { PageView, ModalView, Event } from "../GoogleAnalytics";
import moment from 'moment';
import DeviceRequestsService from "../../services/devicerequests.service";
import Loader from "../Loader/Loader";

const animatedComponents = makeAnimated();

const customStyles = {
    option: (provided, state) => ({
        ...provided,
    }),
    control: () => ({
        width: '200px',
        color: 'rgba(0, 0, 0, 0.65)'
    })
}

const UrlPopover = (props) => {
    const { url } = props;

    return (
        <Popover id="popover-basic">
            <Popover.Content>
                {url}
            </Popover.Content>
        </Popover>
    );
};

class OTALogs extends Component {
    static displayName = OTALogs.name;
    _isMounted = false;

    constructor(props) {
        super(props);

        this.onDeviceChange = this.onDeviceChange.bind(this);
        this.onAddFilter = this.onAddFilter.bind(this);
        this.onStartDateChange = this.onStartDateChange.bind(this);
        this.onEndDateChange = this.onEndDateChange.bind(this);
        this.onActionSelect = this.onActionSelect.bind(this);

        this.onSearchClick = this.onSearchClick.bind(this);
        this.searchLogs = this.searchLogs.bind(this);

        this.onColumnClick = this.onColumnClick.bind(this);
        this.columnSort = this.columnSort.bind(this);
        this.onURLClick = this.onURLClick.bind(this);

        this.onChangePage = this.onChangePage.bind(this);
        this.getPageList = this.getPageList.bind(this);

        this.state = {
            message: "",
            modalMessage: "",
            modalTitle: "",
            loading: false,
            currentDeviceId: "",

            dateSearch: {
                enabled: false,
                startDate: moment().add(-1, 'month'),
                endDate: moment(),
                startDateStr: moment().utc().add(-1, 'month').format(),
                endDatestr: moment().utc().format()
            },
            /*actionSearch: {
                enabled: false,
                actionList: []
            },
            actionOptions: [],*/

            searchLoading: false,
            searchedId: false,
            logList: [],

            columnLogList: [],
            columnAltered: false,
            columnSort: {
                enabled: false,
                type: null,
                reverse: false
            },
            showFullURL: false,
            urlTarget: null,

            pageCount: 1,
            currentPage: 1
        }
    }

    componentDidMount() {
        this._isMounted = true;
        PageView();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setUpPagination() {
        let tableList = this.state.logList;
        let pageCount = 1;
        if (tableList.length > 100) {
            pageCount = Math.ceil(tableList.length / 100);
        }
        this.setState({ pageCount: pageCount });
    }

    onColumnClick(selectedCol) {
        const colSort = this.state.columnSort;
        let newSort = colSort;
        const logList = this.state.logList;
        let logs = logList;
        let colLogs = [];
        let colAltered = false;

        if (colSort.enabled) {
            if (colSort.type == selectedCol) {
                if (colSort.reverse) {
                    newSort = {
                        enabled: false,
                        type: null,
                        reverse: false
                    };
                    colLogs = this.columnSort(logs, "ID", false); /* default sort by desc id */
                    colAltered = false;
                }
                else {
                    newSort.reverse = true;
                    colLogs = this.columnSort(logs, selectedCol, true);
                    colAltered = true;
                }
            }
            else {
                newSort = {
                    enabled: true,
                    type: selectedCol,
                    reverse: false
                };
                colLogs = this.columnSort(logs, selectedCol, false);
                colAltered = true;
            }
        }
        else {
            newSort = {
                enabled: true,
                type: selectedCol,
                reverse: false
            };
            colLogs = this.columnSort(logs, selectedCol, false);
            colAltered = true;
        }

        if (this._isMounted)
            this.setState({ columnSort: newSort, columnLogList: colLogs }, () => {
                this.setState({ columnAltered: colAltered });
            });
    }

    columnSort(list, col, isReverse) {
        let colList = [];
        let logs = list; //this.getPage() ?

        if (col === "ID") {
            colList = logs.sort((a, b) => {
                const valA = a.id;
                const valB = b.id;

                return valB - valA;
            });
        }
        else if (col === "ACTION") {
            colList = logs.sort((a, b) => {
                const valA = a.action.toLocaleUpperCase();
                const valB = b.action.toLocaleUpperCase();

                return valA.localeCompare(valB, undefined, { numeric: true });
            });
        }
        else if (col === "DATE") {
            colList = logs.sort((a, b) => {
                const dateA = new Date(a.date);
                const dateB = new Date(b.date);
                return dateB - dateA;
            });
        }

        if (isReverse)
            colList = colList.reverse();

        return colList;
    }

    onChangePage(e, page) {
        if (this._isMounted)
            this.setState({ currentPage: page }, () => {
                this.getPageList();
            });
    }

    getPageList() {
        let page = null;
        let endIndex = (this.state.currentPage * 100) - 1;
        let startIndex = (this.state.currentPage - 1) * 100;

        if (this.state.logList !== null && this.state.logList !== undefined) {
            page = this.state.logList.slice(startIndex, endIndex);
        }

        return page;
    }

    validDeviceFormat(input) {
        var validFormat = true;

        if (input.length !== 12)
            validFormat = false;

        return validFormat;
    }

    onDeviceChange(e) {
        const val = e.target.value;
        this.setState({ currentDeviceId: val });
    }

    onStartDateChange(e) {
        Event("Start Date Changed", "User changed Start Date in OTA Logs", "Start Date Change");
        const val = e.target.value;
        this.setState(prevState => ({
            dateSearch: {
                ...prevState.dateSearch,
                enabled: true,
                startDate: val
            }
        }));
    }

    onEndDateChange(e) {
        Event("End Date Changed", "User changed End Date in OTA Logs", "End Date Change");
        const val = e.target.value;
        this.setState(prevState => ({
            dateSearch: {
                ...prevState.dateSearch,
                enabled: true,
                endDate: val
            }
        }));
    }

    onActionSelect(e) {

    }

    onAddFilter(filter) {
        if (filter === "DATE") {
            this.setState({
                dateSearch: {
                    enabled: true,
                    startDate: moment().add(-1, 'month'),
                    endDate: moment()
                }
            });
        }
        else if (filter === "ACTION") {
            this.setState({
                actionSearch: {
                    enabled: true,
                    actionList: []
                }
            });
        }
    }

    onSearchClick() {
        Event("Search Clicked", "User clicked search button in OTA Logs", "Search Clicked");
        const input = this.state.currentDeviceId.trim();

        if (input.length && this.validDeviceFormat(this.state.currentDeviceId)) {
            this.searchLogs();
        }
        else {
            this.setState({ deviceMsg: "Device ID is not in correct format" });
        }

        setTimeout(() => {
            this.setState({
                deviceMsg: ""
            });
        }, 4000);
    }

    searchLogs() {
        this.setState({ searchedId: true, searchLoading: true }, async () => {
            if (!this.state.dateSearch.enabled) {
                await DeviceRequestsService.getdevicerequests(this.state.currentDeviceId)
                    .then(response => {
                        if (this._isMounted)
                            this.setState({
                                logList: response.data.reverse(),
                                searchLoading: false
                            }/*, () => {
                                this.getActionOptions(this.state.logList);
                            }*/);
                    })
                    .catch(e => {
                        console.log(e);
                    });
            }
            else {
                await DeviceRequestsService.getdevicerequestsbetween(this.state.currentDeviceId, this.state.dateSearch.startDateStr.toString(), this.state.dateSearch.endDatestr.toString())
                    .then(response => {
                        if (this._isMounted)
                            this.setState({
                                logList: response.data.reverse(),
                                searchLoading: false
                            }/*, () => {
                                this.getActionOptions(this.state.logList);
                            }*/);
                    })
                    .catch(e => {
                        console.log(e);
                    });
            } 
        });
    }

    onURLClick = (e) => {
        this.setState({ showFullURL: !this.state.showFullURL, urlTarget: e.target })
    }

    getDateString(date) {
        let dateStr = moment(date).format('YYYY-MM-DD hh:mm:ss a');

        return dateStr;
    }

    /*getActionOptions(logList) {
        var options = [];
        if (logList.length) {
            options = logList.map(req => req.action).filter((action, index, self) => self.indexOf(action) === index);
        }
        return options;
    }*/

    getOptionLabel = (option) => {
        return (option);
    }

    getOptionValue = (option) => {
        return (option);
    }

    renderTable() {
        let tableList = this.state.columnAltered ? this.state.columnLogList : this.state.logList;

        let arrowIcon = this.state.columnSort.enabled ?
            (this.state.columnSort.reverse ?
                <LongArrowDownIcon style={{ height: '16px', width: '15px', paddingBottom: '2px' }} />
                : <LongArrowUpIcon style={{ height: '16px', width: '15px', paddingBottom: '2px' }} />)
            : null;

        let blankIcon = <LongArrowDownIcon style={{ height: '16px', width: '15px', paddingBottom: '2px', visibility: 'hidden' }} />;

        let actionIcon = this.state.columnSort.enabled && this.state.columnSort.type === "ACTION" ?
            <span style={{ height: '15px', paddingLeft: '3px' }}>
                {arrowIcon}
            </span>
            : <span style={{ height: '15px', paddingLeft: '3px' }}>
                {blankIcon}
            </span>;

        let dateIcon = this.state.columnSort.enabled && this.state.columnSort.type === "DATE" ?
            <span style={{ height: '15px', paddingLeft: '3px' }}>
                {arrowIcon}
            </span>
            : <span style={{ height: '15px', paddingLeft: '3px' }}>
                {blankIcon}
            </span>;

        let tableContent = this.state.logList !== undefined && this.state.logList !== null && this.state.logList.length ?
            <tbody>
                {tableList.map((request, index) =>
                    <tr className="alerts-table-row" key={`${request.deviceId}-tableRow-${index}`} data={index} value={index}>
                        <td style={{ borderLeft: '1px solid #ebeef0' }}>{request.action}</td>
                        <td>{this.getDateString(request.date)}</td>
                        <td>{request.hardwareRevision}</td>
                        <td>{request.firmwareRevision}</td>
                        <td>{request.otaRevision}</td>
                        <td>
                            <OverlayTrigger key="top" placement="top" trigger="click"
                                overlay={<Popover id="popover-basic">
                                    <Popover.Content>
                                        {request.otaUrl}
                                    </Popover.Content>
                                </Popover>}>
                                <div id='tooltipUrl' style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{request.otaUrl}</div>
                            </OverlayTrigger>
                        </td>
                        <td>{request.certificateId}</td>
                    </tr>
                )}
            </tbody>
            : <tbody>
                <tr className="alerts-table-row" key={`NoLogs-tableRow`}>
                    <td colSpan='7'>No OTA logs to show</td>
                </tr>
            </tbody>;

        let pagination =
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Pagination count={this.state.pageCount} page={this.state.currentPage} onChange={this.onChangePage} showFirstButton={this.state.pageCount > 7 ? true : false} showLastButton={this.state.pageCount > 7 ? true : false} size="small" />
            </div>;

        return (
            <div style={{ margin: '20px 30px' }}>
                <div style={{ justifyContent: 'center' }}>
                    <table style={{ width: '100%', textAlign: 'center', tableLayout: 'fixed', marginBottom: '30px' }}>
                        <thead>
                            <tr className="alerts-table-row">
                                <th className="table-shrink" style={{ textAlign: 'center', padding: '10px 0px' }}>
                                    <div onClick={() => this.onColumnClick("ACTION")}>
                                        <span style={{ cursor: 'pointer' }}>ACTION</span>
                                        {actionIcon}
                                    </div>
                                </th>
                                <th className="table-shrink" style={{ textAlign: 'center', padding: '10px 0px' }}>
                                    <div onClick={() => this.onColumnClick("DATE")}>
                                        <span style={{ cursor: 'pointer' }}>DATE</span>
                                        {dateIcon}
                                    </div>
                                </th>
                                <th className="table-shrink" style={{ textAlign: 'center', padding: '10px 0px' }}>
                                    HARDWARE REVISION
                                </th>
                                <th className="table-shrink" style={{ textAlign: 'center', padding: '10px 0px' }}>
                                    FIRMWARE REVISION
                                </th>
                                <th className="table-shrink" style={{ textAlign: 'center', padding: '10px 0px' }}>
                                    OTA REVISION
                                </th>
                                <th className="table-shrink" style={{ textAlign: 'center', padding: '10px 0px' }}>
                                    OTA URL
                                </th>
                                <th className="table-shrink" style={{ textAlign: 'center', padding: '10px 0px' }}>
                                    CERTIFICATE ID
                                </th>
                            </tr>
                        </thead>

                        {tableContent}

                    </table>
                    {pagination}
                </div>
            </div>
        );
    }

    renderContent() {
        let tableOptions =
            <div style={{ margin: '15px 30px', padding: '15px 25px 20px', border: '2px solid #ebeef0', borderRadius: '.3rem' }}>
                <Form noValidate validated={this.state.validated}>
                    <Row>
                        <Form.Group as={Col} md="4" controlId="deviceId">
                            <Form.Label>Device ID</Form.Label>
                            <Form.Control required type="text" placeholder="Ex: 123456789abc" value={this.state.currentDeviceId} onChange={this.onDeviceChange} isInvalid={false} />
                            <Form.Control.Feedback type="invalid">Device ID is required</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="2" controlId="startDate">
                            <Form.Label>Start Date</Form.Label>
                            <Form.Control type="date" value={this.state.dateSearch.startDate} onChange={this.onStartDateChange} />
                        </Form.Group>
                        <Form.Group as={Col} md="2" controlId="endDate">
                            <Form.Label>End Date</Form.Label>
                            <Form.Control type="date" value={this.state.dateSearch.endDate} onChange={this.onEndDateChange} />
                        </Form.Group>
                        {/*<Form.Group as={Col} md="2" controlId="actions">
                            <Form.Label>Actions</Form.Label>
                            <span style={{ minWidth: '200px' }}><Select
                                styles={this.customStyles}
                                closeMenuOnSelect={true}
                                components={animatedComponents}
                                isMulti
                                options={this.state.actionOptions}
                                getOptionLabel={this.getOptionLabel}
                                getOptionValue={this.getOptionValue}
                                onChange={this.onActionSelect}
                                value={this.state.actionSearch.actionList}
                                placeholder="All Actions"
                            /></span>   
                        </Form.Group>*/}
                    </Row>
                    <Button style={{ backgroundColor: 'white', color: '#0033a0', borderColor: '#0033a0' }} onClick={this.onSearchClick}>Search Logs</Button>
                </Form>
            </div>;

        let idSearchContent = this.state.searchedId ?
            this.state.searchLoading ?
                <div className='centered'><Spinner animation="border" variant="dark" /></div>
                : this.renderTable()
            : null;

        return (
            <div>
                {tableOptions}

                {idSearchContent}

            </div>
        )
    }

    render() {
        const { classes } = this.props;

        let loader = this.state.loading ?
            <Loader />
            : null;

        return (
            <div sx={(theme)=>({
                [theme.breakpoints.down('md')]: {
                    marginLeft: '-225px !important',
                },
            })}>
                <div className='infoPage'>
                    <h2 className="pageTitle" id="tabelLabel" style={{ borderBottom: 'none', padding: '20px 0px 0px 30px' }}>
                        OTA Logs
                    </h2>

                    {this.renderContent()}
                    {loader}
                </div>
            </div>
        );
    }
}

export default OTALogs;