import React, {useState} from 'react';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import ListIcon from '@mui/icons-material/Reorder';
import OnboardingDashboardCard from './OnboardingDashboardCard';


function TrainingCountsRow(props) {
    const { onboardInfo, onTrainingReportClick } = props;
    const title = 'Asset Training Report (24 hours)';

    let trainingReportContent = 
    <div className="row no-gutters card-row" style={{ textAlign: 'center' }}>
        <div className="col" style={{ minHeight: '40px', borderRight: '1px solid lightgrey' }}>
            <div className="row no-gutters dashboard-val" style={{ justifyContent: 'center' }}>
                <div className='col' style={{ textAlign: 'right' }}>
                    <CloseIcon className="dashboard-icon-sm db-red db-red-bg" />
                </div>
                <div className="col" style={{ textAlign: 'left' }}>
                    <div className="dashboard-number db-num3">
                        {onboardInfo.failedCount}
                    </div>
                </div>

            </div>
            <div>
                <small style={{ color: "dimGray", fontSize: '80%', textAlign: 'center' }}>Failed Training</small>
            </div>
        </div>

        <div className="col" style={{ minHeight: '40px', borderRight: '1px solid lightgrey' }}>
            <div className="row no-gutters dashboard-val" style={{ justifyContent: 'center' }}>
                <div className='col' style={{ textAlign: 'right' }}>
                    <PriorityHighIcon className="dashboard-icon-sm db-orange db-orange-bg" />
                </div>
                <div className="col" style={{ textAlign: 'left' }}>
                    <div className="dashboard-number db-num3">
                        {onboardInfo.notEnoughDataCount}
                    </div>
                </div>

            </div>
            <div>
                <small style={{ color: "dimGray", fontSize: '80%', textAlign: 'center' }}>Not Enough Data</small>
            </div>
        </div>

        <div className="col" style={{ minHeight: '40px', borderRight: '1px solid lightgrey' }}>
            <div className="row no-gutters dashboard-val" style={{ justifyContent: 'center' }}>
                <div className="col" style={{ textAlign: 'right' }}>
                    <CheckIcon className="dashboard-icon-sm db-teal" />
                </div>
                <div className="col" style={{ textAlign: 'left' }}>
                    <div className="dashboard-number db-num3">
                        {onboardInfo.trainedCount}
                    </div>
                </div>

            </div>
            <div>
                <small style={{ color: "dimGray", fontSize: '80%', textAlign: 'center' }}>Trained</small>
            </div>
        </div>

        <div className="col" style={{ minHeight: '40px' }}>
            <div className="row no-gutters dashboard-val" style={{ justifyContent: 'center' }}>
                <div className="col" style={{ textAlign: 'right' }}>
                    <ListIcon className="dashboard-icon-sm db-gray" />
                </div>
                <div className="col" style={{ textAlign: 'left' }}>
                    <div className="dashboard-number db-num3">
                        {onboardInfo.remainingCount}
                    </div>
                </div>

            </div>
            <div>
                <small style={{ color: "dimGray", fontSize: '80%', textAlign: 'center' }}>Remaining</small>
            </div>
        </div>
    </div>;

    return (
        <div className="col-6" style={{ display: 'grid' }}>
            <div>
                <OnboardingDashboardCard title={title} content={trainingReportContent} onClick={onTrainingReportClick} isGraphCard={false} />
            </div>
        </div>
    );
}

export default TrainingCountsRow;