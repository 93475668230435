/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React from 'react';
import Select from "react-select";
import makeAnimated from "react-select/animated";
const animatedComponents = makeAnimated();

class SelectCustomer extends React.Component {
    /* obj.organizationId here is for customerId not the same as in Partners where it is the partners organization Id.,  */

    onCustomerSelect = (selectedOption) => {
        this.props.onCustomerSelect(selectedOption.organizationId);
    }

    getOptionLabel = (option) => {
        return (option.firstName === "" ? option.lastName : option.lastName + ", " + option.firstName);
    }

    getOptionValue = (option) => {
        return (option["organizationId"]);
    }
    
    render() {
        if (this.props.customers != null) {
            return (
                <div className={"sidebar-select sidebar-select-2"}>
                    <span className="sidebar-select-message select-message" >Customers</span>
                    <span>
                        <Select
                            styles={this.customStyles}
                            closeMenuOnSelect={true}
                            components={animatedComponents}
                            options={this.props.customers}
                            getOptionLabel={this.getOptionLabel}
                            getOptionValue={this.getOptionValue}
                            onChange={this.onCustomerSelect}
                            defaultValue={this.props.customers[0]}
                            value={this.props.customers.find(c => c.organizationId === this.props.selectedCustomer)}
                        />
                    </span>
                </div>
            );
        } 
        else {
            return null;
        }
    }
}

export default SelectCustomer;