import {Component, createContext} from "react";

const DataContext = createContext({
    selectedData:[],
    isSideBarReady:false,
    setSelectedData:()=>{},
});

export class DataProvider extends Component{
    state = {
        selectedData:[],
        isSideBarReady:false,
    };
    
    setSelectedData = (data)=>{
        this.setState({selectedData:data, isSideBarReady: true});
    };
    
    resetSideBarReady = () => {
        this.setState({isSideBarReady: false});
    };
    
    render(){
        const {children}= this.props;
        return (
            <DataContext.Provider value={{
                selectedData:this.state.selectedData,
                setSelectedData:this.setSelectedData,
                isSideBarReady:this.state.isSideBarReady,
                resetSideBarReady:this.resetSideBarReady,
            }}>
                {children}
            </DataContext.Provider>
        )
    }
}
export default DataContext;