/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import { Spinner } from 'react-bootstrap';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import DatePicker from 'react-datepicker';
import { ModalView, Event } from "../GoogleAnalytics";
import CreateJobModal from '../LWTriageAlerts/CreateJobModal';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import SummarizedAlertsModal from './SummarizedAlertsModal';
import { MultiSeriesGraph } from '../LWTriageAlerts/MultiSeriesGraph';
import FullScreenChartModal from '../LWTriageAlerts/FullScreenChartModal';
import AccountService from '../../services/account.service';
import AlertService from '../../services/alerts.service';
import AssetService from '../../services/asset.service';
import ServiceTitanService from '../../services/servicetitan.service';
import CommentsService from '../../services/comments.service';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Input from '@mui/material/Input';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import HelpIcon from '@mui/icons-material/Help';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Snackbar from '@mui/material/Snackbar';
import NotificationIcon from '@mui/icons-material/NotificationsActiveOutlined';
import ScheduledIcon from '@mui/icons-material/DateRange';
import UnscheduledIcon from '@mui/icons-material/EventBusy';
import ServicedIcon from '@mui/icons-material/EventAvailable';
import GroupsIcon from '@mui/icons-material/People';
import InstallIcon from '@mui/icons-material/SystemUpdate';
import DeviceDownIcon from '@mui/icons-material/MobileOff';
import SignalIcon from '@mui/icons-material/SpeakerPhone';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import FilterIcon from '@mui/icons-material/FilterList';
import InfoIcon from '@mui/icons-material/InfoOutlined';

import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import CircleIcon from '@mui/icons-material/FiberManualRecord';

import LongArrowUpIcon from '@mui/icons-material/ArrowUpward';
import LongArrowDownIcon from '@mui/icons-material/ArrowDownward';

import { jsAsset, alertCustomer, jsAlert, jsGraph, jsOpportunity, jsUpdateOpportunity, jsGraphData, jsSource, jsCustomer, AlertTypeIdsEnum, TagIdsEnum, alertSearchStartDate, AlertIndicatorSort } from "../../componentObjects";

import memoize from 'memoize-one';
import moment from 'moment';

import CssBaseline from '@mui/material/CssBaseline';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import AlertGraphsModal from './AlertGraphsModal';
import CommentsModal from './CommentsModal';
import SilenceAlertModal from './SilenceAlertModal';
import AskUserModal from './AskUserModal';
import ViewGraphButton from './ViewGraphButton';
import settingService from "../../services/setting.service";
import ServiceIntegrationService from "../../services/serviceIntegration.service";
import Loader from "../Loader/Loader";

const animatedComponents = makeAnimated();

const customStyles = {
    option: (provided, state) => ({
        ...provided,
    }),
    control: () => ({
        width: '200px',
        color: 'rgba(0, 0, 0, 0.65)'
    })
}

const activeOptions = [
    {
        value: "Both",
        label: "Both"
    },
    {
        value: "Active",
        label: "Active"
    },
    {
        value: "Inactive",
        label: "Inactive"
    }
];

const scheduleOptions = [
    {
        label: "Awaiting Job Creation",
        value: "Unscheduled"
    },
    {
        value: "Scheduled",
        label: "Job Created"
    }
];

const silencedOptions = [
    {
        value: "Hide Silenced",
        label: "Hide Silenced"
    },
    {
        value: "Show Silenced",
        label: "Show Silenced"
    }
];
const deviceAlertsList = 'deviceAlertsList';
const equipmentAlertsList = 'equipmentAlertsList';

const leadFailureMsg = "Failed to Create Lead in Service Titan. Would you like to create a job in LifeWhere Portal without a Lead in Portal";
const leadfailurtoCreateJobTital = "Create a Job(No Lead)"

class TriageAlertsTable extends Component {

    constructor(props) {
        super(props);

        this.onTableTabClick = this.onTableTabClick.bind(this);
        this.changeTableRadio = this.changeTableRadio.bind(this);

        this.consolidateAlerts = this.consolidateAlerts.bind(this);
        this.reloadTable = this.reloadTable.bind(this);
        this.updateAlertsList = this.updateAlertsList.bind(this);
        this.onApplyFiltersClick = this.onApplyFiltersClick.bind(this);
        this.onApplyFilters = this.onApplyFilters.bind(this);
        this.onColumnClick = this.onColumnClick.bind(this);
        this.changeActiveFilter = this.changeActiveFilter.bind(this);
        this.changeScheduledFilter = this.changeScheduledFilter.bind(this);
        this.changeSilencedFilter = this.changeSilencedFilter.bind(this);
        this.changeAlertTypesFilter = this.changeAlertTypesFilter.bind(this);
        this.onChangeStart = this.onChangeStart.bind(this);
        this.onChangeEnd = this.onChangeEnd.bind(this);

        this.getExpandContent = this.getExpandContent.bind(this);
        this.onAlertClick = this.onAlertClick.bind(this);

        this.createJobandLead = this.createJobandLead.bind(this);
        this.createJob = this.createJob.bind(this);
        this.onCreateJob = this.onCreateJob.bind(this);
        this.openCreateJobModal = this.openCreateJobModal.bind(this);
        this.showCreateJobModal = this.showCreateJobModal.bind(this);
        this.navigateToJob = this.navigateToJob.bind(this);

        this.openCommentsModal = this.openCommentsModal.bind(this);
        this.showCommentsModal = this.showCommentsModal.bind(this);
        this.addConversationToAlert = this.addConversationToAlert.bind(this);
        this.addConversationToAsset = this.addConversationToAsset.bind(this);
        this.resetConvo = this.resetConvo.bind(this);
        this.externalReload = this.externalReload.bind(this);
        this.addTagToAlert = this.addTagToAlert.bind(this);
        this.updateAlertComment = this.updateAlertComment.bind(this);

        this.openUnseenModal = this.openUnseenModal.bind(this);
        this.showUnseenModal = this.showUnseenModal.bind(this);
        this.onMarkAlertsUnseen = this.onMarkAlertsUnseen.bind(this);

        this.openSummarizedAlertsModal = this.openSummarizedAlertsModal.bind(this);
        this.showSummarizedAlertsModal = this.showSummarizedAlertsModal.bind(this);

        this.onPriorityOpportunityChange = this.onPriorityOpportunityChange.bind(this);
        this.onSubjectChange = this.onSubjectChange.bind(this);
        this.onFollowUpDateChange = this.onFollowUpDateChange.bind(this);

        this.onJobTypeChange = this.onJobTypeChange.bind(this);
        this.onCampaignChange = this.onCampaignChange.bind(this);
        this.onChangeTags = this.onChangeTags.bind(this);
        this.onCallReasonChange = this.onCallReasonChange.bind(this);

        this.closeAlerts = this.closeAlerts.bind(this);
        this.closeAlert = this.closeAlert.bind(this);
        this.onCloseAlert = this.onCloseAlert.bind(this);
        this.openCloseAlertModal = this.openCloseAlertModal.bind(this);
        this.showCloseAlertModal = this.showCloseAlertModal.bind(this);

        this.silenceAlerts = this.silenceAlerts.bind(this);
        this.onSilenceAlert = this.onSilenceAlert.bind(this);
        this.onSilenceAlertConfirm = this.onSilenceAlertConfirm.bind(this);
        this.openSilenceAlertModal = this.openSilenceAlertModal.bind(this);
        this.showSilenceAlertModal = this.showSilenceAlertModal.bind(this);

        this.unsilenceAlerts = this.unsilenceAlerts.bind(this);
        this.onUnsilenceAlert = this.onUnsilenceAlert.bind(this);
        this.openUnsilenceAlertModal = this.openUnsilenceAlertModal.bind(this);
        this.showUnsilenceAlertModal = this.showUnsilenceAlertModal.bind(this);

        this.onViewGraphs = this.onViewGraphs.bind(this);
        this.showGraphModal = this.showGraphModal.bind(this);


        this.nextPage = this.nextPage.bind(this);
        this.prevPage = this.prevPage.bind(this);
        this.updateSearch = this.updateSearch.bind(this);

        this.showAddAlertsToJobModal = this.showAddAlertsToJobModal.bind(this);
        this.onAddToJob = this.onAddToJob.bind(this);
        this.createJobHandler = this.createJobHandler.bind(this);
        this.onContinueExistingJobFlow = this.onContinueExistingJobFlow.bind(this);
        this.getAlertUnseen = this.getAlertUnseen.bind(this);
        this.getSummarizedAlerts = this.getSummarizedAlerts.bind(this);

        this.onShowAllClick = this.onShowAllClick.bind(this);
        this.showLeadfailurePopupModal = this.showLeadfailurePopupModal.bind(this);
        this.onCreateJobwithoutLead = this.onCreateJobwithoutLead.bind(this);
        this.mapLeadandJobIds = this.mapLeadandJobIds.bind(this);

        this.getTableCounts = this.getTableCounts.bind(this);

        this.state = {
            message: "",
            modalTitle: "",
            modalMessage: "",
            showMessage: false,
            loading: false,
            tableLoading: false,
            alertDetailLoading: false,
            graphLoaded: false,
            alertSelected: false,
            pageNum: 1,
            tableAltered: false,
            tableTabIndex: this.props.fromDashboard ? this.props.startTabIndex : (this.props.serviceTier == "1" ? 1 : 0),
            selected: -1,

            assetComments: null,
            alertComment: null,

            conversation: null,
            conversationId: null,
            commentAlert: null,
            startTime: moment(alertSearchStartDate),
            endTime: moment(),
            searchChanged: false,
            alertMsgObj: {},
            currentCustomer: alertCustomer,
            currentAlert: jsAlert,
            editAlert: jsAlert,
            updateAlert: jsAlert,
            currentOpportunity: jsUpdateOpportunity,
            currentAsset: jsAsset,
            alertCases: [],
            dpFollowUpDate: moment(new Date()).toDate(),
            oppFollowUpDate: moment(new Date()).format().split('T')[0],
            jobTypeId: null,
            jobTypes: [],
            campaignId: null,
            selectedTags: [],
            selectedTagIds: [],
            callReasonId: null,

            anchorEl: null,
            filterAlertTypes: [],
            selectedTypes: [],
            selectedTypeIds: [],

            alertTableType: "All",
            active: null,
            activeFilter: activeOptions[0],
            scheduled: false,
            scheduledFilter: scheduleOptions[0],
            showSilenced: false,
            silencedFilter: silencedOptions[0],
            filterByType: false,
            tableAlertList: [],
            tableEquipList: [],
            tableDevList: [],
            tableCounts: {
                equipCritical: 0,
                equipWarning: 0,
                devCritical: 0,
                devWarning: 0
            },
            filterChanged: false,

            distinctAlertList: [],
            consolidateAlertsDict: {},

            columnAlertList: [],
            columnAltered: false,

            showGraphModal: false,
            showCloseAlertConfirm: false,

            showAddAlertsToJobConfirm: false,

            showSummarizedAlerts: false,
            showSilenceAlertConfirm: false,
            showUnsilenceAlertConfirm: false,
            showCreateJobConfirm: false,

            showCommentsConfirm: false,
            showUnseenConfirm: false,
            acHvacSystem: [],

            currentRunName: "",

            columnSort: {
                enabled: false,
                type: null,
                reverse: false
            },
            alertIndicatorSort: AlertIndicatorSort.None,
            isLeadfailurePopupShow: false,
            onSTLoading:false,
            isCustomerSupport: localStorage.getItem('isCustomerSupport') === 'true' || false,
            showCreateJob: true,
            startTimeStr: moment(alertSearchStartDate).utc(),
            endTimeStr: moment().utc()
        }
    }

    onTableTabClick = (index) => {
        //this.onApplyFilters();
        let x = index;
        let tblList = this.state.tableAlertList;
        if (x == 0) {
            tblList = this.props.tableObj.equipmentAlertsList;
        }
        else if (x == 1) {
            tblList = this.props.tableObj.deviceAlertsList;
        }

        let tableCounts = {
            equipCritical: this.props.tableObj.equipmentAlertsList !== null ? this.props.tableObj.equipmentAlertsList.filter(a => a.severity == "Critical").length : 0,
            equipWarning: this.props.tableObj.equipmentAlertsList !== null ? this.props.tableObj.equipmentAlertsList.filter(a => a.severity == "Warning").length : 0,
            devCritical: this.props.tableObj.deviceAlertsList !== null ? this.props.tableObj.deviceAlertsList.filter(a => a.severity == "Critical").length : 0,
            devWarning: this.props.tableObj.deviceAlertsList !== null ? this.props.tableObj.deviceAlertsList.filter(a => a.severity == "Warning").length : 0
        };

        this.setState({
            tableTabIndex: x,
            tableAlertList: tblList,
            tableEquipList: this.props.tableObj.equipmentAlertsList,
            tableDevList: this.props.tableObj.deviceAlertsList,
            tableCounts: tableCounts,
            selected: -1,
            tableAltered: true,
            filterChanged: false,
            columnAltered: false,
            columnSort: {
                enabled: false,
                type: null,
                reverse: false
            },
            graphLoaded: false
        }, () => { /*this.consolidateAlerts(this.state.tableAlertList);*/ this.onApplyFilters(); });
    }

    changeTableRadio(e) {
        let x = e.target.value;

        this.setState({
            alertTableType: x, selected: -1, tableLoading: true, graphLoaded: false, filterAlertTypes: [], selectedTypes: [], selectedTypeIds: [], acSelected: false,
            active: null, activeFilter: activeOptions[0], scheduledFilter: scheduleOptions[0], scheduled: false, silencedFilter: silencedOptions[0], showSilenced: false, filterChanged: false, columnAltered: false,
            columnSort: {
                enabled: false,
                type: null,
                reverse: false
            }
        }, () => {
            /* apply current filters and consolidate */
            this.onApplyFilters();
        });
    }

    onShowAllClick() {
        this.props.showAllClick();
        this.reloadTable();
    }

    async reloadTable() {
        this.setState({
            tableLoading: true,
            selected: -1,
            tableAlertList: [],
            tableEquipList: [],
            tableDevList: [],
            tableCounts: {
                equipCritical: 0,
                equipWarning: 0,
                devCritical: 0,
                devWarning: 0
            },
            graphLoaded: false,
           // tableAltered: false,
            //currentCustomer: alertCustomer,
            //currentAsset: jsAsset,
            //currentAlert: jsAlert,
           // filterChanged: false,
            //columnAltered: false,
            //columnSort: {
            //    enabled: false,
            //    type: null,
            //    reverse: false
            //},
            //active: true,
            //activeFilter: activeOptions[0],
            //scheduled: false,
            //scheduledFilter: scheduleOptions[0],
            //showSilenced: false,
            //silencedFilter: silencedOptions[0],
            //filterAlertTypes: [],
            //selectedTypes: [],
            //selectedTypeIds: [],
            //alertTableType: "All",
            dpFollowUpDate: null,
            oppFollowUpDate: "",
            jobTypeId: null,
            campaignId: null,
            selectedTags: [],
            selectedTagIds: [],
            callReasonId: null,
            isUpdateHome: false,
            isUpdateorg: false,
            isLeadCreated: false,
            leadId: "",
            jobId :"",
            onSTLoading: false,
            tableLoading: false,
            message: "",
            showMessage: false,
            assetComments: null,
           // tableAltered: false
        });

        this.props.reloadTable();

        this.setState({ tableLoading: false });
    }



    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!nextProps.fromDashboard && (nextProps?.tableObj?.equipmentAlertsList?.length > 0 || nextProps?.tableObj?.deviceAlertsList?.length > 0) && (nextProps?.requiredToRefreshAlertList)) {
            this.onApplyFilters();
            this.props.setBackrefreshAlertList();
        }
    }

    consolidateAlerts(alerts) {
        let distinctAlerts = [];
        let collapseAlerts = [];
        let consolidateAlertsDict = {};
        let collapseUnitAlertsDict = {}; // {{ assetId: assetId used in consolidateDict }}
        let tableTabIndex = !this.state.tableAltered && this.state.tableTabIndex != this.props.startTabIndex ? this.props.startTabIndex : this.state.tableTabIndex;
        let isDevAlerts = /*this.state.*/tableTabIndex === 1;

        for (let i = 0; i < alerts.length; i++) {
            let currAlert = alerts[i];

            if (!distinctAlerts.some(a => a.typeId == currAlert.typeId && a.alert.assetId == currAlert.alert.assetId)) {
                var assetTypeAlerts = alerts.filter(a => a.typeId == currAlert.typeId && a.alert.assetId == currAlert.alert.assetId);
                var alertConversationId = Math.max(...assetTypeAlerts.map(o => o.alert.conversationId)).toString();
                var alertCommentCount = assetTypeAlerts.find(a => a.alert.conversationId == alertConversationId);

                assetTypeAlerts.sort((a, b) => {
                    const startA = new Date(a.alert.startTime);
                    const startB = new Date(b.alert.startTime);
                    return startB - startA;
                });

                var distinctAlert = assetTypeAlerts[0];
                let distinctUnitId = distinctAlert.alert.pathNames !== null && distinctAlert.alert.pathNames.length > 2 ? distinctAlert.alert.pathNames[distinctAlert.alert.pathNames.length - 1].split('-')[0] : null;

                if (isDevAlerts && distinctAlert.typeId == AlertTypeIdsEnum.UnitOffline && distinctUnitId !== null && distinctAlerts.some(d => d.typeId == distinctAlert.typeId && d.alert.pathNames.length > 2 && d.alert.pathNames[d.alert.pathNames.length - 1].split('-')[0] == distinctUnitId)) {
                    let prevDistinctAlert = distinctAlerts.find(d => d.typeId == distinctAlert.typeId && d.alert.pathNames.length > 2 && d.alert.pathNames[d.alert.pathNames.length - 1].split('-')[0] == distinctUnitId);
                    let prevUnitAlerts = consolidateAlertsDict[distinctAlert.typeId][prevDistinctAlert.alert.assetId];

                    let unitAlerts = prevUnitAlerts.concat(assetTypeAlerts);

                    collapseAlerts = collapseAlerts.concat(distinctAlert);
                    collapseUnitAlertsDict[distinctAlert.alert.assetId] = prevDistinctAlert.alert.assetId;
                    consolidateAlertsDict[prevDistinctAlert.typeId][prevDistinctAlert.alert.assetId] = unitAlerts;
                }
                else {
                    if (alertConversationId != 0) {
                        distinctAlert.alert.conversationId = alertConversationId;
                        distinctAlert.commentsCount = alertCommentCount.alert.conversationItemCount;
                        distinctAlert.alert.conversationItemCount = alertCommentCount.alert.conversationItemCount;
                    }
                    distinctAlerts = distinctAlerts.concat(distinctAlert);

                    if (consolidateAlertsDict[currAlert.typeId] === undefined) {
                        consolidateAlertsDict[currAlert.typeId] = {};
                        consolidateAlertsDict[currAlert.typeId][currAlert.alert.assetId] = assetTypeAlerts;
                    }
                    else {
                        consolidateAlertsDict[currAlert.typeId][currAlert.alert.assetId] = assetTypeAlerts;
                    }
                }
            }
        }

        distinctAlerts.sort((a, b) => {
            let aAlerts = consolidateAlertsDict[a.typeId][a.alert.assetId];
            let bAlerts = consolidateAlertsDict[b.typeId][b.alert.assetId];
            if (!alerts.some(c => c.alert.tagIds.includes(TagIdsEnum.NewAlert))) {
                if (!bAlerts.some(d => d.alert.tagIds.includes(TagIdsEnum.NewAlert)))
                    return 0;
                else
                    return 1;
            }
            else {
                if (!bAlerts.some(d => d.alert.tagIds.includes(TagIdsEnum.NewAlert)))
                    return -1;
                else
                    return 0;
            }
        });

        this.setState({ distinctAlertList: distinctAlerts, consolidateAlertsDict: consolidateAlertsDict, /*collapseUnitAlertsDict: collapseUnitAlertsDict,*/ tableLoading: false });
    }

    onApplyFiltersClick = () => {
        Event("Apply Triage Alerts Filters", "User clicked Apply Filters in Triage Alerts", "Apply Filters Clicked");
        this.onApplyFilters();
    }

    onApplyFilters = () => {
        let tableTabIndex = !this.state.tableAltered && this.state.tableTabIndex != this.props.startTabIndex ? this.props.startTabIndex : this.state.tableTabIndex;
        
        this.setState({ selected: -1, currentAlert: jsAlert, currentCustomer: jsCustomer, currentAsset: jsAsset, tableLoading: true, graphLoaded: false }, () => {
            var newAlertList = [];
            var newEquipList = [];
            var newDevList = [];
            let showJob = true; 
            var alerts = /*this.state.*/tableTabIndex === 0 ? this.props.tableObj.equipmentAlertsList : this.props.tableObj.deviceAlertsList;
            var equipAlerts = this.props.tableObj.equipmentAlertsList;
            var devAlerts = this.props.tableObj.deviceAlertsList;

            if (this.props.hasServiceTier && this.props.serviceTier < 3) {
                alerts = alerts.filter(a => a.typeId > 101 && a.typeId < 107); // todo filter for therm alerts better
                equipAlerts = equipAlerts.filter(a => a.typeId > 101 && a.typeId < 107);
                devAlerts = devAlerts.filter(a => a.typeId > 101 && a.typeId < 107);
            }

            if (this.state.alertTableType == "Critical") {
                alerts = alerts.filter(a => a.severity == "Critical");
                equipAlerts = equipAlerts.filter(a => a.severity == "Critical");
                devAlerts = devAlerts.filter(a => a.severity == "Critical");
            }
            else if (this.state.alertTableType == "Warning") {
                alerts = alerts.filter(a => a.severity == "Warning");
                equipAlerts = equipAlerts.filter(a => a.severity == "Warning");
                devAlerts = devAlerts.filter(a => a.severity == "Warning");
            }

//<<<<<<< HEAD 
            /*for (let i = 0; i < alerts.length; i++) {
                let alert = alerts[i];
                var addAlert = true;
                if (this.state.active != null) {
                    if (alert.alert.isActive != this.state.active)
                        addAlert = false;
                }


                    if (this.state.scheduled != null) {
                        if (alert.alert.tagIds.includes(TagIdsEnum.JobCreated) != this.state.scheduled)
                            addAlert = false;
                    }
                    if (this.state.showSilenced != alert.alert.isMuted) {
                        addAlert = false;
                    }
                    if (this.state.selectedTypes.length > 0) {
                        if (!this.state.selectedTypes.some(s => s.id == alert.typeId))
                            addAlert = false;
                    }

                if (addAlert)
                    newAlertList = newAlertList.concat(alert);
            }*/

            for (let i = 0; i < equipAlerts.length; i++) {
                let equipAlert = equipAlerts[i];
                var addAlert = true;
                if (this.state.active != null) {
                    if (equipAlert.alert.isActive != this.state.active)
                        addAlert = false;
                }

                if (this.state.scheduled != null && this.props.source !== "CustomerAlerts") {
                    if (equipAlert.alert.tagIds.includes(TagIdsEnum.JobCreated) != this.state.scheduled)
                        addAlert = false;
                }
                if (this.state.showSilenced != equipAlert.alert.isMuted) {
                    addAlert = false;
                }
                if (this.state.selectedTypes.length > 0) {
                    if (!this.state.selectedTypes.some(s => s.id == equipAlert.typeId))
                        addAlert = false;
                }

                if (addAlert)
                    newEquipList = newEquipList.concat(equipAlert);
            }

            for (let j = 0; j < devAlerts.length; j++) {
                let devAlert = devAlerts[j];
                var addAlert = true;
                if (this.state.active != null) {
                    if (devAlert.alert.isActive != this.state.active)
                        addAlert = false;
                }

                if (this.state.scheduled != null && this.props.source !== "CustomerAlerts") {
                    if (devAlert.alert.tagIds.includes(TagIdsEnum.JobCreated) != this.state.scheduled)
                        addAlert = false;
                }
                if (this.state.showSilenced != devAlert.alert.isMuted) {
                    addAlert = false;
                }
                if (this.state.selectedTypes.length > 0) {
                    if (!this.state.selectedTypes.some(s => s.id == devAlert.typeId))
                        addAlert = false;
                }

                if (addAlert)
                    newDevList = newDevList.concat(devAlert);
            }

            newAlertList = /*this.state.*/tableTabIndex === 0 ? newEquipList : newDevList;

            let tableCounts = {
                equipCritical: newEquipList !== null ? newEquipList.filter(a => a.severity == "Critical").length : 0,
                equipWarning: newEquipList !== null ? newEquipList.filter(a => a.severity == "Warning").length : 0,
                devCritical: newDevList !== null ? newDevList.filter(a => a.severity == "Critical").length : 0,
                devWarning: newDevList !== null ? newDevList.filter(a => a.severity == "Warning").length : 0
            };

            if (this.state.scheduled) { showJob = false }
//=======
//                    if (addAlert)
//                        newAlertList = newAlertList.concat(alert);
//                }
//                if (this.state.scheduled) { showJob = false }
//            }
            
//>>>>>>> Integration
            this.setState({
                tableAlertList: newAlertList, tableEquipList: newEquipList, tableDevList: newDevList, tableCounts: tableCounts,
                tableAltered: true, filterChanged: false, columnAltered: false, columnSort: { enabled: false, type: null, reverse: false },
                showCreateJob: showJob/*, tableLoading: false*/
            }, () => { this.consolidateAlerts(this.state.tableAlertList); });
        });
    }

    changeActiveFilter = (selectedOption) => {
        let x = selectedOption;
        let isActive = null;

        if (x.value == "Active")
            isActive = true;
        else if (x.value == "Inactive")
            isActive = false;

        if (this.state.active !== isActive)
            this.setState({
                active: isActive,
                activeFilter: x,
                filterChanged: true
            });
    }

    changeScheduledFilter = (selectedOption) => {
        let x = selectedOption;
        let jobCreated = false;
        if (x.value == "Scheduled")
            jobCreated = true;

        if (this.state.scheduled !== jobCreated)
            this.setState({
                scheduled: jobCreated,
                scheduledFilter: x,
                filterChanged: true
            });
    }

    changeSilencedFilter = (selectedOption) => {
        let x = selectedOption;
        let showSilenced = false;

        if (x.value == "Show Silenced")
            showSilenced = true;

        if (this.state.showSilenced !== showSilenced)
            this.setState({
                showSilenced: showSilenced,
                silencedFilter: x,
                filterChanged: true
            });
    }

    changeAlertTypesFilter = (selectedOptions) => {
        let types = selectedOptions.map(s => s.typeName);
        let typeIds = selectedOptions.map(s => s.id);

        this.setState({ selectedTypes: selectedOptions, selectedTypeIds: typeIds, filterAlertTypes: types }, () => {
            this.setState({ filterByType: this.state.filterAlertTypes.length > 0 ? true : false, filterChanged: true });
        });

    }

    onChangeStart(e) {
        var start = moment(e)
        var startStr = moment(e).utc().format();
        this.setState({ startTime: start, startTimeStr :startStr, searchChanged: true });
    }

    onChangeEnd(e) {
        var end = moment(e)
        var endStr = moment(e).utc().format();
        this.setState({ endTime: end, endTimeStr :endStr, searchChanged: true });
    }

    onColumnClick(selectedCol) {
        const colSort = this.state.columnSort;
        let newSort = colSort;
        let alertList = this.state.tableAltered ? this.state.distinctAlertList/*tableAlertList*/ : this.props.alertList;
        if (this.props.hasServiceTier && this.props.serviceTier < 3)
            alertList = alertList.filter(a => a.typeId > 101 && a.typeId < 107); //todo better filter. 
        let alerts = alertList;
        let colAlerts = [];
        let colAltered = false;

        if (colSort.enabled) {
            if (colSort.type == selectedCol) {
                if (colSort.reverse || this.state.alertIndicatorSort == AlertIndicatorSort.QuestionAsked) {
                    newSort = {
                        enabled: false,
                        type: null,
                        reverse: false
                    };
                    colAlerts = this.columnSort(alerts, "START", false); /* default sort by is start time */
                    colAltered = false;
                }
                else if (selectedCol === "ALERTINDICATOR") {
                    newSort.reverse = false;
                    colAlerts = this.columnSort(alerts, selectedCol, true);
                    colAltered = true;
                }
                else {
                    newSort.reverse = true;
                    colAlerts = this.columnSort(alerts, selectedCol, true);
                    colAltered = true;
                }
            }
            else {
                newSort = {
                    enabled: true,
                    type: selectedCol,
                    reverse: false
                };
                colAlerts = this.columnSort(alerts, selectedCol, false);
                colAltered = true;
            }
        }
        else {
            newSort = {
                enabled: true,
                type: selectedCol,
                reverse: false
            };
            colAlerts = this.columnSort(alerts, selectedCol, false);
            colAltered = true;
        }

        if (selectedCol == "ALERTINDICATOR") {
            var alertIndicatorType = this.state.alertIndicatorSort == AlertIndicatorSort.QuestionAsked ? AlertIndicatorSort.None : this.state.alertIndicatorSort + 1;
            this.setState({ columnSort: newSort, columnAlertList: colAlerts, alertIndicatorSort: alertIndicatorType }, () => {
                this.setState({ columnAltered: colAltered });
            });
        }
        else if (colSort.type == "ALERTINDICATOR") {
            this.setState({ columnSort: newSort, columnAlertList: colAlerts, alertIndicatorSort: AlertIndicatorSort.None }, () => {
                this.setState({ columnAltered: colAltered });
            });
        }
        else {
            this.setState({ columnSort: newSort, columnAlertList: colAlerts }, () => {
                this.setState({ columnAltered: colAltered });
            });
        }
    }

    columnSort(alertList, col, isReverse) {
        let colAlerts = [];
        let alerts = alertList;

        if (col === "END") {
            colAlerts = alerts.sort((a, b) => {
                if (a.alert.endTime == null || a.alert.endTime == "") {
                    if (b.alert.endTime == null || b.alert.endTime == "")
                        return 0;
                    else
                        return 1;
                }
                else {
                    const dateA = new Date(a.alert.startTime);

                    if (b.alert.endTime == null || b.alert.endTime == "")
                        return -1;
                    else {
                        const dateB = new Date(b.alert.startTime);
                        if (isReverse)
                            return dateA - dateB;
                        else
                            return dateB - dateA;
                    }
                }
            });
        }
        else if (col === "ALERTINDICATOR") {
            var alertIndicatorType = this.state.alertIndicatorSort == AlertIndicatorSort.QuestionAsked ? AlertIndicatorSort.None : this.state.alertIndicatorSort + 1;
            colAlerts = alerts.sort((a, b) => {
                var tag = "";
		        var tag2 = "";
                var aHasTag = false;
                var bHasTag = false;
                var aHasTag2 = false;
                var bHasTag2 = false;
                var assetComments = this.state.assetComments !== null ? this.state.assetComments : this.props.assetComments;
                var aComments = assetComments.filter(c => c.assetId == a.alert.assetId && c.alertTypeId == a.alert.typeId);
                var aComment = aComments !== null && aComments !== undefined && aComments.length ? aComments[0] : null; 
                var bComments = assetComments.filter(c => c.assetId == b.alert.assetId && c.alertTypeId == b.alert.typeId);
                var bComment = bComments !== null && bComments !== undefined && bComments.length ? bComments[0] : null; 

                if (alertIndicatorType == AlertIndicatorSort.NewAlerts) {
                    tag = TagIdsEnum.NewAlert;
                    aHasTag = a.alert.tagIds.includes(tag);
                    bHasTag = b.alert.tagIds.includes(tag);
                }
                else if (alertIndicatorType == AlertIndicatorSort.QuestionAnswered) {
                    tag = TagIdsEnum.QuestionAnswered;
		            tag2 = TagIdsEnum.NewComment;
                    aHasTag = aComment !== null ? aComment.hasAnswer : false;
                    aHasTag2 = aComment !== null ? aComment.hasNewComment : false;
                    bHasTag = bComment !== null ? bComment.hasAnswer : false;
                    bHasTag2 = bComment !== null ? bComment.hasNewComment : false;
                }
                else if (alertIndicatorType == AlertIndicatorSort.QuestionAsked) {
                    tag = TagIdsEnum.QuestionAsked;
                    aHasTag = aComment !== null ? aComment.hasQuestion : false;
                    bHasTag = bComment !== null ? bComment.hasQuestion : false;
                }

                if (tag2 !== "") {
                    if (!aHasTag && !aHasTag2) {
                        if (!bHasTag && !bHasTag2)
                            return 0;
                        else
                            return 1;
                    }
                    else {
                        if (!bHasTag && !bHasTag2)
                            return -1;
                        else
                            return 0;
                    }
                }
                else {
                    if (!aHasTag) {
                        if (!bHasTag)
                            return 0;
                        else
                            return 1;
                    }
                    else {
                        if (!bHasTag)
                            return -1;
                        else
                            return 0;
                    }
                }
            });
        }
        else {
            if (col === "CUSTOMER") {
                colAlerts = alerts.sort((a, b) => {
                    const nameA = this.getCustomerName(a.alert.organizationId, a.alert.organizationName).toLocaleUpperCase();
                    const nameB = this.getCustomerName(b.alert.organizationId, b.alert.organizationName).toLocaleUpperCase();

                    return nameA.localeCompare(nameB);
                });
            }
            else if (col === "START") {
                colAlerts = alerts.sort((a, b) => {
                    const dateA = new Date(a.alert.startTime);
                    const dateB = new Date(b.alert.startTime);
                    return dateB - dateA;
                });

            }
            else if (col === "ALERT") {
                colAlerts = alerts.sort((a, b) => {
                    const stringA = a.typeName;
                    const stringB = b.typeName;

                    return stringA.localeCompare(stringB);
                });
            }
            else if (col === "PROBLEM") {
                colAlerts = alerts.sort((a, b) => {
                    const stringA = a.alertMessage;
                    const stringB = b.alertMessage;

                    return stringA.localeCompare(stringB);
                });
            }
            else if (col === "SEVERITY") {
                colAlerts = alerts.sort((a, b) => {
                    if (a.severity === b.severity) {
                        return 0;
                    }
                    else if (a.severity === "Critical") {
                        return -1;
                    }
                    else if (a.severity === "Warning") {
                        return 1;
                    }
                });
            }
            else if (col === "COUNT") {
                let consolidateDict = this.state.tableAltered ? this.state.consolidateAlertsDict : this.props.consolidateAlertsDict;
                colAlerts = alerts.sort((a, b) => {
                    const valA = consolidateDict[a.typeId][a.alert.assetId].length;
                    const valB = consolidateDict[b.typeId][b.alert.assetId].length;

                    return valB - valA;
                });
            }

            if (isReverse) {
                colAlerts = colAlerts.reverse();
            }
        }

        return colAlerts;
    }

    onViewGraphs() {

    }

    showGraphModal() {
        this.setState({ showGraphModal: !this.state.showGraphModal }, () => {
            if (this.state.showGraphModal)
                ModalView("triageAlertsGraph");
        });
    }


    openCommentsModal(alert) {
        var assetComments = this.state.assetComments !== null ? this.state.assetComments : this.props.assetComments;
        var alertComments = assetComments.filter(c => c.assetId.toString() == alert.assetId && c.alertTypeId.toString() == alert.typeId);
        var alertComment = alertComments !== null && alertComments !== undefined && alertComments.length ? alertComments[0] : null;
        var commentAlert = this.state.currentAlert;
        this.setState({ commentsLoading: true, commentAlert: commentAlert }, async () => {
            ModalView('alertComments');
            if (alertComment == null) {
                await AlertService.getassetconvoid(alert.assetId)
                    .then(response => {
                        if (response.status === 200 && response.data !== undefined) {
                            var commentObj = {
                                id: 0,
                                assetId: parseInt(alert.assetId),
                                alertTypeId: parseInt(alert.typeId),
                                convoId: parseInt(response.data),
                                hasNewComment: false,
                                hasQuestion: false,
                                hasAnswer: false
                            }
                            this.setState({ conversationId: response.data, alertComment: commentObj, showCommentsConfirm: true }, async () => {
                                if (this.state.conversationId !== null) {
                                    AlertService.getconversationlist(this.state.conversationId)
                                        .then(response2 => {
                                            if (response2.status === 200 && response2.data !== undefined && response2.data !== null && response2.data !== false) {
                                                let typeString = alert.type + " - ";
                                                let alertConvo = response2.data.filter(c => c.message.includes(typeString));
                                                
                                                this.setState({ conversation: alertConvo, commentsLoading: false });
                                            }
                                        })
                                        .catch(e => {
                                            console.log(e);
                                        });
                                }
                            })
                        }
                    })
                    .catch(e => {
                        console.log(e);
                        this.setState({ commentsLoading: false })
                    })
            }
            else {
                if (alertComment.convoId !== null) {
                    AlertService.getconversationlist(alertComment.convoId)
                        .then(response3 => {
                            if (response3.status === 200 && response3.data !== undefined && response3.data !== null && response3.data !== false) {
                                let typeString = alert.type + " - ";
                                let alertConvo = response3.data.filter(c => c.message.includes(typeString));
                                
                                this.setState({ conversation: alertConvo, conversationId: alertComment.convoId, showCommentsConfirm: true }, async () => {
                                    if (alertComment.hasAnswer || alertComment.hasNewComment) {
                                        alertComment.hasNewComment = false;
                                        alertComment.hasAnswer = false;
                                        
                                        await CommentsService.updatecomment(alertComment)
                                            .then(response4 => {
                                                if (response4.status === 200 && response4.data !== undefined && response4.data !== null && response4.data !== false) {
                                                    alertComment = response4.data;

                                                    let commentIndex = assetComments.indexOf(c => c.id == alertComment.id);
                                                    assetComments[commentIndex] = alertComment;

                                                    let tableAlerts = this.state.columnAltered ? this.state.columnAlertList : (this.state.tableAltered ? this.state.distinctAlertList : this.props.alertList);
                                                    let dict = this.state.tableAltered && this.state.consolidateAlertsDict != {} ? this.state.consolidateAlertsDict : this.props.consolidateAlertsDict;
                                                    this.setState({ alertComment: alertComment, assetComments: assetComments, tableAltered: true, commentsLoading: false, distinctAlertList: tableAlerts, consolidateAlertsDict: dict });
                                                }
                                            })
                                            .catch(e => {
                                                console.log(e);
                                            });
                                    }
                                    else {
                                        this.setState({ alertComment: alertComment, commentsLoading: false });
                                    }
                                });
                            }
                        })
                        .catch(e => {
                            console.log(e);
                        })
                }
            }
        })
    }

    showCommentsModal() {
        this.setState({
            showCommentsConfirm: !this.state.showCommentsConfirm
        }, () => {
            if (this.state.showCommentsConfirm)
                ModalView('alertComments');
        });
    }

    updateAlertComment(updatedComment, callAPI = true) {
        this.setState({ commentsLoading: true }, async () => {
            let tableAlerts = this.state.columnAltered ? this.state.columnAlertList : (this.state.tableAltered ? this.state.distinctAlertList : this.props.alertList);
            let dict = this.state.tableAltered && this.state.consolidateAlertsDict != {} ? this.state.consolidateAlertsDict : this.props.consolidateAlertsDict;
            var assetComments = this.state.assetComments !== null ? this.state.assetComments : this.props.assetComments;
            var index = assetComments.findIndex(c => c.assetId === updatedComment.assetId && c.alertTypeId === updatedComment.alertTypeId);
            
            if (updatedComment.convoId == null) {
                updatedComment.convoId = this.state.conversationId !== null ? this.state.conversationId : null;
            }
            
            if (callAPI) {
                await CommentsService.updatecomment(updatedComment)
                .then(response => {
                    if (response.status === 200 && response.data !== undefined && response.data !== null && response.data !== false) {
                        updatedComment = response.data;

                        if (index === -1 || index < 0)
                            assetComments = assetComments.concat(updatedComment);
                        else
                            assetComments[index] = updatedComment;

                        this.setState({ 
                            alertComment: updatedComment, 
                            assetComments: assetComments, 
                            tableAltered: true, commentsLoading: false, 
                            consolidateAlertsDict: dict, 
                            distinctAlertList: tableAlerts 
                        });
                    }
                })
                .catch(e => {
                    console.log(e);
                    this.setState({ commentsLoading: false });
                });
            }
            else {
                if (index === -1 || index < 0)
                    assetComments = assetComments.concat(updatedComment);
                else
                    assetComments[index] = updatedComment;
                
                this.setState({ 
                    alertComment: updatedComment, 
                    assetComments: assetComments, 
                    tableAltered: true, commentsLoading: false, 
                    consolidateAlertsDict: dict, 
                    distinctAlertList: tableAlerts 
                });
            }
        });
    }

    updateAlertsList(updatedComment) {
        var currentAlert = this.state.currentAlert;
        var assetComments = this.state.assetComments !== null ? this.state.assetComments : this.props.assetComments;
        let tableAlerts = this.state.columnAltered ? this.state.columnAlertList : (this.state.tableAltered ? this.state.distinctAlertList : this.props.alertList);
        let dict = this.state.tableAltered ? this.state.consolidateAlertsDict : this.props.consolidateAlertsDict;
        var index = assetComments.indexOf(c => c.assetId == updatedComment.assetId && c.alertTypeId == updatedComment.alertTypeId);

        if (updatedComment.hasAnswer) {
            assetComments = assetComments.filter(c => c.assetId !== updatedComment.assetId && c.alertTypeId !== updatedComment.alertTypeId);
            tableAlerts = tableAlerts.filter(a => a.alertId !== currentAlert.alertId);
            //dict[currentAlert.typeId][currentAlert.assetId] = consolidatedAlerts;
            delete dict[currentAlert.typeId][currentAlert.assetId];

            this.setState({ 
                currentAlert: jsAlert, 
                selected: -1, 
                tableAltered: true, 
                distinctAlertList: tableAlerts, 
                consolidateAlertsDict: dict });
        }
    }

    addConversationToAsset(conversationId) {

    }

    addConversationToAlert(conversationId) {
        if (this.state.currentAlert.conversationId == null) {
            this.setState(prevState => ({
                currentAlert: {
                    ...prevState.currentAlert,
                    conversationId: conversationId,
                    conversationItemCount: (this.state.currentAlert.conversationItemCount) + 1
                }
            }));
            this.props.addConversationToAlert(conversationId, this.state.currentAlert.alertId);
        }
        else {
            this.setState(prevState => ({
                currentAlert: {
                    ...prevState.currentAlert,
                    conversationItemCount: (prevState.currentAlert.conversationItemCount) + 1
                }
            }));
        }
    }

    addTagToAlert(tagIdList) {
        if (tagIdList != null && tagIdList.length > 0) {
            let dict = this.state.tableAltered ? this.state.consolidateAlertsDict : this.props.consolidateAlertsDict;
            let currAlert = this.state.currentAlert;
            let commentAlert = this.state.commentAlert;
            let consolidatedAlerts = dict[currAlert.typeId][currAlert.assetId];
            let alertIndex = consolidatedAlerts.indexOf(a => a.alertId == currAlert.alertId);

            for (let i = 0; i < tagIdList.length; i++) {
                let tagId = tagIdList[i];

                if (currAlert != null) {
                    if (!currAlert.tagIds.includes(tagId)) {
                        currAlert.tagIds = currAlert.tagIds.concat(tagId);
                    }
                }
                if (commentAlert != null) {
                    if (!commentAlert.tagIds.includes(tagId)) {
                        commentAlert.tagIds = commentAlert.tagIds.concat(tagId);
                    }
                }
            }
            consolidatedAlerts[alertIndex] = currAlert;
            dict[currAlert.typeId][currAlert.assetId] = consolidatedAlerts;

            this.setState({ currentAlert: currAlert, commentAlert: commentAlert, consolidateAlertsDict: dict, tableAltered: true });
        }
    }

    resetConvo(commentsAddedCount) {
        let tableAlerts = this.state.columnAltered ? this.state.columnAlertList : (this.state.tableAltered ? this.state.distinctAlertList : this.props.alertList);

        let updatedAlertsTable = tableAlerts.map(item => {
            if (item.alertId == this.state.currentAlert.alertId) {
                return {
                    ...item,
                    commentsCount: this.state.currentAlert.conversationItemCount,
                    alert: this.state.currentAlert
                };
            }
            return item;
        });

        var test = null;
        if (this.state.columnAltered) {
            this.setState({ columnAlertList: updatedAlertsTable, conversation: null, commentAlert: null });
        }
        else if (this.state.tableAltered) {
            this.setState({ distinctAlertList: updatedAlertsTable, conversation: null, commentAlert: null });
        }
        else {
            this.props.updateAlertList(updatedAlertsTable);
            this.setState({ conversation: null, commentAlert: null });
        }
    }

    externalReload() {
        this.setState({ currentAlert: jsAlert, selected: -1, assetComments: null, tableAltered: false }, () => {
            this.props.reloadTable();
        });
       
    }

    createJobHandler(alert, opportunity) {
        let tableTabIndex = !this.state.tableAltered && this.state.tableTabIndex != this.props.startTabIndex ? this.props.startTabIndex : this.state.tableTabIndex;
        let alertList = /*this.state.*/tableTabIndex === 0 ? this.props.tableObj.equipmentAlertsList : this.props.tableObj.deviceAlertsList;
        let matchingAlerts = alertList.filter(a => a.typeId == alert.typeId && a.alert.assetId == alert.assetId && a.alertId != alert.alertId);
        let alertwithCaseDetails = matchingAlerts?.find(x => (x.alert.tagIds.includes("91")));

        if (matchingAlerts.length > 0 && alertwithCaseDetails != null && alertwithCaseDetails != "undefined") {
            var alertsExistText = "It looks like this asset already has a job for this Alert type. Would you like to add these alerts to that job?";
            this.setState({ modalTitle: "Add To Job?", modalMessage: alertsExistText },
                () => {
                    this.showAddAlertsToJobModal(null);
                });
        }
        else {
            this.openCreateJobModal(alert, opportunity);
        }
    }

    openCreateJobModal(alert, opportunity) {
        this.setState({ modalTitle: "Create Job", editAlert: alert, currentOpportunity: opportunity },
            () => {
                this.setState(prevState => ({
                    currentOpportunity: {
                        ...prevState.currentOpportunity,
                        subject: this.state.editAlert.message
                    }
                }));
                this.props.getAddress(this.state.currentCustomer);
                this.showCreateJobModal(null);
            });
    }

    onAddToJob() {
        this.setState({ editAlert: this.state.currentAlert, showAddAlertsToJobConfirm: !this.state.showAddAlertsToJobConfirm }, () => {
            var isCreateJob = false;
            this.createJobandLead(isCreateJob);
        });
    }

    onContinueExistingJobFlow() {
        this.showAddAlertsToJobModal(null);
        this.openCreateJobModal(this.state.currentAlert, this.state.currentOpportunity);
    }

    showAddAlertsToJobModal() {
        this.setState({
            showAddAlertsToJobConfirm: !this.state.showAddAlertsToJobConfirm
        });
    }

    showCreateJobModal() {
        this.setState({
            showCreateJobConfirm: !this.state.showCreateJobConfirm
        }, () => {
            if (this.state.showCreateJobConfirm)
                ModalView('createJob');
        });
    }

    openSummarizedAlertsModal() {
        this.setState({ modalTitle: "Summarized Alerts", editAlert: alert }, () => {
            this.showSummarizedAlertsModal();
        })
    }

    showSummarizedAlertsModal() {
        this.setState({
            showSummarizedAlerts: !this.state.showSummarizedAlerts
        }, () => {
            if (this.state.showSummarizedAlerts)
                ModalView('summarizedAlerts');
        });
    }

    openUnseenModal(alert) {
        this.setState({ modalTitle: "Mark Alert Unseen", modalMessage: "Do you want to mark this alert as unseen?", editAlert: alert }, () => {
            this.showUnseenModal();
        })
    }

    showUnseenModal() {
        this.setState({
            showUnseenConfirm: !this.state.showUnseenConfirm
        });
    }

    async onMarkAlertsUnseen() {
        Event("Marked Alerts Unseen", "User marked alerts unseen", "Marked Alerts Unseen");
        let alert = this.state.currentAlert;
        let dict = this.state.tableAltered ? this.state.consolidateAlertsDict : this.props.consolidateAlertsDict;
        let consolidatedAlerts = dict[alert.typeId][alert.assetId];
        let seenAlerts = consolidatedAlerts.filter(a => a.alert.alertId == alert.alertId);
        var tagAlerts = seenAlerts.map(item => {
            return {
                ...item.alert,
                conversationId: item.alert.conversationId !== null ? item.alert.conversationId.toString() : null
            };
        });
        let tableAlerts = this.state.columnAltered ? this.state.columnAlertList : (this.state.tableAltered ? this.state.distinctAlertList : this.props.alertList);
        let updatedTableAlerts = tableAlerts.map(item => {
            if (item.alertId == this.state.currentAlert.alertId) {
                let updateAlert = this.state.currentAlert;
                updateAlert.tagIds = updateAlert.tagIds.concat(TagIdsEnum.NewAlert);

                return {
                    ...item,
                    alert: updateAlert
                };
            }
            return item;
        });

        this.setState({
            showUnseenConfirm: false
        }, async () => {
                await AlertService.bulkalerttag(TagIdsEnum.NewAlert, tagAlerts, true)
                    .then(response => {
                        if (response.status === 200 && response.data !== undefined && response.data !== null && response.data !== false) {
                            console.log("Succesfully added New Alert tag to alert - alertId: " + alert.alertId + " and " + seenAlerts.length + " consolidated alerts!");

                            for (let i = 0; i < seenAlerts.length; i++) {
                                let currAlert = seenAlerts[i];
                                let alertIndex = consolidatedAlerts.indexOf(currAlert);
                                currAlert.alert.tagIds = currAlert.alert.tagIds.concat(TagIdsEnum.NewAlert);
                                consolidatedAlerts[alertIndex] = currAlert;
                                dict[alert.typeId][alert.assetId] = consolidatedAlerts;
                            }

                            this.setState({
                                consolidateAlertsDict: dict,
                                showUnseenConfirm: false,
                                selected: -1,
                                alertSelected: false,
                                editAlert: jsAlert,
                                currentAlert: jsAlert
                            }, () => {
                                    if (this.state.columnAltered) {
                                        this.setState({ columnAlertList: updatedTableAlerts });
                                    }
                                    else if (this.state.tableAltered) {
                                        this.setState({ distinctAlertList: updatedTableAlerts });
                                    }
                                    else {
                                        this.props.updateAlertList(updatedTableAlerts);
                                    }
                            });
                        }
                        else {
                            console.log("Failed to add New Alert tag to alert - alertId: " + alert.alertId);
                        }
                    }).
                    catch(e => {
                        console.log(e);
                    });
        })
    }

    navigateToJob = () => {
        Event("Go To Job Clicked", "User clicked Go To Job button in Triage Alerts", "Go To Job Clicked");
        this.props.setActivePath("/scheduleRepairs");
    }

    onFollowUpDateChange(e) {
        if (e != null) {
            var date = moment(e).toDate();
            var dateStr = moment(e).format().split('T')[0];

            this.setState({ dpFollowUpDate: date, oppFollowUpDate: date });
        }
        else {
            this.setState({ dpFollowUpDate: null, oppFollowUpDate: "" });
        }
    }

    onJobTypeChange(e) {
        const type = e.target.value;

        this.setState({ jobTypeId: parseInt(type, 10) });
    }

    onCampaignChange(e) {
        const campaign = e.target.value;

        this.setState({ campaignId: parseInt(campaign, 10) });
    }

    onChangeTags(tags) {

        let tagIds = tags.map(s => s.id);

        this.setState({ selectedTags: tags, selectedTagIds: tagIds });
    }

    onCallReasonChange(e) {
        const callReason = e.target.value;

        this.setState({ callReasonId: parseInt(callReason, 10) });
    }

    onPriorityOpportunityChange(e) {
        const priority = e.target.value;

        this.setState(prevState => ({
            currentOpportunity: {
                ...prevState.currentOpportunity,
                priority: parseInt(priority, 10)
            }
        }));
    }

    onCommentsChange(e) {
        const comment = e.target.value;

        this.setState(prevState => ({
            currentOpportunity: {
                ...prevState.currentOpportunity,
                comment: comment
            }
        }));
    }

    onSubjectChange(e) {
        const subject = e.target.value;

        this.setState(prevState => ({
            currentOpportunity: {
                ...prevState.currentOpportunity,
                subject: subject
            },
            editAlert: {
                ...prevState.editAlert,
                message: subject
            }
        }));
    }

    onCreateJob() {
        Event("Job Created", "User created job for alert", "Job Created");
        if (this.props.hasServiceTitan && (this.state.campaignId == null || this.state.oppFollowUpDate == "")) {
            var newMsg = `Campaign and FollowUpDate are Required to create lead in Service Titan`;

            this.setState({
                message: newMsg,
                showMessage: true
            });


            setTimeout(() => {
                this.setState({
                    message: "",
                    showMessage: false
                });
            }, 10000);
        } 
        else if (this.props.hasServiceTitan && this.props.isInvalidCustomerandLocation && this.props.customerID != 0 && this.props.locationId != 0) {
            var newMsg = `The location and customer IDs entered are not associated`;

            this.setState({
                message: newMsg,
                showMessage: true
            });


            setTimeout(() => {
                this.setState({
                    message: "",
                    showMessage: false
                });
            }, 10000);
        }
        else if (this.props.hasServiceTitan && (this.props.customerID == null || this.props.customerID == "" || this.props.customerID == 0 || this.props.locationId == 0 || this.props.locationId == null || this.props.locationId == null) || this.props.isInValidCustomerId )
        { 
            var newMsg = `CustomerId and LocationId are Required to create lead in Service Titan`;

            this.setState({
                message: newMsg,
                showMessage: true
            });


            setTimeout(() => {
                this.setState({
                    message: "",
                    showMessage: false
                });
            }, 10000);
	     }
       
       
        else {
            this.setState({
                onSTLoading: true
            }, async () => {
                this.createJobandLead();
            })
        }
    }

    async createJobandLead(createJob = true) {
        let dict = this.state.tableAltered ? this.state.consolidateAlertsDict : this.props.consolidateAlertsDict;
        let editAlert = this.state.editAlert;
        let editAlerts = dict[editAlert.typeId][editAlert.assetId];
        if (editAlerts.length >1) {
            editAlerts = editAlerts.sort(function (a, b) { return a.alertId - b.alertId });
        }
        // editAlerts = editAlerts.reverse();
        if (editAlerts != undefined) { 
        var tagAlerts = editAlerts.map(item => {
            return {
                ...item.alert,
                conversationId: item.alert.conversationId !== null ? item.alert.conversationId.toString() : ""
            };
        });

        await AlertService.bulkalerttag(TagIdsEnum.JobCreated, tagAlerts, true) /* Job Created tag */
            .then(response => {
                if (response.status === 200 && response.data !== undefined && response.data !== null && response.data !== false) {
                    console.log("Succesfully added jobCreated tag to alerts - assetId: " + editAlert.assetId); //todo delete 
                }
                else {
                    console.log("Failed to add jobCreated tag to alert - assetId: " + editAlert.assetId); //todo delete
                }
            }).
            catch(e => {
                console.log(e);
                this.setState({
                    onSTLoading: true,
                    tableLoading: false
                });
            });

        //create job for just first alert.
        // ONLY CREATE JOB IF CREATE JOB BOOL IS TRUE
            if (createJob) {
                let currAlert = editAlerts[0];
                this.setState({ editAlert: currAlert }, async () => {
                    await this.createJob();
                });
            }
            else {
                this.setState(prevState => ({
                    tableLoading: true,
                    editAlert: {
                        ...prevState.editAlert,
                        priority: 1,
                        priorityName: "High",
                        isFalsePositive: false
                    }
                }), async () => {
                    await AlertService.updatealert(this.state.editAlert)
                        .then(response => {
                            var msg = "";
                            if (response.status === 200 && response.data !== undefined && response.data !== null && response.data !== false) {
                                msg = "Alerts successfully added to Job";
                            }
                            else {
                                msg = "Failed to add alerts to Job";
                            }
                            this.setState({
                                message: msg,
                                showMessage: true
                            });

                            setTimeout(() => {
                                this.setState({
                                    message: "",
                                    showMessage: false,
                                    showMessage: false,
                                    onSTLoading: false
                                });
                            }, 3000);

                            this.reloadTable();
                        }).
                        catch(e => {
                            console.log(e);
                            this.setState({
                                onSTLoading: true,
                                tableLoading: false,
                            });
                        });
                });
            }
        }
    }
    async createLead() {
        let x = "";
        if (this.props.customerID != "" && this.props.customerID != null && this.state.editAlert != null && this.state.editAlert !== undefined && this.state.editAlert?.alert?.organization != null && this.state.editAlert?.alert?.organization != undefined)
            var updateCustoer = {
                "Names": "CustomerId",
                "DataTypeIds": "3",
                "Values": this.props.customerID.toString(),
                "OrganizationId": parseInt(this.state.editAlert.alert.organization, 10)
            }

        await settingService.addLocationIdtoHome(this.props.locationId, this.state.currentCustomer.houseAssetId)
            .then(response => {
                if (response?.status === 200 && response?.data !== undefined && response?.data !== null && response?.data !== false) {
                    this.setState({
                        isUpdateorg: true,
                        message: "Updating  location attributes",
                        showMessage: true
                    });
                }
            }).catch(e => {
                console.log(e);
                console.log("Failed to update or add  location attributes");
                this.setState({
                   // isLeadfailurePopupShow: true,
                    message: "Failed to update or add  location attributes ",
                    showMessage: true
                });

                setTimeout(() => {
                    this.setState({
                        message: "",
                        showMessage: false,
                        tableLoading: false,
                    });
                }, 10000);
            } );

        await settingService.organizationUpdateAttributesCustomer(updateCustoer)
            .then(response => {
             if (response !== undefined && response.length > 0) 
                 {
                    this.setState({
                        isUpdateHome: true,

                    }, async () => {
                          if (this.props.hasServiceTitan && this.state.jobId != "") {
                            if (this.state.campaignId !== null && this.state.oppFollowUpDate !== "") 
                            {
                                                var leadObj = {
                                                    customerId: parseInt(this.props.customerID, 10),
                                                    locationId: parseInt(this.props.locationId, 10),
                                                    campaignId: this.state.campaignId,
                                                    businessUnitId: 0,
                                                    jobTypeId: this.state.jobTypeId,
                                                    summary: "##LFID" + this.state.jobId +"##"+this.state.currentOpportunity.subject,
                                                    tagTypeIds: this.state.selectedTagIds,
                                                    priority: this.state.currentOpportunity.priority == 1 ? "1" : this.state.currentOpportunity.priority == 2 ? "2" : "3",
                                                    callReasonId: this.state.callReasonId,
                                                    followUpDate: this.state.oppFollowUpDate
                                                };
                                                var portalLead = {
                                                    Lead: leadObj,
                                                    Customer: this.state.currentCustomer
                                                };

                                                await ServiceTitanService.createleadfromportal(this.props.PartnerId, this.state.editAlert.alertId, portalLead) //this.state.editAlert.alertId, this.state.currentOpportunity.subject, this.state.currentOpportunity.priority, this.state.oppFollowUpDate, this.state.jobTypeId, this.props.PartnerId, this.state.currentCustomer)
                                                    .then(response4 => { 
                                                        if (response4.status === 200 && response4.data !== undefined && response4.data !== null && response4.data !== 0 && response4.data !== false ) {
                                                            var newMsg = `Lead created in Service Titan for this job`;

                                                            this.setState({
                                                                message: newMsg,
                                                                showMessage: true,
                                                                tableLoading: false,
                                                                isLeadCreated: true,
                                                                leadId: response4.data.toString()//response4?.data?.toString()
                                                            }, () => {
                                                                // this.createJobandLead();
                                                                this.mapLeadandJobIds();
                                                            });
                                                        }
                                                        else {
                                                            var newMsg = `Failed to create lead in Service Titan`;

                                                            this.setState({
                                                                message: newMsg,
                                                                showMessage: true,
                                                                onSTLoading: false,
                                                                isLeadfailurePopupShow: true
                                                            });
                                                            setTimeout(() => {
                                                                this.setState({
                                                                    message: "",
                                                                    showMessage: false
                                                                });
                                                            }, 3000);
                                                            console.log(newMsg);
                                                        }
                                                    })
                                                    .catch(e => {
                                                        console.log(e);
                                                        this.setState({
                                                            isLeadfailurePopupShow: true,
                                                            message: "Failed to create lead in Service Titan",
                                                            showMessage: true
                                                        });

                                                        setTimeout(() => {
                                                            this.setState({
                                                                message: "",
                                                                showMessage: false,
                                                                tableLoading: false,
                                                            });
                                                        }, 10000);

                                                        console.log("Failed to create lead in Service Titan");
                                                    });
                                                }
                                            else 
                                            {
                                                var newMsg = `Failed to create lead in Service Titan`;
                                                console.log(newMsg);
                                                this.setState({
                                                    message: newMsg,
                                                    showMessage: true
                                                });
                                                setTimeout(() => {
                                                    this.setState({
                                                        message: "",
                                                        showMessage: false
                                                    });
                                                }, 3000);
                                            }
                                        }
                                        else 
                                        {
                                            this.setState({ tableLoading: false });
                                            this.reloadTable();
                                        }
				     });          
                 }
               
                else {
                    this.setState({
                        isLeadfailurePopupShow: true,
                        message: "Failed to update customer attributes in  organization",
                        showMessage: true
                    });
                    console.log("Failed to update customer attributes in organization");
                    setTimeout(() => {
                        this.setState({
                            message: "",
                            showMessage: false,
                            tableLoading: false,
                        });
                    },1000);
                }
            })
            .catch(e => {
                console.log(e);
                console.log("Failed to update customer attributes in organization");
                this.setState({
                    message: "Failed to update customer attributes in organization",
                    showMessage: true
                });


                setTimeout(() => {
                    this.setState({
                        message: "",
                        showMessage: false
                    });
                }, 10000);
            });
    }


    
    async createJob() {
        this.setState(prevState => ({
            tableLoading: true,
            editAlert: {
                ...prevState.editAlert,
                priority: 1,
                priorityName: "High",
                isFalsePositive: false
            }
        }), async () => {

            await AlertService.updatealert(this.state.editAlert)
                .then(response => {
                    if (response.status === 200 && response.data !== undefined && response.data !== null && response.data !== false) {
                        this.setState(prevState => ({
                            currentOpportunity: {
                                ...prevState.currentOpportunity
                            }
                        }), async () => {
                            await AlertService.createjob(this.state.editAlert.alertId, this.state.currentOpportunity)
                                .then(response2 => {
                                    if (response2.status === 200 && response2.data !== undefined && response2.data !== null && response2.data !== false) {
                                        var localeDateAlert = new Date(this.state.editAlert.alert.startTime);
                                        var msg = `Job created for alert occurring on ${localeDateAlert.toLocaleDateString()}, ${localeDateAlert.toLocaleTimeString()}.`;
                                        this.setState({
                                            message: msg,
                                            showMessage: true,
                                            jobId: response2.data,

                                        }, async () => {
                                            if (this.props.hasServiceTitan) {
                                                this.setState({
                                                    onSTLoading: true
                                                }, async () => {
                                                    this.createLead();
                                                })
                                               // this.mapLeadandJobIds();
                                            }
                                            else {
                                                this.setState({
                                                    showMessage: false,
                                                    showCreateJobConfirm: !this.state.showCreateJobConfirm,
                                                    isLeadfailurePopupShow: false,
                                                    onSTLoading: false,                                               
                                                    tableLoading: false

                                                }, () => { this.reloadTable() });
                                            }
					            }); 
                                        setTimeout(() => {
                                            this.setState({
                                                message: "",
                                                showMessage: false,
                                                showMessage: false,
                                                tableLoading: false
                                            });
                                        }, 3000);
                                    }
                                } )
                                .catch(e => {
                                    console.log(e);
                                    console.log("Failed to create opportunity");
                                    this.setState({
                                        message: "Failed to create opportunity",
                                        showMessage: true
                                    });


                                    setTimeout(() => {
                                        this.setState({
                                            message: "",
                                            showMessage: false,
                                            tableLoading: false
                                        });
                                    }, 10000);
                                });
                        });
                    }
                }).catch(e => {
                    console.log(e);
                    console.log("Failed to update alert status");
                    this.setState({
                        message: "Failed to update alert status",
                        showMessage: true
                    });


                    setTimeout(() => {
                        this.setState({
                            message: "",
                            showMessage: false,
                            tableLoading: false
                        });
                    }, 10000);
                });
        });

    }
        
     
    async mapLeadandJobIds() {
        if (this.state.leadId != null && this.state.leadId != "" && this.state.jobId != null && this.state.leadId != "") {
            await ServiceIntegrationService.mapLeadandJobId(this.props.PartnerId, this.state.leadId, this.state.jobId, this.state.editAlert.alertId,this.props.locationId)
                .then(response2 => {
                    if (response2.status === 200 && response2.data !== undefined && response2.data !== null && response2.data !== false) {
                        var localeDateAlert = new Date(this.state.editAlert.alert.startTime);
                        var msg = "";
                        this.setState({
                            showMessage: false,
                            showCreateJobConfirm: !this.state.showCreateJobConfirm,
                            isLeadfailurePopupShow: false,
                            onSTLoading: false,                       
                            tableLoading: false,
                            message: "mapping lead and opportunity ids",
                            showMessage: true
                            
                        }, () => { this.reloadTable() });
                        
                    }
                })
                .catch(e => {
                    console.log("Failed to map lead and 0pportunity");
                    this.setState({
                        message: "Failed to map lead and 0pportunity",
                        showMessage: true
                    });


                    setTimeout(() => {
                        this.setState({
                            message: "",
                            showMessage: false,
                            tableLoading: false,
                            onSTLoading: false,
                            tableLoading: false
                        });
                    }, 10000);
                });
        }
        else {
            this.setState({
                showMessage: false,
                showCreateJobConfirm: false,
                isLeadfailurePopupShow: false,
                onSTLoading: false,
                tableLoading: false
            }, () => { this.reloadTable(); });
        }
     }

    openCloseAlertModal(alert) {
        var localeDateAlert = new Date(alert.startTime);
        this.setState({
            modalTitle: "Close Alert",
            modalMessage: `You are about to close this alert from ${localeDateAlert.toLocaleDateString()}, ${localeDateAlert.toLocaleTimeString()}. Are you sure?`,
            editAlert: alert
        }, () => { this.showCloseAlertModal() });
    }

    showCloseAlertModal() {
        this.setState({
            showCloseAlertConfirm: !this.state.showCloseAlertConfirm
        });
    }

    onCloseAlert() {
        this.closeAlerts();
        this.setState({ showCloseAlertConfirm: !this.state.showCloseAlertConfirm, tableLoading: true });
    }

    async closeAlerts() {
        Event("Close Alerts", "User closed alerts", "Close Alerts");
        let dict = this.state.tableAltered ? this.state.consolidateAlertsDict : this.props.consolidateAlertsDict;
        let editAlert = this.state.editAlert;
        let editAlerts = dict[editAlert.typeId][editAlert.assetId];
        let alertList = editAlerts.map( a => a.alert );

        await this.closeAlert(alertList);
    }

    async closeAlert(alertList) {
        await AlertService.triagealerts("CLOSEINTERNAL", alertList)
            .then(response => {
                if (response.status === 200 && response.data !== undefined && response.data !== null && response.data !== false) {
                    this.setState({
                        message: `Alerts closed.`,
                        showMessage: true,
                        tableLoading: false
                    });
                    this.reloadTable();
                }
                else {
                    this.setState({
                        message: "Failed to close the alerts!",
                        showMessage: true,
                        tableLoading: false
                    });
                }

                setTimeout(() => {
                    this.setState({
                        message: "",
                        showMessage: false
                    });
                }, 3000);
            })
            .catch(e => {
                console.log(e);
                this.setState({
                    message: "Failed to close the alerts!",
                    showMessage: true,
                    tableLoading: false
                });
            });
    }

    openSilenceAlertModal(alert) {
        console.log("silencing alert:");
        console.log(alert);
        this.setState({
            modalTitle: "Warning",
            modalMessage: `You are about to remove this ${alert.priorityName} priority item from the alert list. Are you sure you would like to proceed?`,
            editAlert: alert
        }, () => { this.showSilenceAlertModal() });
    }

    showSilenceAlertModal() {
        this.setState({
            showSilenceAlertConfirm: !this.state.showSilenceAlertConfirm
        });
    }

    onSilenceAlert() {
        Event("Silence Alert", "User silenced alert", "Silence Alert");
        this.setState({ showSilenceAlertConfirm: !this.state.showSilenceAlertConfirm, showSilenceAlertUntil: true });
    }

    onSilenceAlertConfirm(dateStr) {
        this.silenceAlerts(dateStr);
        this.setState({ showSilenceAlertConfirm: false, tableLoading: true });
    }

    async silenceAlerts(dateStr) {
        let dict = this.state.tableAltered ? this.state.consolidateAlertsDict : this.props.consolidateAlertsDict;
        let editAlert = this.state.editAlert;
        let editAlerts = dict[editAlert.typeId][editAlert.assetId];
        let alertIds = editAlerts.map((item) => item.alertId);
        let alertIdsStr = alertIds.join(",");
        let muteUntilStr = dateStr;

        await AlertService.silencealerts(alertIdsStr, muteUntilStr)
            .then(response => {
                let successMsg = "Alert has successfully been silenced.";
                let errorMsg = "Failed to silence the alert!";
                if (editAlerts.length > 1) {
                    successMsg = "Alerts have successfully been silenced.";
                    errorMsg = "Failed to silence the alerts!";
                }

                if (response.status === 200 && response.data !== undefined && response.data !== null && response.data !== false) {
                    this.setState({
                        message: successMsg,
                        showMessage: true
                    }, () => {
                            this.reloadTable();
                    });
                }
                else {
                    this.setState({
                        message: errorMsg,
                        showMessage: true,
                        tableLoading: false
                    });
                }

                setTimeout(() => {
                    this.setState({
                        message: "",
                        showMessage: false
                    });
                }, 3000);
            })
            .catch(e => {
                console.log(e);
            });
    }

    openUnsilenceAlertModal(alert) {
        this.setState({
            modalTitle: "Unsilence Alert",
            modalMessage: `Warning: You are about to Unsilence this ${alert.priorityName} priority item from the alert list. Are you sure you would like to proceed?`,
            editAlert: alert
        }, () => { this.showUnsilenceAlertModal() });
    }

    showUnsilenceAlertModal() {
        this.setState({
            showUnsilenceAlertConfirm: !this.state.showUnsilenceAlertConfirm
        });
    }
    showLeadfailurePopupModal() {
        this.setState({
            isLeadfailurePopupShow: !this.state.isLeadfailurePopupShow
        }, () => {
            ModalView('createLeadFailure');
        });
    }
    onUnsilenceAlert() {
        Event("Unsilence Alert", "User unsilenced alert", "Unsilence Alert");
        this.unsilenceAlerts();
        this.setState({ showUnsilenceAlertConfirm: !this.state.showUnsilenceAlertConfirm, tableLoading: true });
    }

    onCreateJobwithoutLead() {
        this.setState({
            onSTLoading: true,
            tableLoading: true,
        }, () => { this.createJobandLead(); });
    }

    async unsilenceAlerts() {
        let dict = this.state.tableAltered ? this.state.consolidateAlertsDict : this.props.consolidateAlertsDict;
        let editAlert = this.state.editAlert;
        let editAlerts = dict[editAlert.typeId][editAlert.assetId];
        let alertIds = editAlerts.map((item) => item.alertId);
        let alertIdsStr = alertIds.join(",");
        let now = moment();
        let dateStr = now.format();

        await AlertService.silencealerts(alertIdsStr, dateStr)
            .then(response => {
                let successMsg = "Alert has successfully been unsilenced.";
                let errorMsg = "Failed to unsilence the alert!";
                if (editAlerts.length > 1) {
                    successMsg = "Alerts have successfully been unsilenced.";
                    errorMsg = "Failed to unsilence the alerts!";
                }

                if (response.status === 200 && response.data !== undefined && response.data !== null && response.data !== false) {
                    this.setState({
                        message: successMsg,
                        showMessage: true
                    }, () => {
                        this.reloadTable();
                    });
                }
                else {
                    this.setState({
                        message: errorMsg,
                        showMessage: true,
                        tableLoading: false
                    });
                }

                setTimeout(() => {
                    this.setState({
                        message: "",
                        showMessage: false
                    });
                }, 3000);
            })
            .catch(e => {
                console.log(e);
            });
    }

    onAlertClick(index, alertId) {
        let x = index;
        if (this.state.selected == x) {
            this.setState({ selected: -1, alertMsgObj: {} });
        }
        else {
            var alert = this.props.tableObj.alerts.find(t => t.alertId == alertId);
            let alertMsg = {};
            var alertMsgObj = this.props.alertMessaging.find(a => a.id == alert.typeId);
            if (alertMsgObj !== null && alertMsgObj !== undefined) {
                alertMsg = alertMsgObj;
            }
            this.setState({
                graphLoaded: false,
                selected: x,
                alertMsgObj: alertMsg,
                currentAlert: alert,
                alertDetailLoading: true
            }, async () => {
                await AlertService.get(this.state.currentAlert.organization)
                    .then(async response => {
                        if (this.props._isMounted) {
                            /* New Alert Tag Deletion */ 
                            let dict = this.state.tableAltered ? this.state.consolidateAlertsDict : this.props.consolidateAlertsDict;
                            let consolidatedAlerts = dict[alert.typeId][alert.assetId];
                            let hasNew = consolidatedAlerts.some(a => a.alert.tagIds.length && a.alert.tagIds.includes(TagIdsEnum.NewAlert));

                            if (hasNew) {
                                let newAlerts = consolidatedAlerts.filter(a => a.alert.tagIds.length && a.alert.tagIds.includes(TagIdsEnum.NewAlert));
                                var tagAlerts = newAlerts.map(item => {
                                    return {
                                        ...item.alert,
                                        conversationId: item.alert.conversationId !== null ? item.alert.conversationId.toString() : ""
                                    };
                                });

                                await AlertService.bulkalerttag(TagIdsEnum.NewAlert, tagAlerts, false)
                                    .then(response => {
                                        if (response.status === 200 && response.data !== undefined && response.data !== null && response.data !== false) {
                                            console.log("Succesfully removed New Alert tag to alert - alertId: " + alert.alertId + " and " + newAlerts.length + " consolidated alerts!");   

                                            for (let i = 0; i < newAlerts.length; i++) {
                                                let currAlert = newAlerts[i];
                                                let alertIndex = consolidatedAlerts.indexOf(currAlert);
                                                currAlert.alert.tagIds = currAlert.alert.tagIds.filter(t => t !== TagIdsEnum.NewAlert);

                                                consolidatedAlerts[alertIndex] = currAlert;
                                                dict[alert.typeId][alert.assetId] = consolidatedAlerts;
                                            }

                                            if (this._isMounted)
                                                this.setState({ consolidateAlertsDict: dict, tableAltered: true });
                                        }
                                        else {
                                            console.log("Failed to remove New Alert tag to alert - alertId: " + alert.alertId);
                                        }
                                    }).
                                    catch(e => {
                                        console.log(e);
                                        this.setState({
                                            onSTLoading: true,
                                            tableLoading: false
                                        });
                                    });
                            }

                            this.setState({ currentCustomer: response.data }, async () => {
                                await AlertService.getalertasset(this.state.currentAlert)
                                    .then(response2 => {
                                        this.setState({ currentAsset: response2.data, alertDetailLoading: true }, async () => {
                                            await AssetService.getassetlinks(this.state.currentAlert.assetId)
                                                .then(response4 => {
                                                    if (response4.data.cases && response4.data.cases.length > 0) {
                                                        var assetCases = response4.data.cases;
                                                        var alertCases = assetCases.filter(a => a.source === "Alert" && a.isClosed != true );
							                        //&& isNaN(a.caseNumber.substring(1));
                                                    // && consolidatedAlerts.some(c => c.alertId === a.caseNumber.substring(1))).sort((a, b) => b.caseId - a.caseId);
                                                        this.setState({ alertCases: alertCases, alertDetailLoading: false });
                                                    }
                                                    else {
                                                        this.setState({ alertDetailLoading: false, alertCases : [] });
                                                    }
                                                })
                                                .catch(e => {
                                                    console.log(e);
                                                });
                                        });
                                    })
                                    .catch(e => {
                                        console.log(e);
                                    });
                            });
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    })
            });
        }
    }

    getStartDate(startTime) {
        var d = new Date(startTime);
        var hr = d.getHours() < 10 ? "0" : "";
        var min = d.getMinutes() < 10 ? "0" : "";
        var sec = d.getSeconds() < 10 ? "0" : "";
        var month = d.getMonth() + 1;

        var date = month + "/" + d.getDate() + "/" + d.getFullYear() + " " + d.toLocaleTimeString('en-US');

        if (startTime == "" || startTime == null)
            date = "";

        return (date);
    }

    getEndDate(endTime) {
        var d = new Date(endTime);
        var hr = d.getHours() < 10 ? "0" : "";
        var min = d.getMinutes() < 10 ? "0" : "";
        var sec = d.getSeconds() < 10 ? "0" : "";
        var month = d.getMonth() + 1;

        var date = month + "/" + d.getDate() + "/" + d.getFullYear() + " " + d.toLocaleTimeString('en-US');

        if (endTime == "" || endTime == null) {
            date = "Active";
            return (<div style={{ color: 'red' }}>{date}</div>);
        }
        else {
            return (date);
        }
    }

    getFormatDate(timeStr) {

        var time = timeStr.substring(
            timeStr.indexOf("(") + 1,
            timeStr.lastIndexOf(")")
        )

        var d = new Date(parseInt(time, 10));
        var hr = d.getHours() < 10 ? "0" : "";
        var min = d.getMinutes() < 10 ? "0" : "";
        var sec = d.getSeconds() < 10 ? "0" : "";
        var month = d.getMonth() + 1;

        var date = month + "/" + d.getDate() + "/" + d.getFullYear() + " " + d.toLocaleTimeString('en-US');

        if (time == "" || time == null)
            date = "";

        return (date);
    }

    getAddress() {
        var addy = "";

        if (this.state.currentCustomer.address !== "" && this.state.currentCustomer.address !== null) {
            addy = this.state.currentCustomer.address;

            if (this.state.currentCustomer.city !== "" && this.state.currentCustomer.city !== null) {
                addy += ", " + this.state.currentCustomer.city;
            }

            if (this.state.currentCustomer.state !== "" && this.state.currentCustomer.state !== null) {
                addy += ", " + this.state.currentCustomer.state;

                if (this.state.currentCustomer.zip !== "" && this.state.currentCustomer.zip !== null)
                    addy += " " + this.state.currentCustomer.zip;
            }
        }
        return (addy);
    }

    getAlertSeverity(alertId) {
        var alertMsgObj = this.props.alertMessaging.find(a => a.id == alertId);
        if (alertMsgObj !== null && alertMsgObj !== undefined) {
            if (alertMsgObj.severity == "Critical") {
                return (<Tooltip title="Critical" enterDelay={600} leaveDelay={200}>
                    <NotificationIcon className="db-red db-red-bg alerts-severity-icon" />
                </Tooltip>
                );
            }
            else {
                return (<Tooltip title="Warning" enterDelay={600} leaveDelay={200}>
                    <NotificationIcon className="db-orange db-orange-bg alerts-severity-icon" />
                </Tooltip>
                );
            }
        }
        return (<Tooltip title="Warning" enterDelay={600} leaveDelay={200}>
            <NotificationIcon className="db-orange db-orange-bg alerts-severity-icon" />
        </Tooltip>);
    }

    getAlertIndicator(alert, assetComments) {
        let circleType = "";
        let circle = <CircleIcon style={{ padding: '5px', color: 'transparent' }} />;

        if (alert !== null && alert !== undefined) {
            let dict = this.state.tableAltered && this.state.consolidateAlertsDict != {} ? this.state.consolidateAlertsDict : this.props.consolidateAlertsDict;
            let consolidatedAlerts = dict[alert.typeId][alert.alert.assetId];
            let hasNew = consolidatedAlerts.some(a => a.alert.tagIds.length && a.alert.tagIds.includes(TagIdsEnum.NewAlert));

            if (hasNew) {
                circleType = "New Alert";
                circle = <CircleIcon style={{ padding: '5px', color: 'cornflowerBlue' }} />;
            }
            else if (assetComments !== null && assetComments !== undefined && assetComments.length) {
                let alertComments = assetComments.filter(c => c.assetId == alert.alert.assetId && c.alertTypeId == alert.alert.typeId);
                if (alertComments !== null && alertComments !== undefined && alertComments.length) {
                    let alertComment = alertComments[0];
                    if (alertComment.hasAnswer) {
                        circleType = "Question Answered";
                        circle = <CircleIcon style={{ padding: '5px', color: 'green' }} />;
                    } 
                    else if (alertComment.hasQuestion) {
                        circleType = "Question Asked";
                        circle = <CircleIcon style={{ padding: '5px', color: 'red' }} />;
                    }
                    else if (alertComment.hasNewComment) {
                        circleType = "New Comment";
                        circle = <CircleIcon style={{ padding: '5px', color: 'orchid' }} />; // new 'new comment' color
                    }
                }
            }
        }

        return (
            <Tooltip title={circleType} enterDelay={400} leaveDelay={300} style={{ marginTop: '-5px', paddingTop: '-5px', backgroundColor: '#f7f9fa' }}>
                {circle}
            </Tooltip>
        );
    }

    getAlertUnseen() {
        let alert = this.state.currentAlert;
        let isUnseen = false;
        if (alert !== null && alert !== undefined) {
            let dict = this.state.tableAltered ? this.state.consolidateAlertsDict : this.props.consolidateAlertsDict;
            let consolidatedAlerts = dict[alert.typeId][alert.assetId];
            let hasNew = consolidatedAlerts.some(a => a.alert.tagIds.length && a.alert.tagIds.includes(TagIdsEnum.NewAlert));

            if (hasNew) {
                isUnseen = true;
            }
        }

        return (isUnseen);
    }

    getSummarizedAlerts() {
        let alert = this.state.currentAlert;
        let isSummarized = false;
        let alerts = [];
        if (alert !== null && alert !== undefined && alert !== jsAlert && alert.alertId !== "" ) {
            let dict = this.state.tableAltered && this.state.consolidateAlertsDict !== null && this.state.consolidateAlertsDict !== undefined && this.state.consolidateAlertsDict[alert.typeId] !== undefined ? 
                this.state.consolidateAlertsDict 
                : this.props.consolidateAlertsDict;
            if(alert != null && alert.typeId != null && alert.assetId != null &&  dict[alert.typeId] != undefined )
            {
                let consolidatedAlerts = dict[alert.typeId][alert.assetId];

                if (consolidatedAlerts?.length > 1) {
                    isSummarized = true;
                    alerts = consolidatedAlerts;
                }
            }
        }

        return (alerts);
    }

    getOptionLabel = (option) => {
        return (option["typeName"]);
    }

    getOptionValue = (option) => {
        return (option["id"]);
    }

    getCustomerName(orgId, name) {
        let nameStr = name;

        if (this.props.customers.length) {
            let customerList = this.props.customers.filter(c => c.organizationId === orgId);

            if (customerList.length) {
                let customer = customerList[0];
                nameStr = customer.lastName + ", " + customer.firstName;
            }
            else {
                nameStr = this.formatOrgName(name);
            }
        }
        else {
            nameStr = this.formatOrgName(name);
        }

        return nameStr;
    }

    formatOrgName(initName) {
        let nameArr = [];

        if (initName != undefined) {
            nameArr = initName.split(' ');
        }

        let nameStr = initName;

        if (nameArr.length === 2) {
            nameStr = nameArr[1] + ", " + nameArr[0];
        }
        else if (nameArr.length > 2) {
            let lastName = nameArr.pop();
            nameStr = lastName + ", " + nameArr.join(' ');
        }
        else if (nameArr.length === 1) {
            nameStr = nameArr[0];
        }

        return nameStr;
    }

    getGraphButton(alert) {
        /* If equipment health, show 'View Graph' button. If device, dont. */
        let tableTabIndex = !this.state.tableAltered && this.state.tableTabIndex != this.props.startTabIndex ? this.props.startTabIndex : this.state.tableTabIndex;
        if (/*this.state.*/tableTabIndex === 0) {
            var width = this.props.source == "AlertQuestions" ? '104px' : '96px';
            let buttonFormat = { width: width, marginBottom: '5px', marginTop: '10px', borderRadius: '.2rem', height: '25px' };

            return (
                <ViewGraphButton currentAlert={this.state.currentAlert} currentAsset={this.state.currentAsset} buttonContent="View Graphs" buttonId={0}
                    buttonFormat={buttonFormat} show={this.state.showGraphModal} showGraphModal={this.showGraphModal} _isMounted={this.props._isMounted} acSelected={this.state.acSelected} />

            );
        }
        else
            return null;
    }

    getPage(table) {
        let page = null;
        let endIndex = (this.state.pageNum * 100) - 1;
        let startIndex = (this.state.pageNum - 1) * 100;

        if (endIndex > table.length - 1) {
            endIndex = table.length - 1;
        }

        page = table.slice(startIndex, endIndex);

        return page;
    }

    nextPage() {
        this.setState({ pageNum: this.state.pageNum + 1 });
    }

    prevPage() {
        this.setState({ pageNum: this.state.pageNum - 1 });
    }

    updateSearch() {
        if (this.state.searchChanged) {
            var start = this.state.startTime.format();
            var end = this.state.endTime.format();
            var tableTabIndex = !this.state.tableAltered && this.state.tableTabIndex != this.props.startTabIndex ? this.props.startTabIndex : this.state.tableTabIndex;
   
            this.setState({ selected: -1, currentAlert: jsAlert, currentCustomer: jsCustomer, currentAsset: jsAsset, tableLoading: true, graphLoaded: false }, () => {
                var newAlertList = [];
                var newEquipList = [];
                var newDevList = [];
                var alerts = /*this.state.*/tableTabIndex === 0 ? this.props.tableObj.equipmentAlertsList : this.props.tableObj.deviceAlertsList;
                var equipAlerts = this.props.tableObj.equipmentAlertsList;
                var devAlerts = this.props.tableObj.deviceAlertsList;

                if (this.state.alertTableType == "Critical") {
                    alerts = alerts.filter(a => a.severity == "Critical");
                    equipAlerts = equipAlerts.filter(a => a.severity == "Critical");
                    devAlerts = devAlerts.filter(a => a.severity == "Critical");
                }
                else if (this.state.alertTableType == "Warning") {
                    alerts = alerts.filter(a => a.severity == "Warning");
                    equipAlerts = equipAlerts.filter(a => a.severity == "Warning");
                    devAlerts = devAlerts.filter(a => a.severity == "Warning");
                }

                /*for (let i = 0; i < alerts.length; i++) {
                    let alert = alerts[i];
                    var addAlert = true;
 
                    if (alert.alert.startTime >= start && alert.alert.startTime <= end) {
                        addAlert = true;
                    }
                    else {
                        addAlert = false;
                    }

                    if (addAlert)
                        newAlertList = newAlertList.concat(alert);
                }*/

                for (let i = 0; i < equipAlerts.length; i++) {
                    let equipAlert = equipAlerts[i];
                    var addAlert = true;

                    if (equipAlert.alert.startTime >= start && equipAlert.alert.startTime <= end) {
                        addAlert = true;
                    }
                    else {
                        addAlert = false;
                    }

                    if (addAlert)
                        newEquipList = newEquipList.concat(equipAlert);
                }

                for (let j = 0; j < devAlerts.length; j++) {
                    let devAlert = devAlerts[j];
                    var addAlert = true;

                    if (devAlert.alert.startTime >= start && devAlert.alert.startTime <= end) {
                        addAlert = true;
                    }
                    else {
                        addAlert = false;
                    }

                    if (addAlert)
                        newDevList = newDevList.concat(devAlert);
                }

                newAlertList = /*this.state.*/tableTabIndex === 0 ? newEquipList : newDevList;

                let tableCounts = {
                    equipCritical: newEquipList !== null ? newEquipList.filter(a => a.severity == "Critical").length : 0,
                    equipWarning: newEquipList !== null ? newEquipList.filter(a => a.severity == "Warning").length : 0,
                    devCritical: newDevList !== null ? newDevList.filter(a => a.severity == "Critical").length : 0,
                    devWarning: newDevList !== null ? newDevList.filter(a => a.severity == "Warning").length : 0
                };

                this.setState({
                    searchChanged: false, tableAlertList: newAlertList, tableEquipList: newEquipList, tableDevList: newDevList, tableCounts: tableCounts,
                    tableAltered: true, filterChanged: false, columnAltered: false, /*tableTabIndex: tableTabIndex,*/
                    columnSort: { enabled: false, type: null, reverse: false }/*, tableLoading: false*/
                }, () => { this.consolidateAlerts(this.state.tableAlertList); });
            });
        }
    }

    doNothing() {
    
    }

    getTableCounts() {
        let equipList = this.props.tableObj.equipmentAlertsList !== null ? this.props.tableObj.equipmentAlertsList : [];
        let devList = this.props.tableObj.deviceAlertsList !== null ? this.props.tableObj.deviceAlertsList : [];


    }

    renderCommentCount(count) {

        let commentsContent = (count > 0) ?
            <div style={{ fontSize: "12", position: "relative", display: "inline-flex", justifyContent: "center", alignItems: "center" }}>
                <ChatBubbleIcon style={{ fontSize: "2.1em" }} color="primary" />
                <Typography component="span" style={{ position: "absolute", color: "#fff" }}>
                    {count}
                </Typography>
            </div>
            : <div>None</div> ;
        
        return (
            <td>{commentsContent}</td>
            );
    }

    getExpandContent(index, alert) {


        const { classes } = this.props;
        let isJobExists = false; 
        if (alert.alert != null && alert.alert !== undefined && alert.alert.tagIds != null && alert.alert.tagIds !== undefined && alert.alert.tagIds.includes(TagIdsEnum.JobCreated))
                isJobExists = true;
        
        let expandedLoader = this.state.alertDetailLoading ?
            <Loader />
            : null;

        
        if (this.state.selected == index) {
            if (this.state.alertCases.length > 0) { 
                let caseid = this.state.alertCases.find(x => x.caseNumber == "A" + alert.alertId)?.caseNumber;
                if (caseid != null && caseid != "undefined") {
                    var link = "/scheduleRepairs?jobNumber=" + caseid;
                }
                else {
                    var link = "/scheduleRepairs";
                }

            }

            let jobButton = (this.state.alertCases.length > 0 && this.props.source != "AlertQuestions" && isJobExists) ? //todo "Go to Job" Button Add here
                <div><Link to={link} className="secondary-btn btn-small" style={{ width: '96px', marginBottom: '5px', borderRadius: '.2rem', height: '25px', padding: '.4em' }} onClick={this.navigateToJob}>
                    Go To Job</Link></div>
                : (this.props.source == "TriageAlerts" && (!isJobExists )) ?
                    <div><button className="secondary-btn btn-small" style={{ width: '96px', marginBottom: '5px', borderRadius: '.2rem', height: '25px' }} onClick={() => this.createJobHandler(this.state.currentAlert, this.state.currentOpportunity)}>Create Job</button></div>
                    : null;

            let closeButton = this.props.source == "TriageAlerts" ? (this.props.hasServiceTitan && isJobExists) ? null :
                <div>
                    <button className="secondary-btn btn-small" style={{ width: '96px', marginBottom: '5px', borderRadius: '.2rem', height: '25px' }} onClick={() => this.openCloseAlertModal(this.state.currentAlert)}>Close Alert</button>
                </div>
                : null;

            let silenceButton = this.props.source == "AlertHistory" || this.props.source == "CustomerAlerts" || this.props.source == "AlertQuestions" ? null
                : this.state.showSilenced && this.state.currentAlert.isMuted ?
                <div>
                    <button className="secondary-btn btn-small" style={{ width: '96px', marginBottom: '5px', borderRadius: '.2rem', height: '25px' }} onClick={() => this.openUnsilenceAlertModal(this.state.currentAlert)}>Unsilence Alert</button>
                </div>
                : <div>
                    <button className="secondary-btn btn-small" style={{ width: '96px', marginBottom: '5px', borderRadius: '.2rem', height: '25px' }} onClick={() => this.openSilenceAlertModal(this.state.currentAlert)}>Silence Alert</button>
                    </div>;

            let unseenButton = this.getAlertUnseen(this.state.currentAlert) ?
                null 
                : <div>
                    <button className="secondary-btn btn-small" style={{ width: '96px', marginBottom: '5px', borderRadius: '.2rem', height: '25px' }} onClick={() => this.openUnseenModal(this.state.currentAlert)}>Mark Unseen</button>
                </div>;

            let summarizedAlerts = this.getSummarizedAlerts();
            let viewAlertsButton = summarizedAlerts?.length > 1 ?
                <div>
                    <button className="secondary-btn btn-small" style={{ width: '96px', marginBottom: '5px', borderRadius: '.2rem', height: '25px' }} onClick={() => this.openSummarizedAlertsModal(this.state.currentAlert)}>View Alerts</button>
                </div>
                : null;

            let commentsButtonText = this.props.source == "AlertQuestions" ? "Answer Question" : "Comments";
            let commentsButtonWidth = this.props.source == "AlertQuestions" ? '104px' : '96px';

            return (
                <tr className="expanded" key={`expandedContent-${index}`} style={{ backgroundColor: 'white !important' }}>
                    <td colSpan={10} style={{ color: 'unset' }}>
                        <div className="row">
                            <div className="col">
                                <h6 className="db-blue">Customer</h6>
                                <p><span style={{ color: 'grey' }}>Name</span><br />
                                    {this.state.currentCustomer.name}
                                </p>
                                <p><span style={{ color: 'grey' }}>Phone Number</span><br />
                                    {this.state.currentCustomer.phone}
                                </p>
                                <p><span style={{ color: 'grey' }}>Email</span><br />
                                    {this.state.currentCustomer.email}
                                </p>
                                <p><span style={{ color: 'grey' }}>Address</span><br />
                                    {this.getAddress()}
                                </p>
                            </div>
                            <div className="col">
                                <h6 className="db-blue">Asset</h6>
                                <p>
                                    <span style={{ color: 'grey' }}>Make</span><br />
                                    {this.state.currentAsset.make}
                                </p>
                                <p><span style={{ color: 'grey' }}>Model</span><br />
                                    {this.state.currentAsset.model}
                                </p>
                                <p><span style={{ color: 'grey' }}>Manufacturing Date</span><br />
                                    {this.state.currentAsset.mfgDate}
                                </p>
                            </div>
                            <div className="col-4">
                                <h6 className="db-blue">Alert Details</h6>
                                <p><span style={{ color: 'grey' }}>Possible Causes</span><br />
                                    {this.state.alertMsgObj.possibleCauses}
                                </p>
                                <p><span style={{ color: 'grey' }}>Suggested Technician Action</span><br />
                                    {this.state.alertMsgObj.techAction}
                                </p>
                                <p><span style={{ color: 'grey' }}>Suggested Homeowner Action</span><br />
                                    {this.state.alertMsgObj.homeownerAction}
                                </p>
                                <p><span style={{ color: 'grey' }}>Risk</span><br />
                                    {this.state.alertMsgObj.risk}
                                </p>
                                
                            </div>
                            <div className="col-2">
                                <h6 className="db-blue">Take Action</h6>
                                <div>
                                    {this.getGraphButton(alert)}
                                    {(!this.state.isCustomerSupport) && (< div >
                                        {jobButton}
                                        {closeButton}
                                        {silenceButton}
                                        {<div>
                                            <button className="secondary-btn btn-small" style={{ width: commentsButtonWidth, borderRadius: '.2rem', height: '25px', marginBottom: '5px' }} onClick={() => this.openCommentsModal(alert.alert)}>{commentsButtonText}</button>
                                        </div>}
                                        {unseenButton}
                                        {viewAlertsButton}
                                    </div>)}
                                </div>
                            </div>
                            {expandedLoader}
                        </div>
                        <div className="bottom-right">
                            <Tooltip style={{ position: "absolute", right: "0px", bottom: "0px" }} title="Question about this alert? Submit a question to ProIQ Advanced LifeWhere Support by clicking the comments button."
                                enterDelay={600} leaveDelay={200}>
                                <HelpIcon />
                            </Tooltip>
                        </div>
                    </td>
                </tr>
            );
        }
        else
            return (<div>{null}</div>);
    }

    renderTable() {
        let tableAlerts = this.state.columnAltered ? this.state.columnAlertList : (this.state.tableAltered ? this.state.distinctAlertList /*this.state.tableAlertList*/ : this.props.alertList); /*this.props.tableObj.equipmentAlertsList.filter(e => e.alert.isMuted == false && e.alert.isActive == true);*/
        let consolidateDict = this.state.tableAltered ? this.state.consolidateAlertsDict : this.props.consolidateAlertsDict;
        let assetComments = this.state.assetComments !== null ? this.state.assetComments : this.props.assetComments;

        if (this.props.source === "AlertHistory" && tableAlerts.length > 100) {
            tableAlerts = this.getPage(tableAlerts);
        }


        let alertsContent = tableAlerts.length/*props.alertList.length*/ ?
            <tbody>
                {tableAlerts.map((alert, index) =>
                    [<tr className="alerts-table-row" key={`${alert.alertId}-tableRow`} data={index} value={index} onClick={() => this.onAlertClick(index, alert.alertId)}>
                        <td style={{ padding: '16px 0px 16px 2px', borderLeft: '1px solid #ebeef0' }}>
                            {this.getAlertIndicator(alert, assetComments)}
                        </td>
                        <td style={{ textAlign: 'center' }}>{this.getAlertSeverity(alert.typeId)}</td>
                        <td>{this.getCustomerName(alert.alert.organization, alert.alert.organizationName)}</td>
                        {this.props.source == "AlertQuestions" ?
                            null
                            :<td style={{ textAlign: 'center' }}>
                            {consolidateDict[alert.typeId] !== undefined && consolidateDict[alert.typeId][alert.alert.assetId] !== undefined ? consolidateDict[alert.typeId][alert.alert.assetId].length : 0/*consolidateDict[alert.typeId][alert.alert.assetId].length > 1 ? "(" + consolidateDict[alert.typeId][alert.alert.assetId].length + ")" : null*/}
                        </td>}
                        <td>{alert.typeName}</td>
                        <td style={{ maxWidth: '400px' }}>{alert.alertMessage}</td>
                        <td>{this.getStartDate(alert.alert.startTime)}</td>
                        <td>{this.getEndDate(alert.alert.endTime)}</td>
                        <td className="alerts-td-right">
                            {this.state.selected == index ? <ArrowUpIcon className="db-blue-reverse" style={{ borderRadius: '12px' }} /> : <ArrowDownIcon className="db-blue db-blue-bg" style={{ borderRadius: '12px' }} />}
                        </td>
                    </tr>,
                    this.state.selected == index && (this.getExpandContent(index, alert))
                ])}

            </tbody>
            : <tbody>
                <tr>
                    <td>No Alerts!</td>
                </tr>
            </tbody>;

        let arrowIcon = this.state.columnSort.enabled ?
            (this.state.columnSort.reverse ?
                <LongArrowDownIcon style={{ height: '16px', width: '15px', paddingBottom: '2px' }} />
                : <LongArrowUpIcon style={{ height: '16px', width: '15px', paddingBottom: '2px' }} />)
            : null;

        let custIcon = this.state.columnSort.enabled && this.state.columnSort.type === "CUSTOMER" ?
            <span style={{ height: '15px', paddingLeft: '3px' }}>
                {arrowIcon}
            </span>
            : null;
        let startIcon = this.state.columnSort.enabled && this.state.columnSort.type === "START" ?
            <span style={{ height: '15px', paddingLeft: '3px' }}>
                {arrowIcon}
            </span>
            : null;
        let endIcon = this.state.columnSort.enabled && this.state.columnSort.type === "END" ?
            <span style={{ height: '15px', paddingLeft: '3px' }}>
                {arrowIcon}
            </span>
            : null;
        let alertIcon = this.state.columnSort.enabled && this.state.columnSort.type === "ALERT" ?
            <span style={{ height: '15px', paddingLeft: '3px' }}>
                {arrowIcon}
            </span>
            : null;
        let problemIcon = this.state.columnSort.enabled && this.state.columnSort.type === "PROBLEM" ?
            <span style={{ height: '15px', paddingLeft: '3px' }}>
                {arrowIcon}
            </span>
            : null;
        let countIcon = this.state.columnSort.enabled && this.state.columnSort.type === "COUNT" ?
            <span style={{ height: '15px', paddingLeft: '3px' }}>
                {arrowIcon}
            </span>
            : null;
        let severityIcon = this.state.columnSort.enabled && this.state.columnSort.type === "SEVERITY" ?
            <span style={{ height: '15px', paddingLeft: '3px' }}>
                {arrowIcon}
            </span>
            : null;

        let alertIndicatorIcon = this.state.columnSort.enabled && this.state.columnSort.type === "ALERTINDICATOR" ?
            this.state.alertIndicatorSort === AlertIndicatorSort.NewAlerts
                ? 
                    <span style={{ height: '15px', color: 'rgba(0, 0, 0, 0.6)' }}>
                        <CircleIcon style={{ padding: '5px', color: 'cornflowerBlue' }} />
                    </span>
                :
                this.state.alertIndicatorSort === AlertIndicatorSort.QuestionAsked
                ?
                    <span style={{ height: '15px', color: 'rgba(0, 0, 0, 0.6)' }}>
                        <CircleIcon style={{ padding: '5px', color: 'red' }} />
                    </span>
                :
                this.state.alertIndicatorSort === AlertIndicatorSort.QuestionAnswered || this.state.alertIndicatorSort === AlertIndicatorSort.NewComments
                ?
                        <span style={{ height: '15px', color: 'rgba(0, 0, 0, 0.6)' }}>
                            <CircleIcon style={{ padding: '5px', color: 'green' }} />
                        </span>
                        :
                        <span style={{ height: '15px', color: 'rgba(0, 0, 0, 0.6)' }}>
                            <CircleIcon style={{ padding: '5px', color: 'transparent' }} />
                        </span>
            : <span style={{ height: '15px', color: 'rgba(0, 0, 0, 0.6)' }}>
                <CircleIcon style={{ padding: '5px', color: 'transparent' }} />
            </span>;

        let countColumn = this.props.source === "AlertQuestions" ? null :
            <th>
                <div style={{ textAlign: 'center' }} onClick={() => this.onColumnClick("COUNT")}>
                    <span style={{ cursor: 'pointer' }}>COUNT</span>
                    {countIcon}
                </div>
            </th>

                return (
            <div style={{ margin: '20px 30px' }}>

                <div>
                    <table className="table-hover" style={{ width: '100%' }}>

                        <thead>
                            <tr className="alerts-table-row">
                                <th className="table-shrink" style={{ textAlign: 'center', padding: '10px 0px' }}>
                                    <div onClick={() => this.onColumnClick("ALERTINDICATOR")}>
                                        <span style={{ cursor: 'pointer' }}>
                                            {alertIndicatorIcon}
                                        </span>
                                    </div>
                                </th>
                                <th className="table-shrink" style={{ textAlign: 'center', padding: '10px 0px', minWidth: '80px' }}>
                                    <div onClick={() => this.onColumnClick("SEVERITY")}>
                                        <span style={{ cursor: 'pointer' }}>SEVERITY</span>
                                        {severityIcon}
                                    </div>
                                </th>
                                <th>
                                    <div onClick={() => this.onColumnClick("CUSTOMER")}>
                                        <span style={{ cursor: 'pointer' }}>CUSTOMER</span>
                                        {custIcon}
                                    </div>
                                </th>
                                {countColumn}
                                <th>
                                    <div onClick={() => this.onColumnClick("ALERT")}>
                                        <span style={{ cursor: 'pointer' }}>ALERT</span>
                                        {alertIcon}
                                    </div>
                                </th>
                                <th>
                                    <div onClick={() => this.onColumnClick("PROBLEM")}>
                                        <span style={{ cursor: 'pointer' }}>PROBLEM</span>
                                        {problemIcon}
                                    </div>
                                </th>

                                <th>
                                    <div onClick={() => this.onColumnClick("START")}>
                                        <span style={{ cursor: 'pointer' }}>START</span>
                                        {startIcon}
                                    </div>
                                </th>
                                <th>
                                    <div onClick={() => this.onColumnClick("END")}>
                                        <span style={{ cursor: 'pointer' }}>END</span>
                                        {endIcon}
                                    </div>
                                </th>
                                <th className="table-shrink" style={{ textAlign: 'center', padding: '10px 10px 10px 5px' }}></th>
                            </tr>
                        </thead>

                        {alertsContent}

                    </table>
                </div>
            </div>
        );
    } 

    renderContent() {
        const { classes } = this.props;
        let equipCriticalCount = this.props.tableObj !== null && equipmentAlertsList in this.props.tableObj && this.props.tableObj.equipmentAlertsList !== null && this.props.tableObj.equipmentAlertsList !== undefined ? this.props.tableObj.equipmentAlertsList.filter(c => c.severity === "Critical").length : 0;
        let equipWarningCount = this.props.tableObj !== null && equipmentAlertsList in this.props.tableObj && this.props.tableObj.equipmentAlertsList !== null && this.props.tableObj.equipmentAlertsList !== undefined ? this.props.tableObj.equipmentAlertsList.filter(c => c.severity === "Warning").length : 0;
        let deviceCriticalCount = this.props.tableObj !== null && deviceAlertsList in this.props.tableObj && this.props.tableObj.deviceAlertsList !== null && this.props.tableObj.deviceAlertsList !== undefined ? this.props.tableObj.deviceAlertsList.filter(c => c.severity === "Critical").length : 0;
        let deviceWarningCount = this.props.tableObj !== null && deviceAlertsList in this.props.tableObj && this.props.tableObj.deviceAlertsList !== null && this.props.tableObj.deviceAlertsList !== undefined ? this.props.tableObj.deviceAlertsList.filter(c => c.severity === "Warning").length : 0;

        let tableAlerts = this.state.columnAltered ? this.state.columnAlertList : (this.state.tableAltered ? this.state.distinctAlertList : this.props.alertList);
        let tableEquipCriticalCount = this.state.tableEquipList !== null && this.state.tableEquipList !== undefined ? this.state.tableEquipList.filter(c => c.severity == "Critical").length : 0; 
        let tableEquipWarningCount = this.state.tableEquipList !== null && this.state.tableEquipList !== undefined ? this.state.tableEquipList.filter(c => c.severity == "Warning").length : 0; 
        let tableDevCriticalCount = this.state.tableDevList !== null && this.state.tableDevList !== undefined ? this.state.tableDevList.filter(c => c.severity == "Critical").length : 0;
        let tableDevWarningCount = this.state.tableDevList !== null && this.state.tableDevList !== undefined ? this.state.tableDevList.filter(c => c.severity == "Warning").length : 0;

        let tabs = "";
        let totalVals = ""; 
        let tableOptions = null;
        let pages = Math.floor(tableAlerts.length / 100) + 1;

        let tableCountsObj = this.state.tableAltered ? this.state.tableCounts : this.props.startTableCounts;
        let tableTabIndex = !this.state.tableAltered && this.state.tableTabIndex !== this.props.startTabIndex ? this.props.startTabIndex : this.state.tableTabIndex;

        if (this.props.source === "AlertHistory" || this.props.source === "CustomerAlerts") {
            tabs =
                <div className="alerts-top-tabs row no-gutters alerts-tab-wrapper" >
                <div onClick={this.props.serviceTier === '1' ? () => this.doNothing() : () => this.onTableTabClick(0)} >

                        <Card elevation={0}>
                            <CardContent style={{ padding: 'unset' }}>
                                <div className="alerts-tab-item">
                                    <div className="alerts-tab-label">
                                        <NotificationIcon className="alerts-tab-icon" />
                                        Equipment Health
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                        <div className={tableTabIndex === 0 ? "alerts-indicator db-blue-bg2" : 'alerts-indicator'} />
                    </div>

                    <div onClick={() => this.onTableTabClick(1)}>
                        <Card elevation={0}>
                            <CardContent style={{ padding: 'unset' }}>
                                <div className="alerts-tab-item">
                                    <div className="alerts-tab-label">
                                        <SignalIcon className="alerts-tab-icon" />
                                        Device Health
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                        <div className={tableTabIndex === 1 ? "alerts-indicator db-blue-bg2" : 'alerts-indicator'} />
                    </div>
                </div>

            if (this.props.source === "CustomerAlerts") {
                tableOptions =
                    <div className="alerts-tab-wrapper alerts-tab-wrapper-grey">
                        <div className="alerts-tab-label pad-left-45">Table Options</div>
                        <div onChange={e => this.changeTableRadio(e)} className={"pad-left-40 margin-5"}>
                            <div>
                                <input type="radio" value="All" defaultChecked name="alertTableType" />
                                &nbsp; All &nbsp;&nbsp;
                            <input type="radio" value="Critical" name="alertTableType" />
                                &nbsp; Critical &nbsp;&nbsp;
                            <input type="radio" value="Warning" name="alertTableType" />
                                &nbsp; Warning &nbsp;&nbsp;
                        </div>
                        </div>
                    </div>
            }
            else if (this.props.source === "AlertHistory") {
                tableOptions =
                    <div className="alerts-tab-wrapper alerts-tab-wrapper-grey">
                        <div className="alerts-tab-label pad-left-45">Table Options</div>
                        <div onChange={e => this.changeTableRadio(e)} className={"pad-left-40 margin-5"}>
                            <div className={"alerts-wrapper"}>
                                <div>
                                    <input type="radio" value="All" defaultChecked name="alertTableType" />
                                        &nbsp; All &nbsp;&nbsp;
                                    <input type="radio" value="Critical" name="alertTableType" />
                                        &nbsp; Critical &nbsp;&nbsp;
                                    <input type="radio" value="Warning" name="alertTableType" />
                                        &nbsp; Warning &nbsp;&nbsp;
                                </div>
                                <div className={"display-flex"}>
                                    <span>
                                        <div className={"text-center"}>
                                            <div className="margin-left-10">Start:</div>
                                            <DatePicker
                                                customInput={<input className="txt-detail datepicker" disabled={this.state.disableGraphOptions} />}
                                                id="startDate"
                                                name="startDate"
                                                selected={this.state.startTime.toDate()}
                                                onChange={date => { this.onChangeStart(date); }}
                                                dateFormat="MM/dd/yyyy h:mm:ss aa"
                                                timeIntervals={15}
                                                showTimeSelect
                                                popperPlacement="top-end"
                                                popperProps={{
                                                    positionFixed: true
                                                }}
                                            />
                                        </div>
                                    </span>
                                    <span>
                                        <div className={"text-center"}>
                                            <div className="margin-left-10">End:</div>
                                            <DatePicker
                                                customInput={<input className="txt-detail date-picker" disabled={this.state.disableGraphOptions} />}
                                                id="endDate"
                                                name="endDate"
                                                selected={this.state.endTime.toDate()}
                                                onChange={date => { this.onChangeEnd(date); }}
                                                dateFormat="MM/dd/yyyy h:mm:ss aa"
                                                timeIntervals={15}
                                                showTimeSelect
                                                popperPlacement="top-end"
                                                popperProps={{
                                                    positionFixed: true
                                                }}
                                            />
                                        </div>
                                    </span>
                                    <button className="secondary-btn btn-small alerts-tab-btn" onClick={this.updateSearch} disabled={!this.state.searchChanged}>Update Search</button>
                                </div>
                                <div className={"display-flex"} >
                                    <button className="secondary-btn btn-small alerts-tab-btn" onClick={this.prevPage} disabled={this.state.pageNum === 1}>Prev Page</button>
                                    <p className={"margin-bottom-10 margin-top-10 pad-0-10"}>
                                        Page {this.state.pageNum} of {pages}
                                    </p>
                                    <button className="secondary-btn btn-small alerts-tab-btn" onClick={this.nextPage} disabled={this.state.pageNum === pages} >Next Page</button>
                                </div>
                            </div>
                        </div >
                    </div >
            }
        }
        else if (this.props.source === "AlertQuestions") {
            tabs = null;
            tableOptions = null;
        }
        else if (this.props.source === "TriageAlerts") {
            totalVals =   
                <div className="alerts-top-tabs alerts-tab-wrapper">
                    <div className="btn-right margin-right-35">
                        <Tooltip title="This is the total number of alerts in your system in each state between including ‘job scheduled’, ‘awaiting job creation,’ and ‘silenced’" enterDelay={100} leaveDelay={800} className={"alerts-tooltip"}>
                            <IconButton
                                aria-label="description"
                                aria-controls="menu-faves"
                                aria-haspopup="false"
                                onClick={null}
                            >
                                <InfoIcon className={"alerts-info-icon"} />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <div className="row no-gutters">
                        <div className="col"></div>
                        <div className="pad-top-15 text-center col-3">
                            <p>Total Critical Equipment Alerts: &nbsp;<b>{equipCriticalCount}</b></p>
                            <p>Total Warning Equipment Alerts: &nbsp;<b>{equipWarningCount}</b></p>
                        </div>
                        <div className="pad-top-15 text-center col-3 offset-2">
                            <p>Total Critical Device Alerts: &nbsp;<b>{deviceCriticalCount}</b></p>
                            <p>Total Warning Device Alerts: &nbsp;<b>{deviceWarningCount}</b></p>
                            </div>
                        <div className="col"></div>
                    </div>
                </div>;

            tabs =
                <div className="alerts-top-tabs row no-gutters alerts-tab-wrapper" >
                <div onClick={this.props.serviceTier === '1' ? () => this.doNothing() : () => this.onTableTabClick(0)}>

                        <Card elevation={0}>
                            <CardContent style={{ padding: 'unset' }}>
                                <div className="alerts-tab-item">
                                    <div className="alerts-tab-label">
                                        <NotificationIcon className="alerts-tab-icon" />
                                            Equipment Health
                                        <div className="alerts-badge db-red-bg2">{tableCountsObj.equipCritical} Critical</div>
                                        <div className="alerts-badge db-orange-bg2">{tableCountsObj.equipWarning} Warning</div>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                        <div className={tableTabIndex === 0 ? "alerts-indicator db-blue-bg2" : 'alerts-indicator'} />
                    </div>

                    <div onClick={() => this.onTableTabClick(1)}>
                        <Card elevation={0}>
                            <CardContent className={"pad-unset"}>
                                <div className="alerts-tab-item">
                                    <div className="alerts-tab-label">
                                        <SignalIcon className="alerts-tab-icon" />
                                            Device Health
                                        <div className="alerts-badge db-teal-bg2">{tableCountsObj.devCritical} Critical</div>
                                        <div className="alerts-badge db-purple-bg2">{tableCountsObj.devWarning} Warning</div>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                        <div className={tableTabIndex === 1 ? "alerts-indicator db-blue-bg2" : 'alerts-indicator'} />
                    </div>
                </div>

            tableOptions =
                <div className="alerts-tab-wrapper alerts-tab-wrapper-grey">
                    <div className="alerts-tab-label pad-left-45" >Table Options</div>
                    <div className={"pad-left-40 margin-5"} onChange={e => this.changeTableRadio(e)}>
                        <div className={"alerts-tab-options"}>
                            <input type="radio" value="All" defaultChecked name="alertTableType" />
                                    &nbsp; All &nbsp;&nbsp;
                                <input type="radio" value="Critical" name="alertTableType" />
                                    &nbsp; Critical &nbsp;&nbsp;
                                <input type="radio" value="Warning" name="alertTableType" />
                                    &nbsp; Warning &nbsp;&nbsp;
                                <span className={"margin-left-15 alerts-tab-select"} >
                                    <Select
                                        styles={this.customStyles}
                                        closeMenuOnSelect={true}
                                        components={animatedComponents}
                                        options={activeOptions}
                                        onChange={this.changeActiveFilter}
                                        defaultValue={this.state.activeFilter}
                                        value={this.state.activeFilter}
                                    />
                                </span>

                                <span className={"alerts-tab-select"}>
                                    <Select
                                        styles={this.customStyles}
                                        closeMenuOnSelect={true}
                                        components={animatedComponents}
                                        options={scheduleOptions}
                                        onChange={this.changeScheduledFilter}
                                        defaultValue={this.state.scheduledFilter}
                                        value={this.state.scheduledFilter}
                                    />
                                </span>

                                <span className={"alerts-tab-select"}>
                                    <Select
                                        styles={this.customStyles}
                                        closeMenuOnSelect={true}
                                        components={animatedComponents}
                                        options={silencedOptions}
                                        onChange={this.changeSilencedFilter}
                                        defaultValue={this.state.silencedFilter}
                                        value={this.state.silencedFilter}
                                    />
                                </span>

                                <span className={"alerts-tab-select-wide"}>
                                    <Select
                                        styles={this.customStyles}
                                        closeMenuOnSelect={true}
                                        components={animatedComponents}
                                        isMulti
                                        options={this.props.alertMessaging}
                                        getOptionLabel={this.getOptionLabel}
                                        getOptionValue={this.getOptionValue}
                                        onChange={this.changeAlertTypesFilter}
                                        value={this.state.selectedTypes}
                                        placeholder="All Alert Types"
                                    />
                                </span>
                            <button className="alerts-tab-btn secondary-btn btn-small" onClick={this.onApplyFiltersClick} disabled={this.state.filterChanged == true ? false : true}>Apply Filters</button>
                        </div>
                    </div >
                </div >
        }

        let tableContents = this.state.tableLoading || this.props.alertTableLoading ?
            <Loader />
            : this.renderTable();

        let showAllBtn = this.props.showAllBtn ?
            <div className="row no-gutters alerts-tab-wrapper pad-bottom-15">
                <div className="show-all-btn btn-right">
                    <button className={this.props.showAllAlerts ? "btn-150 primary-btn btn-large" : "btn-150 secondary-btn btn-large"} onClick={this.onShowAllClick}>Show All</button>
                </div>
            </div> : null;
        
        return (
            <div>
                {showAllBtn}
                {totalVals}
                {tabs}
                {tableOptions}
                <div>
                    {tableContents}
                </div>
            </div>
        );
    }

    render() {
        const { classes } = this.props;

        let contents = this.props.loading ?
            <Loader />
            : this.renderContent();

        return (
            <div>
                {contents}

                <AskUserModal show={this.state.showAddAlertsToJobConfirm} onYes={this.onAddToJob} onNo={this.onContinueExistingJobFlow} onClick={this.showAddAlertsToJobModal} message={this.state.modalMessage} title={this.state.modalTitle}></AskUserModal>

                <CreateJobModal show={this.state.showCreateJobConfirm} onClick={this.showCreateJobModal} title={this.state.modalTitle}
                    editAlert={this.state.editAlert} createJob={this.state.currentOpportunity}
                    onSave={this.onCreateJob} onPriorityOpportunityChange={this.onPriorityOpportunityChange}
                    onCommentsChange={this.onCommentsChange} onSubjectChange={this.onSubjectChange}
                    hasServiceTitan={this.props.hasServiceTitan} followUpDate={this.state.dpFollowUpDate} onFollowUpDateChange={this.onFollowUpDateChange}
                    jobTypeId={this.state.jobTypeId} onJobTypeChange={this.onJobTypeChange} jobTypesList={this.props.jobTypesList}
                    campaignId={this.state.campaignId} onCampaignChange={this.onCampaignChange} campaignIdsList={this.props.campaignIdsList}
                    selectedTags={this.state.selectedTags} onChangeTags={this.onChangeTags} tagsList={this.props.tagsList}
                    callReasonId={this.state.callReasonId} onCallReasonChange={this.onCallReasonChange} callReasonsList={this.props.callReasonsList}
                    locationList={this.props.locationList} locationRawList={this.props.locationRawList} locationId={this.props.locationId} onlocationChange={this.props.onlocationChange}
                    selectedLocation={this.props.selectedLocation} onLocationObjChange={this.props.onLocationObjChange} onLocationSearch={this.props.onLocationSearch} changeLocationId={this.props.changeLocationId}
                    selectedCustomer={this.props.selectedCustomer} customerRawList={this.props.customerRawList} customerList={this.props.customerList} customerID={this.props.customerID} loadingCreateJob={this.props.loadingCreateJob}
                    isInValidCustomerId={this.props.isInValidCustomerId} onSTLoading={this.state.onSTLoading} onResetClick={this.props.onResetClick }
		     >
                </CreateJobModal>

                <ConfirmationModal show={this.state.showCloseAlertConfirm} onYes={this.onCloseAlert} onClick={this.showCloseAlertModal} message={this.state.modalMessage} title={this.state.modalTitle}></ConfirmationModal>
                <ConfirmationModal show={this.state.showUnseenConfirm} onYes={this.onMarkAlertsUnseen} onClick={this.showUnseenModal} message={this.state.modalMessage} title={this.state.modalTitle}></ConfirmationModal>
                <SummarizedAlertsModal show={this.state.showSummarizedAlerts} onClick={this.showSummarizedAlertsModal} alertList={this.getSummarizedAlerts()} ></SummarizedAlertsModal>
                <SilenceAlertModal show={this.state.showSilenceAlertConfirm} onConfirm={this.onSilenceAlertConfirm} onClick={this.showSilenceAlertModal} alertPriority={this.state.editAlert.priorityName}/>
                <ConfirmationModal show={this.state.showUnsilenceAlertConfirm} onYes={this.onUnsilenceAlert} onClick={this.showUnsilenceAlertModal} message={this.state.modalMessage} title={this.state.modalTitle}></ConfirmationModal>
                <ConfirmationModal show={this.state.isLeadfailurePopupShow} onYes={this.onCreateJobwithoutLead} onClick={this.showLeadfailurePopupModal} message={leadFailureMsg} title={leadfailurtoCreateJobTital}></ConfirmationModal>

                <CommentsModal show={this.state.showCommentsConfirm} onClick={this.showCommentsModal} conversation={this.state.conversation} alert={this.state.currentAlert} asset={this.state.currentAsset} message={this.state.modalMessage} 
                    tabIndex={!this.state.tableAltered && this.state.tableTabIndex != this.props.startTabIndex ? this.props.startTabIndex : this.state.tableTabIndex} addConversationToAlert={this.addConversationToAlert} addTagToAlert={this.addTagToAlert}
                    userOrg={this.props.userOrg} resetConvo={this.resetConvo} addConversationToAsset={this.addConversationToAsset} alertComment={this.state.alertComment} updateAlertComment={this.updateAlertComment} conversationId={this.state.conversationId}
                    commentAlert={this.state.commentAlert} commentType={"ALERT"} source={this.props.source} forceReload={this.externalReload} userName={this.props.userName} updateAlertsList={this.updateAlertsList}></CommentsModal>

                <Snackbar open={this.state.showMessage} message={this.state.message} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={6000} />
                </div>
        );
    }
}

export default TriageAlertsTable;
