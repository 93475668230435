
/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component, useEffect, useState} from "react";
import DatePicker from "react-datepicker";
import PropTypes from "prop-types";

function JobRightPanel({ jobStartClicked, jobEndClicked, onClick, onClick1, onClick2, selected, selected1 }) {
    
    let startButton = !jobStartClicked ?
            <button
                className={"secondary-btn btn-small"}
                onClick={onClick} style={{marginTop: ".7em"}}>
                Start Job
            </button>
            : null;

        let resetButton = jobStartClicked ?
            <button
                className={"secondary-btn btn-small"}
                onClick={onClick1} style={{marginTop: ".7em"}}>
                Reset Job
            </button>
            : null;

        let closeButton = jobStartClicked ?
            <button
                className={"secondary-btn btn-small"}
                onClick={onClick2} disabled={jobEndClicked} style={{marginTop: ".7em"}}>
                End Job
            </button>
            : null;
            
        let startRow = jobStartClicked ?
            <div className="row no-gutters">
                <div className="col-sm-3 col-md-6 col-lg-3 col-xl-2">
                    <div style={{marginLeft: "10px"}}>Start Job:</div>
                </div>
                <div className="col-sm-9 col-md-12 col-lg-9 centered" style={{textAlign: "center"}}>
                    <DatePicker autoComplete="off"
                                customInput={<input className="txt-detail" style={{
                                    maxWidth: "274px",
                                    textAlign: "center",
                                    paddingRight: "18px"
                                }}/>}
                                id="startDate"
                                name="startDate"
                                selected={selected}
                                onChange={() => {}}
                                dateFormat="MM/dd/yyyy h:mm:ss aa"
                                timeIntervals={15}
                                showTimeSelect
                                popperPlacement="top-end"
                                popperProps={{
                                    positionFixed: true
                                }}
                    />
                </div>
            </div>
            : null;

        let closeRow = jobEndClicked ?
            <div className="row no-gutters">
                <div className="col-sm-2 col-md-6 col-lg-3 col-xl-2">
                    <div style={{marginLeft: "10px"}}>End Job:</div>
                </div>
                <div className="col-sm-9 col-md-12 col-lg-9 centered" style={{textAlign: "center"}}>
                    <DatePicker autoComplete="off"
                                customInput={<input className="txt-detail" style={{
                                    maxWidth: "274px",
                                    textAlign: "center",
                                    paddingRight: "18px",
                                    marginBottom: "0px"
                                }}/>}
                                id="endDate"
                                name="endDate"
                                selected={selected1}
                                onChange={() => {}}
                                dateFormat="MM/dd/yyyy h:mm:ss aa"
                                timeIntervals={15}
                                showTimeSelect
                                popperPlacement="top-end"
                                popperProps={{
                                    positionFixed: true
                                }}
                    />
                </div>
            </div>
            : null;
            
            let startCol = jobStartClicked ?
                <div className="col-4">
                    {startRow}
                </div>
                : null;
                
            let closeCol = jobEndClicked ?
                <div className="col-4">
                    {closeRow}
                </div>
                : null;
            
    return (
        <div className="row no-gutters centered">
            {startCol}
            {closeCol}
            <div>
                {startButton}
                {resetButton}
                {closeButton}
            </div>
            
        </div>
    )

}

export default JobRightPanel