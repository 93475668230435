/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import "../../CSS/DataModal.css";
import ArrowRightIcon from '@mui/icons-material/ArrowRightAlt';
import FullLoader from "../Loader/FullLoader";

class PublishModal extends Component {

    onClick = e => {
        this.props.onClick && this.props.onClick(e);
    };

    renderChangedContent() {
        return (
            <div className="row no-gutters">
                <div className="col-5">
                    <div className="row no-gutters">
                        <div className="col-11 offset-1">
                            <div className="white-container" style={{ border: "1px solid #ebeef0", padding: '10px 0px' }}>
                                {this.props.originalProps.map((ogProp, index) =>
                                    <div className="row no-gutters" key={`prop-${index}`}>
                                        <div className="col-8" style={{ textAlign: 'right', paddingRight: '5px' }}>
                                            {ogProp.name}:
                                                </div>
                                        <div className="col-4" style={{ textAlign: 'left', paddingLeft: '5px' }}>
                                            {ogProp.value}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-2">
                    <ArrowRightIcon style={{ height: '50px', width: '50px', marginTop: '30px' }} />
                </div>

                <div className="col-5">
                    <div className="row no-gutters">
                        <div className="col-11">
                            <div className="white-container" style={{ border: "1px solid #ebeef0", padding: '10px 0px' }}>
                                {this.props.editProps.map((prop, index) =>
                                    <div className="row no-gutters" key={`prop-${index}`}>
                                        <div className="col-8" style={{ textAlign: 'right', paddingRight: '5px' }}>
                                            {prop.name}:
                                                </div>
                                        <div className={this.props.changedProps.includes(prop.name) ? "col-4 edited-publish" : "col-4"} style={{ textAlign: 'left', paddingLeft: '5px', overflow: 'auto' }}>
                                            {prop.value}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {

        if (!this.props.show) {
            return null;
        }
        else {
            let propertyContent = this.props.propsChanged ?
                this.renderChangedContent()
                : <div className="row no-gutters">
                    <div className="col-8 offset-2">
                        <div className="white-container" style={{ border: "1px solid #ebeef0", padding: '10px 0px' }}>
                            {this.props.assetProps.map((prop, index) =>
                                <div className="row no-gutters" key={`prop-${index}`}>
                                    <div className="col-6" style={{ textAlign: 'right', paddingRight: '5px' }}>
                                        {prop.name}:
                                                </div>
                                    <div className="col-6" style={{ textAlign: 'left', paddingLeft: '5px' }}>
                                        {prop.value}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>;

            let loader = this.props.loading ?
                <FullLoader/>
                : null;

                    return (
                        <div className="modal" style={{ display: this.props.show ? "block" : "none" }}>
                            <div className="modal-wrapper"
                                style={{
                                    transform: this.props.show ? 'translateY(0vh)' : 'translateY(-100vh)',
                                    opacity: this.props.show ? '1' : '0'
                                }}>

                                <div className="modal-header">
                                    <h3>Publish Asset</h3>
                                    <span className="close-modal-btn" onClick={e => { this.onClick(e); }}>×</span>
                                </div>

                                <div className="modal-body">
                                    <br />
                                    <p style={{ padding: '0px 70px' }}>
                                        You are about to publish the training session for this furnace. The following properties will be pushed to TwinThread:
                                    </p>

                                    {propertyContent}
                                    {loader}

                                </div>

                                <div className="modal-footer">
                                    <button className="secondary-btn btn-small" onClick={e => { this.onClick(e); }}>Cancel</button>
                                    <button type='button' className="primary-btn btn-small" onClick={this.props.onContinuePublish} disabled={this.props.loading}>Continue</button>
                                </div>
                            </div>
                        </div>
                    );
        }
    }
}


export default PublishModal;
