/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import http from "../http-common";

const controller = "/installfailures";
const installFailuresService = {
    async gettechinstallreports(partnerId) {
        const response = await http.post(`${controller}/gettechinstallreports?partnerId=${partnerId}`);
        return response;
    },

    addinstallfailure(failure) {
        const response = http.post(`${controller}/addinstallfailure`, failure);
        return response;
    }
}

export default installFailuresService;