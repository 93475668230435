/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import { Spinner, Image } from 'react-bootstrap';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { PageView, Event } from "../GoogleAnalytics";
import 'react-tabs/style/react-tabs.css';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/AddCircleOutline';
import RestoreIcon from '@mui/icons-material/RestoreFromTrash';
import RemoveIcon from '@mui/icons-material/RemoveCircle';
import AccountService from '../../services/account.service';
import CustomerService from '../../services/customer.service';
import SelectPartner from '../Sidebar/SelectPartner';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import memoize from "memoize-one";
import userfavoriteService from "../../services/userfavorite.service";
import Loader from "../Loader/Loader";

class ManageFavorites extends Component {
    static displayName = ManageFavorites.name;
    _isMounted = false;

    constructor(props) {
        super(props);

        this.getFavorites = this.getFavorites.bind(this);
        this.filterCustomers = this.filterCustomers.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onSave = this.onSave.bind(this);
        this.openCancelModal = this.openCancelModal.bind(this);
        this.showCancelModal = this.showCancelModal.bind(this);
        this.openSaveModal = this.openSaveModal.bind(this);
        this.showSaveModal = this.showSaveModal.bind(this);
        this.onPartnerSelect = this.onPartnerSelect.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
        this.handleApply = this.handleApply.bind(this);

        this.handleRemoveAsset = this.handleRemoveAsset.bind(this);
        this.handleApplyAsset = this.handleApplyAsset.bind(this);
        this.onAssetSearch = this.onAssetSearch.bind(this);

        this.state = {
            message: "",
            loading: false,
            showCancelConfirm: false,
            showSaveConfirm: false,
            showLogoUpdate: false,
            modalMessage: "",
            modalTitle: "",
            customerList: [], //null
            selectedPartner: -1,

            favoritesList: this.props.favorites,
            newFavoritesList: [],
            addList: [],
            removeList: [],

            assetsList: this.props.favoriteAssets,
            newAssetsList: [],
            addAssetList: [],
            removeAssetList: [],

            searchList: [{ macId: "1234567" }, { macId: "567456" }]
        }
    }

    componentDidMount() {
        this._isMounted = true;
        PageView();

        this.getFavorites(this.props.favorites);
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    memFavorites = memoize(this.getFavorites);

    getFavorites() {
        let customers = this.props.customers;
        customers = this.filterCustomers(customers);
        if (this._isMounted)
            this.setState({
                customerList: customers,
                newFavoritesList: this.state.favoritesList,
                addList: [],
                removeList: [],
                newAssetsList: this.state.assetsList,
                addAssetList: [],
                removeAssetList: []
            });
    }

    showCancelModal() {
        if (this._isMounted)
            this.setState({
                showCancelConfirm: !this.state.showCancelConfirm
            });
            window.scrollTo(0, 0);
    }

    openCancelModal() {
        if (this._isMounted)
            this.setState({ modalMessage: "Are you sure you want to undo all changes?", modalTitle: "Undo Changes" }, () => {
                this.showCancelModal();
            });
    }

    showSaveModal() {
        if (this._isMounted)
            this.setState({
                showSaveConfirm: !this.state.showSaveConfirm
            });
        window.scrollTo(0, 0);
    }

    openSaveModal() {
        if (this._isMounted)
            this.setState({ modalMessage: "Are you sure you want to save all changes?", modalTitle: "Save Changes" }, () => {
                this.showSaveModal();
            });
    }

    async onSave() {
        Event("Save User Favorite", "User saved their updated favorites", "Save User Favorites");
        if (this._isMounted)
            this.setState({ loading: true }, async () => {
                await userfavoriteService.updateuserfavorites(this.props.userName, this.state.newFavoritesList)
                    .then(response => {
                        if (this._isMounted === true) {
                            if (response.data === true) {
                                this.setState({
                                    loading: false,
                                    favoritesList: this.state.newFavoritesList,
                                    showSaveConfirm: !this.state.showSaveConfirm,
                                    message: "Favorites have been saved successfully."
                                });
                                this.props.setFavoritesList(this.state.newFavoritesList);
                            }
                            else {
                                this.setState({
                                    loading: false,
                                    showSaveConfirm: !this.state.showSaveConfirm,
                                    message: "Favorites failed to save."
                                });
                            }

                            setTimeout(() => {
                                if (this._isMounted)
                                    this.setState({
                                        message: ""
                                    });
                            }, 3000);
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    })
            });
    }

    onCancel() {
        Event("User Favorites Cancel", "User clicked cancel button in manage favorites screen", "Cancel User Favorites Changes Clicked"); 
        if (this._isMounted) {
            this.setState({
                favoritesList: this.props.favorites,
                newFavoritesList: this.props.favorites,
                showCancelConfirm: !this.state.showCancelConfirm,
                message: "Changes undone successfully!"
            });

            setTimeout(() => {
                this.setState({
                    message: ""
                });
            }, 3000);
        }
    }

    onAssetSearch(e) {

    }

    handleRemove = (e, index) => {
        const x = this.props.favorites[index]; 

        let removeList = this.state.removeList;
        let newList = this.state.newFavoritesList;

        if (!this.state.removeList.some(f => f.customerId === x.customerId)) {
            removeList = removeList.concat(x);
            newList = newList.filter(f => f.customerId !== x.customerId);
            if (this._isMounted)
                this.setState({ newFavoritesList: newList, removeList: removeList });
        }
        else {
            removeList = removeList.filter(f => f.customerId !== x.customerId);
            newList = newList.concat(x);
            if (this._isMounted)
                this.setState({ newFavoritesList: newList, removeList: removeList });
        }
    };

    handleApply = (e, index) => {
        let customers = this.state.customerList; // ? this.state.customerList : this.props.customers;
        const x = customers[index]; 
        let fave = {
            userName: this.props.userName,
            favoriteTypeId: 1,
            customerId: parseInt(x.organizationId),
            customerName: x.name,
            partnerId: parseInt(x.parentOrganizationId)
        };

        let addList = this.state.addList;
        let newList = this.state.newFavoritesList;

        if (!this.state.addList.some(f => f.customerId === x.organizationId)) {
            addList = addList.concat(fave);
            newList = newList.concat(fave);
            if (this._isMounted)
                this.setState({ newFavoritesList: newList, addList: addList });
        }
        else {
            addList = addList.filter(f => f.customerId !== x.organizationId);
            newList = newList.filter(f => f.customerId !== x.organizationId);
            if (this._isMounted)
                this.setState({ newFavoritesList: newList, addList: addList });
        }
    };

    handleRemoveAsset = (e, index) => {
        const x = this.props.favoriteAssets[index];

        let removeList = this.state.removeAssetList;
        let newList = this.state.newAssetsList;

        if (!this.state.removeList.some(f => f.assetId === x.assetId)) {
            removeList = removeList.concat(x);
            newList = newList.filter(f => f.assetId !== x.assetId);
            if (this._isMounted)
                this.setState({ newAssetsList: newList, removeAssetList: removeList });
        }
        else {
            removeList = removeList.filter(f => f.assetId !== x.assetId);
            newList = newList.concat(x);
            if (this._isMounted)
                this.setState({ newAssetsList: newList, removeAssetList: removeList });
        }
    };

    handleApplyAsset = (e, index) => {
        //todo operate from search

    };

    onPartnerSelect(e) {
        if (this.state.selectedPartner !== e.target.value) {
            this.setState({ loading: true, selectedPartner: e.target.value, customerList: [] }, async () => {
                await CustomerService.get(this.state.selectedPartner > 0 ? this.state.selectedPartner : this.props.partners[0].organizationId)
                    .then(customers => {
                        let customerList = this.filterCustomers(customers);
                        if (this._isMounted) {
                            this.setState({ customerList: customerList }, async () => {
                                this.setState({ loading: false });
                            });
                        }  
                    }).catch(e => {
                        console.log(e)
                    });
            })
        }

    }

    getPartnerName(id) {
        let x = this.props.partners.find(p => p.organizationId === id);
        if (x !== undefined && x !== null && x.company !== null)
            return x.company;
        else
            return "";
    }

    filterCustomers(list) {
        let faves = this.state.favoritesList ? this.state.favoritesList : this.props.favorites; 
        let customers = list; 
        if (faves !== null && faves.length) {
            customers = customers.filter(c => !faves.some(f => f.customerId === c.organizationId));
            return customers; 
        }
        else 
            return customers;
    }

    renderFavorites() {
        let favorites = this.state.favoritesList ? this.state.favoritesList : this.props.favorites; 
        //let customers = this.state.customerList ? this.state.customerList : this.props.customers;

        return (
            <div className="row no-gutters" style={{ display: 'flex' }}>
                <div className="col-sm-12 col-md-6">
                    <div className="white-container" style={{ minHeight: '100%' }}>
                        <h6 className="sectionTitle" id="tabelLabel1">Current Favorites</h6>
                        <div className="table-container-2">
                            <table className='table table-striped table-bordered table-sm table-hover' aria-labelledby="tabelLabel" >
                                <thead>
                                    <tr >
                                        <th>Customer Name</th>
                                        <th>Partner</th>
                                        <th style={{ width: 75 + "px", textAlign: 'center' }}>Remove</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {favorites.map((favorite, index) =>
                                            <tr key={`${index}-${favorite.customerId}`} data={index}>
                                                <td>{favorite.customerName}</td>
                                                <td>{this.getPartnerName(favorite.partnerId)}</td>
                                            <td style={{ textAlign: 'center' }}>
                                                <IconButton key={`${index}-${favorite.customerId}`} onClick={(e) => this.handleRemove(e, index)} style={{ padding: '0px' }}>
                                                    {this.state.removeList.some(f => f.customerId === favorite.customerId) ?
                                                        <RestoreIcon style={{ width: '28px', height: '28px', padding: '3px' }} />
                                                        :<DeleteIcon style={{ width: '28px', height: '28px', padding: '3px' }} />
                                                    }
                                                </IconButton>
                                                </td>
                                            </tr>
                                        )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6">
                    <div className="white-container" style={{ minHeight: '100%' }}>
                            <h6 className="sectionTitle" id="tabelLabel1">Add To Favorites</h6>
                            <div className="table-container-2">
                                <SelectPartner partners={this.props.partners} selectedPartner={this.state.selectedPartner > 0 ? this.state.selectedPartner : this.props.selectedPartner} onPartnerSelect={this.onPartnerSelect} inFaveModal={true} />
                                <table className='table table-striped table-bordered table-sm table-hover' aria-labelledby="tabelLabel" >
                                    <thead>
                                        <tr >
                                            <th>Customer Name</th>
                                            <th style={{ width: 75 + "px", textAlign: 'center' }}>Add</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {this.state.customerList.map((customer, index) =>
                                            <tr key={`${index}-${customer.organizationId}`} data={index}>
                                                <td>{customer.name}</td>
                                                <td style={{ textAlign: 'center' }}>
                                                    <IconButton key={`${index}-${customer.organizationId}`} onClick={(e) => this.handleApply(e, index)} style={{ padding: '0px' }}>
                                                        {this.state.addList.some(f => f.customerId === customer.organizationId) ?
                                                            <RemoveIcon style={{ width: '28px', height: '28px', padding: '3px' }} />
                                                            : <AddIcon style={{ width: '28px', height: '28px', padding: '3px' }} />
                                                        }
                                                    </IconButton>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                    </div>
                </div>
            </div>
        );
    }

    renderAssets() {
        let favorites = this.state.assetsList ? this.state.assetsList : this.props.favoriteAssets;
        //let customers = this.state.customerList ? this.state.customerList : this.props.customers;

        return (
            <div className="row no-gutters" style={{ display: 'flex' }}>
                <div className="col-sm-12 col-md-6">
                    <div className="white-container" style={{ minHeight: '100%' }}>
                        <h6 className="sectionTitle" id="tabelLabel1">Current Favorites</h6>
                        <div className="table-container-2">
                            <table className='table table-striped table-bordered table-sm table-hover' aria-labelledby="tabelLabel" >
                                <thead>
                                    <tr >
                                        <th>Customer</th>
                                        <th>Asset Nickname</th>
                                        <th>Mac</th>
                                        <th style={{ width: 75 + "px", textAlign: 'center' }}>Remove</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {favorites.map((favorite, index) =>
                                        <tr key={favorite.assetId} data={index}>
                                            <td>{favorite.customerName}</td>
                                            <td>{favorite.asset.nickname}</td>
                                            <td>{favorite.asset.macId}</td>
                                            <td style={{ textAlign: 'center' }}>
                                                <IconButton key={favorite.assetId} onClick={(e) => this.handleRemoveAsset(e, index)} style={{ padding: '0px' }}>
                                                    {this.state.removeAssetList.some(f => f.assetId === favorite.assetId) ?
                                                        <RestoreIcon style={{ width: '28px', height: '28px', padding: '3px' }} />
                                                        : <DeleteIcon style={{ width: '28px', height: '28px', padding: '3px' }} />
                                                    }
                                                </IconButton>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6">
                    <div className="white-container" style={{ minHeight: '100%' }}>
                        <h6 className="sectionTitle" id="tabelLabel1">Add To Favorites</h6>
                        <div className="table-container-2">

                            <div className="search-container" style={{ marginBottom: 3 + 'px' }}>
                                <span className="search-message" style={{ marginLeft: 10 + 'px' }}>
                                    Search Assets
                                </span>
                                <div style={{ width: 200 + 'px', height: 30 + 'px', marginLeft: 10 + 'px', marginRight: 0 + 'px', border: '1px solid #ebeef0', borderRadius: '.3em' }}>
                                    <span style={{ display: "inline-flex" }}><span style={{ width: 165 + 'px', margin: 0 + 'px', display: "inline-block" }}>
                                        <ReactSearchAutocomplete
                                            items={this.state.searchList}
                                            fuseOptions={{ threshold: "0.2", distance: "2", ignoreLocation: true, keys: ["macId"] }}
                                            onSelect={this.onAssetSearch}
                                            onFocus={this.handleOnFocus}
                                            showIcon={false}
                                            placeholder="Search Assets"
                                            styling={{
                                                height: 28 + 'px',
                                                width: 150 + 'px',
                                                border: 0 + 'px',
                                                borderRadius: '.3em',
                                                placeholderColor: "black",
                                                color: "black"
                                            }}
                                        />
                                    </span>
                                        <button disabled style={{ backgroundColor: "white", height: 26 + 'px', border: 0 + 'px', verticalAlign: "top", float: "right" }}>
                                            <Image src={require('../../img/search-icon.png')} style={{ width: 20 + 'px', height: 20 + 'px', display: "inline-block" }} />
                                        </button>
                                    </span>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );

    }

    render() {
        const { classes } = this.props;

        let loader = this.state.loading ?
            <Loader />
            : <div>{null}</div>;

        let contents = this.renderFavorites(); /*<Tabs>
            <TabList style={{ paddingLeft: 15 + "px" }}>
                <Tab>Customers</Tab>
                <Tab>Assets</Tab>
            </TabList>

            <TabPanel>
                {this.renderFavorites()}
            </TabPanel>
            <TabPanel>
                {this.renderAssets()}
            </TabPanel>
        </Tabs>;*/

        return (
            <div sx={(theme)=>({
                [theme.breakpoints.down('md')]: {
                    marginLeft: '-225px !important',
                },
            })}>
                <div className="infoPage">
                    <div className="row no-gutters">
                        <div className="col">
                            <h3 className="pageTitle" id="tableLabel">Manage Favorites</h3>
                        </div>
                        <div className="col pageTitle">
                            <div className="btn-right">
                                <button type="button" className="secondary-btn btn-large" onClick={this.openCancelModal}>Cancel</button>
                                <button type="button" className="primary-btn btn-large" onClick={this.openSaveModal}>Save</button>
                            </div>
                        </div>
                    </div>
                    {contents}
                    {loader}

                    <p className="status-msg">{this.state.message}</p>

                    <ConfirmationModal show={this.state.showCancelConfirm} onYes={this.onCancel} onClick={this.showCancelModal} message={this.state.modalMessage} title={this.state.modalTitle}></ConfirmationModal>
                    <ConfirmationModal show={this.state.showSaveConfirm} onYes={this.onSave} onClick={this.showSaveModal} message={this.state.modalMessage} title={this.state.modalTitle}></ConfirmationModal>
                </div>
            </div>
        );
    }
}

export default ManageFavorites;