import {Component} from "react";
import DataContext from "./DataProvider";

const withDataContext = (WrappedComponent) => {
    class WithDataContext extends Component {
        render() {
            return (
                <DataContext.Consumer>
                    {context => {
                        if(!context) {
                            throw new Error('withDataContext must be used within the data provider.');
                        }
                        return <WrappedComponent {...this.props} context={context} />;
                    }}
                </DataContext.Consumer>
            );
        }
    }
    WithDataContext.displayName = `WithDataContext(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;
    return WithDataContext;
};

export default withDataContext;