/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React from 'react';
import Select from "react-select";
import makeAnimated from "react-select/animated";
const animatedComponents = makeAnimated();

class SelectPartner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            partners: []
        }
    }
    
    onPartnerSelect = (selectedOption) => {
        this.props.onPartnerSelect(selectedOption.organizationId);
    }

    getOptionLabel = (option) => {
        return (option["company"]);
    }

    getOptionValue = (option) => {
        return (option["organizationId"]);
    }
    
    getDefaultPartner(partners) {
        let defaultPartner = partners.find(p => p.organizationId === this.props.selectedPartner);
        if (defaultPartner === null || defaultPartner === undefined) {
            let storageId = localStorage.getItem('currentPartnerId');
            if (storageId !== null && storageId !== undefined && storageId >= 0) {
                defaultPartner = partners.find(p => p.organizationId === storageId);
            }
        }
        return defaultPartner;
    }

    render() {
        if (this.props.partners != null) {

            this.props.partners.sort((a, b) => a.company.localeCompare(b.company, undefined, { numeric: true }));

            let select = <select className="selectpicker" style={{ width: 200 + 'px', height: 30 + 'px', paddingLeft: 13 + 'px', paddingRight: 18 + 'px' }} onChange={this.props.onPartnerSelect} value={this.props.selectedPartner}>
                    {
                        this.props.partners.map((obj, index) =>
                            <option key={`${index}-${obj.organizationId}`} id={`${index}-${obj.organizationId}`} value={obj.organizationId}>{obj.company}</option>
                        )
                    }
                </select>;

            let partnerPicker = this.props.inFaveModal ?
                <div style={{ marginBottom: '10px' }}>
                    <span className="select-message" style={{ paddingLeft: '0px' }}>Select from partner:
                        {select}
                    </span>
                    <br/>
                </div>
                : <div className={"sidebar-select"}>
                    <span className="sidebar-select-message select-message">Partners</span>
                    <span>
                        <Select
                            styles={this.customStyles}
                            closeMenuOnSelect={true}
                            components={animatedComponents}
                            options={this.props.partners}
                            getOptionLabel={this.getOptionLabel}
                            getOptionValue={this.getOptionValue}
                            onChange={this.onPartnerSelect}
                            defaultValue={this.props.partners[0]}
                            value={this.props.partners.find(p => p.organizationId === this.props.selectedPartner)}
                        />
                    </span>
                </div>;

            return (
                <div className="select-container">
                    {partnerPicker}
                </div>
            );
        }
        else {
            return (null);
        }
    }
}
export default SelectPartner;

