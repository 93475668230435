import React, {useState} from 'react';
import OnboardingDashboardCard from './OnboardingDashboardCard';
import BasicHighchartGraph from '../../Graph/BasicHighchartGraph';
import moment from "moment";
import { createOnboardingGraphSettings } from '../../../componentObjects';


function AssetTrainingHistoryCard(props) {
    const { assetType, onboardInfo, onTrainingHistoryClick } = props;

    let onboardingChartData = createOnboardingGraphSettings(assetType);

    const getChartItem = (dataArray, chartData, pointStartMoment) => {
        let trendData = {
            name: chartData.name,
            data: dataArray,
            color: chartData.color,
            dashStyle: 'solid',
            type: 'line',
            pointStart: pointStartMoment,
            pointInterval: 24 * 3600 * 1000,
            opacity: 1,
            marker: {
                enabledThreshold: 0
            }
        };

        return trendData;
    }

    const getChartData = (data) => {
        let chartDataList = [];

        if (data !== undefined && data.length) {
            let pointMoment = moment(data[0].Timestamp).add(moment().utcOffset() * -1, 'minutes').format("l")
            var dt = new Date(pointMoment);
            var pointStartMoment = Date.UTC(dt.getFullYear(), dt.getMonth(), dt.getDate(), dt.getHours(), dt.getMinutes(), dt.getSeconds(), dt.getMilliseconds());

            let trainedArr = data.map((item) => (item.Trained));
            const trainedData = getChartItem(trainedArr, onboardingChartData.trained, pointStartMoment);
            chartDataList = chartDataList.concat(trainedData);

            let failedArr = data.map((item) => (item.Failed));
            const failedData = getChartItem(failedArr, onboardingChartData.failed, pointStartMoment);
            chartDataList = chartDataList.concat(failedData);

            let notEnoughDataArr = data.map((item) => (item.NotEnoughData));
            const notEnoughDataData = getChartItem(notEnoughDataArr, onboardingChartData.notEnoughData, pointStartMoment);
            chartDataList = chartDataList.concat(notEnoughDataData);

        }

        return chartDataList;
    }

    let trainingHistoryContent = 
    <div className="row no-gutters card-row" style={{ textAlign: 'center', minHeight: '110px !important' }}>
        <div className="col" style={{ minHeight: '110px' }}>
            <BasicHighchartGraph chartData={getChartData(onboardInfo.trainingGraphData)} yAxisTitle='Asset Count' />
        </div>
    </div>

    return (
        <div className="col-6">
            <div>
                <OnboardingDashboardCard title={'Asset Training History'} content={trainingHistoryContent} onClick={onTrainingHistoryClick} isGraphCard={true}/>
            </div>
        </div>
    );
}

export default AssetTrainingHistoryCard;