/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import { Spinner, Tooltip, OverlayTrigger, Image } from 'react-bootstrap';

import Snackbar from '@mui/material/Snackbar';

import HomeService from "../../services/home.service";
import AssetService from "../../services/asset.service";

//import ProvisioningService from "../../services/provisioning.service";

//import SelectHome from "../Home/SelectHome";
import CancelModal from "./CancelModal";
import ConfirmCancelModal from "./ConfirmCancelModal";
//import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
//import { Battery } from '@pxblue/react-progress-icons';
import '../../custom.css';

import { PageView, Event, Timing, ModalView } from "../GoogleAnalytics";
import { jsAsset, jsDevice, jsHouse } from "../../componentObjects";
import moment from 'moment';

import AccountService from "../../services/account.service";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import UserAssetList from "../Assets/Hierarchy/UserAssetList";
import ConfirmPasswordModal from "../Account/ConfirmUser/ConfirmPasswordModal";
import billingService from "../../services/billing.service";
import InstallService from "../../services/install.service";
import installService from "../../services/install.service";
import Loader from "../Loader/Loader";

class BillingInfo extends Component {
    static displayName = BillingInfo.name;
    _isMounted = false;

    constructor(props) {
        super(props);

        this.loadInfo = this.loadInfo.bind(this);

        this.onHomeSelect = this.onHomeSelect.bind(this);
        this.onHVACSystemSelect = this.onHVACSystemSelect.bind(this);
        this.getDevices = this.getDevices.bind(this);

        this.onCancelClick = this.onCancelClick.bind(this);
        this.openCancelModal = this.openCancelModal.bind(this);
        this.showCancelModal = this.showCancelModal.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.showConfirmCancelModal = this.showConfirmCancelModal.bind(this);
        this.onConfirmCancel = this.onConfirmCancel.bind(this);
        this.showConfirmationModal = this.showConfirmationModal.bind(this);
        this.cancelAccountDelete = this.cancelAccountDelete.bind(this);
        this.getChild = this.getChild.bind(this);
        this.showPasswordConfirmationModal = this.showPasswordConfirmationModal.bind(this);
        this.checkAccountAssets = this.checkAccountAssets.bind(this);
        
        this.state = {
            message: "",
            showMessage: false,

            loading: false,
            newHouseLoading: false,
            tableLoading: false,
            cancelLoading: false,

            homeList: [],
            hvacList: [],
            assetList: [],
            deviceList: [],
            fullDeviceList: [],
            deviceContracts: [],

            currentCustomerName: "",
            currentHVACSystem: jsHouse,
            currentHome: jsHouse,
            currentInstall: {},
            currentInstallTechStr: "",
            selectedHVACId: "",
            selectedHomeId: "",

            showCancelModal: false,
            showConfirmCancelModal: false,
            needConfirmation: false,
            showConfirmation: false,
            modalTitle: "",
            userassethierarchy: [],
            hasFilter: false,
            isCustomerSupport: localStorage.getItem('isCustomerSupport') === 'true' || false,
        };
    }

    componentDidMount() {
        this._isMounted = true;
        PageView();
        this.loadInfo();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.currentCustomerId !== this.props.currentCustomerId) {
            this.loadInfo();
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
    }

    loadInfo() {
            this.setState({ loading: true }, async () => {
                var startTime = performance.now();
                var name = "";
                var customer = this.props.customers.find(c => c.organizationId == this.props.currentCustomerId);
                if (customer !== undefined && customer !== null)
                    name = customer.name;

                await HomeService.get(this.props.currentCustomerId, false).then(response => {
                    if (this._isMounted)
                        this.setState({ currentCustomerName: name, homeList: response.data }, () => {
                            //console.log("homeList:");
                            //console.log(this.state.homeList);
                            let currHome = jsHouse;
                            let currHomeId = "";
                            if (this.state.homeList.length) {
                                currHome = this.state.homeList[0];
                                currHomeId = currHome.orgId;
                            }
                            //console.log("currentHome:");
                            //console.log(currHome);
                            this.setState({ currentHome: currHome, selectedHomeId: currHomeId }, async () => {
                                if (this.state.currentHome !== jsHouse && this.state.currentHome !== undefined && this.state.currentHome != null) {

                                    await AssetService.gethvacassets(this.state.currentHome.houseAssetId).then(response => {
                                        if (this._isMounted)
                                            this.setState({ hvacList: response.data }, () => {
                                                //console.log("hvacList:");
                                                //console.log(this.state.hvacList);
                                                let currSystem = jsHouse;
                                                let currSysId = "";
                                                if (this.state.hvacList.length) {
                                                    currSystem = this.state.hvacList[0];
                                                    currSysId = currSystem.assetId;
                                                }
                                                this.setState({ currentHVACSystem: currSystem, selectedHVACId: currSysId }, async () => {
                                                    if (this.state.currentHVACSystem !== jsHouse && this.state.currentHVACSystem !== undefined && this.state.currentHVACSystem !== null) {


                                                        await AssetService.get(this.state.currentHVACSystem.assetId).then(response2 => {
                                                            if (this._isMounted)
                                                                this.setState({ assetList: response2.data/*, currentAsset: response2.data[0]*/ }, async () => {
                                                                    await AssetService.getbillingdevices(this.state.currentHVACSystem.assetId, this.state.assetList)
                                                                        .then(response => {
                                                                            if (this._isMounted)
                                                                                this.setState({ deviceList: response.data }, async () => {
                                                                                    var macId = this.getMacId(this.state.assetList);
                                                                                    await InstallService.getinstallbymacid(macId).then(response2 => {
                                                                                        let install = response2;
                                                                                        if (install !== null && install !== undefined) {
                                                                                            let techStr = install.technicianName !== null && install.technicianName !== undefined ? install.technicianName + " (" + install.technicianEmail + ") " + moment(install.startDateTime).format("MM/DD/YYYY hh:mm a") : "";
                                                                                            this.setState({
                                                                                                currentInstall: install, currentInstallTechStr: techStr, loading: false
                                                                                            }, () => {
                                                                                                var elapsedTime = performance.now() - startTime;
                                                                                                Timing("Devices Loading", "loading", elapsedTime, "Customer Devices Loading");
                                                                                            });
                                                                                        }
                                                                                    })
                                                                                    /*this.setState({ loading: false }, () => {
                                                                                        var elapsedTime = performance.now() - startTime;
                                                                                        Timing("Devices Loading", "loading", elapsedTime, "Customer Devices Loading");
                                                                                    });*/
                                                                                })
                                                                        });
                                                                });
                                                        });

                                                    }
                                                    else {
                                                        this.setState({ loading: false }, () => {
                                                            var elapsedTime = performance.now() - startTime;
                                                            Timing("Devices Loading", "loading", elapsedTime, "Customer Devices Loading");
                                                        });
                                                    }
                                                })
                                            });
                                    });
                                }
                                else {
                                    this.setState({ loading: false }, () => {
                                        var elapsedTime = performance.now() - startTime;
                                        Timing("Devices Loading", "loading", elapsedTime, "Customer Devices Loading");
                                    });
                                }
                            })
                        });
                });
            })
    }

    getMacId(assets) {
        if (assets !== null && assets !== undefined && assets.length) {
            if (assets.length > 1) {
                if (assets.filter(a => a.type.trim() !== "Thermostat").length)
                    return assets.filter(a => a.type.trim() !== "Thermostat")[0].mac;
            }
            else
                return assets[0].mac;
        }
    }

    async onHomeSelect(e) {
        let x = e.target.value;

        this.setState({ loading: true, newHouseLoading: true, selectedHomeId: x }, () => {
            this.getDevices(x);
        });
    }

    onHVACSystemSelect(e) {
        var startTime = performance.now();
        Event("Customer HVAC System Selected", "User selected one of the customers HVAC systems in dropdown", "Customer HVAC System Selected");
        var currSys = this.state.hvacList.find(h => h.assetId == e.target.value);
        
        this.setState({
            currentHVACSystem: currSys/*this.state.hvacList[e.target.value]*/, loading: true, newHouseLoading: true
        }, async () => {
            this.setState({ newHouseLoading: false, selectedHVACId: this.state.currentHVACSystem.assetId }, async () => {
                    await AssetService.get(this.state.currentHVACSystem.assetId).then(response2 => {
                        if (this._isMounted)
                            this.setState({ assetList: response2.data/*, currentAsset: response2.data[0]*/ }, async () => {
                                await AssetService.getbillingdevices(this.state.currentHVACSystem.assetId, this.state.assetList)
                                    .then(response => {
                                        if (this._isMounted)
                                            this.setState({ deviceList: response.data }, async () => {
                                                var macId = this.getMacId(this.state.assetList);
                                                await installService.getinstallbymacid(macId).then(response2 => {
                                                    let install = response2;
                                                    if (install !== null && install !== undefined) {
                                                        let techStr = install.technicianName !== null && install.technicianName !== undefined ? install.technicianName + " (" + install.technicianEmail + ") " + moment(install.startDateTime).format("MM/DD/YYYY hh:mm a") : "";
                                                        this.setState({
                                                            currentInstall: install, currentInstallTechStr: techStr, loading: false
                                                        }, () => {
                                                            var elapsedTime = performance.now() - startTime;
                                                            Timing("Devices Loading", "loading", elapsedTime, "Customer Devices Loading");
                                                        });
                                                    }
                                                })
                                            })
                                    });
                            });
                    });
            });
        });
    }

    async getDevices(id) {
        var startTime = performance.now();
        var selectedHome = this.state.homeList.find(house => house.houseAssetId == id);
        this.setState({
            currentHome: selectedHome
        }, async () => {
            if (this.state.currentHome !== jsHouse && this.state.currentHome !== undefined && this.state.currentHome !== null) {
                await AssetService.gethvacassets(this.state.currentHome.houseAssetId).then(response => {
                    if (this._isMounted)
                        this.setState({ hvacList: response.data }, () => {
                            let currSystem = jsHouse;
                            let currSysId = "";
                            if (this.state.hvacList.length) {
                                currSystem = this.state.hvacList[0];
                                currSysId = currSystem.assetId;
                            }
                            this.setState({ currentHVACSystem: currSystem, selectedHVACId: currSysId }, async () => {
                                if (this.state.currentHVACSystem !== jsHouse && this.state.currentHVACSystem !== undefined && this.state.currentHVACSystem !== null) {
                                    await AssetService.get(this.state.currentHVACSystem.assetId).then(response2 => {
                                        if (this._isMounted)
                                            this.setState({ assetList: response2.data/*, currentAsset: response2.data[0]*/ }, async () => {
                                                await AssetService.getbillingdevices(this.state.currentHVACSystem.assetId, this.state.assetList)
                                                    .then(response => {
                                                        if (this._isMounted)
                                                            this.setState({ deviceList: response.data }, async () => {
                                                                var macId = this.getMacId(this.state.assetList);
                                                                await installService.getinstallbymacid(macId).then(response2 => {
                                                                let install = response2;
                                                                    if (install !== null && install !== undefined) {
                                                                        let techStr = install.technicianName !== null && install.technicianName !== undefined ? install.technicianName + " (" + install.technicianEmail + ") " + moment(install.startDateTime).format("MM/DD/YYYY hh:mm a") : "";
                                                                        this.setState({
                                                                            currentInstall: install, currentInstallTechStr: techStr, loading: false, newHouseLoading: false
                                                                        }, () => {
                                                                            var elapsedTime = performance.now() - startTime;
                                                                            Timing("Devices Loading", "loading", elapsedTime, "Customer Devices Loading");
                                                                        });
                                                                    }
                                                                })
                                                            })
                                                    });
                                            });
                                    });
                                }
                                else {
                                    this.setState({ 
                                        selectedHomeId: this.state.currentHome.orgId,
                                        selectedHVACId: "None",
                                        newHouseLoading: false
                                    });
                                }
                            });
                        });
                });
            }
            else {
                this.setState({ 
                    newHouseLoading: false,
                    message: "Error Occured While Getting Customer's Current Home",
                    showMessage: true
                });

                setTimeout(() => {
                    this.setState({
                        message: "",
                        showMessage: false
                    });
                }, 3000);
            };
        });
    }

    getChild(data, arr){ 
        if(data.children.length){ 
            for(let i of data.children) {
                this.getChild(i, arr); 
            }
        } else{
            let name = data.name.substring(0, data.name.indexOf("-"));
            if(!arr.includes(name)) {
                arr.push(name);
            }
        }
    }
    onCancelClick() {
        Event("Cancel Clicked", "User clicked cancel button in BillingInfo", "Cancel Clicked");
        this.setState({ cancelLoading: true, showCancelModal: true, showConfirmation: false, showPasswordConfirmation: false }, async () => {
            let deviceIdsStr = "";
            /*if (this.state.deviceList.length && this.state.deviceList[0].deviceId !== null)
                deviceIdsStr = this.state.deviceList.map(d => d.deviceId).join(',');*/
            if (this.state.userassethierarchy.length) {
                let accumulator = [];
                for (let asset of this.state.userassethierarchy) {
                    this.getChild(asset, accumulator);
                }

                deviceIdsStr = accumulator.map(d => d).join(',');
                if (accumulator) {
                    let devices = accumulator.map(deviceId => ({deviceId: deviceId}));
                    this.setState({fullDeviceList: devices}, async () => {
                        await billingService.getbillingdevicecontracts(this.props.currentCustomerId, deviceIdsStr)
                            .then(response => {
                                if (this._isMounted)
                                    this.setState({deviceContracts: response, cancelLoading: false});
                            })
                            .catch(e => {
                                console.log(e);
                                this.setState({
                                    cancelLoading: false,
                                    message: "Error occured while getting customers Billing Contracts",
                                    showMessage: true
                                });

                                setTimeout(() => {
                                    this.setState({
                                        message: "",
                                        showMessage: false
                                    });
                                }, 3000);
                            });
                    });
                }
            }
        });
    }

    openCancelModal() {
        this.showCancelModal();
    }

    showCancelModal() {
        this.setState({ showCancelModal: !this.state.showCancelModal, showConfirmation: false, showPasswordConfirmation: false }, () => {
            if (this.state.showCancelModal)
                ModalView('cancelBilling');
        });
    }

    onCancel() {
        this.setState({ showCancelModal: false, showConfirmCancelModal: true, showConfirmation: false }, () => {
            ModalView('cancelBillingConfirm');
        });
    }

    showConfirmCancelModal() {
        this.setState({ showConfirmCancelModal: !this.state.showConfirmCancelModal, showConfirmation: false }, () => {
            if (this.state.showConfirmCancelModal)
                ModalView('cancelBillingConfirm');
        });
    }
    
    cancelAccountDelete(){
        this.setState({
            showCancelModal: false, 
            showConfirmCancelModal: false,
            showConfirmation: false
        });
    }

    showConfirmationModal = (needFilter) => {
        this.setState({
            showConfirmation: !this.state.showConfirmation,
            modalTitle: !this.state.showConfirmation ? "Delete Assets" : "",
            hasFilter: needFilter
        }, () => {
            if (this.state.showConfirmation){
               this.checkAccountAssets(this.props.currentCustomerId);
               ModalView("deleteAssetsConfirm");
            }
        });
        window.scrollTo(0, 0);
    }

    showPasswordConfirmationModal = () => {
        this.setState({
            showPasswordConfirmation: !this.state.showPasswordConfirmation,
            showCancelModal: false,
            showConfirmCancelModal: false,
            showConfirmation: false
        }, () => {
            if (this.state.showPasswordConfirmation){
                ModalView("ConfirmPasswordModal");
            }
        });
        window.scrollTo(0, 0);
    }
    checkAccountAssets(id) {
        this.setState({
            waitDelete: true,
            loading: true
        }, async ()=> {
            let fromAssetId = this.state.hasFilter ? this.state.currentHVACSystem.assetId : 0;
            await AssetService.getassethierarchy(id, fromAssetId).then(response => {
                if (response.status === 200 && response.data !== undefined && response.data !== null && Array.isArray(response.data) && response.data.length) {
                    /*let selectChildren = response.data.find(({id}) => id == this.state.currentHome.houseAssetId).children.filter(({id}) => id == this.state.currentHVACSystem.assetId);
                    let filteredData = this.state.hasFilter ? selectChildren : response.data; */
                    this.setState({
                        userassethierarchy: response.data,
                        needConfirmation: true
                    });
                } else {
                    this.setState({
                        message: "Failed to delete the account! Reason: " + response.data,
                        needConfirmation: false,
                        loading: false
                    });
                }
                setTimeout(() => {
                    this.setState({
                        waitDelete: false,
                        loading: false
                    });
                }, 3000);

            })
                .catch(e => {
                    console.log(e);
                });
        });
    }
    
    onConfirmCancel() {
        Event("Cancelled Subscription(s)", "User cancelled billing subscription(s)", "Cancelled Subscription(s)");
            this.setState({ loading: true, showConfirmCancelModal: false }, async () => {
                // todo do cancellation API call here
                await billingService.quitContracts(this.props.currentCustomerId, this.state.fullDeviceList)
                    .then(response => {
                        let message = "";

                        if (response.status === 200 && response.data !== undefined && response.data !== null && response.data !== false) {
                            message = "Successfully updated billing database!";
                            this.setState({
                                message: message,
                                showMessage: true
                            }, async () => {
                                    if (this.state.hasFilter && this.state.fullDeviceList.length === 1){
                                        AccountService.deletehvac(this.props.currentCustomerId)
                                            // implement this when in real environment - untested 
                                            //await AssetService.deleteContractAssetss(this.props.currentCustomerId, this.props.currentCustomerId, this.state.selectedHomeId, this.state.selectedHVACId, this.state.assetList)
                                            .then(response2 => {
                                                if (response2.status === 200 && response2.data !== undefined && response2.data !== null && response2.data === "Success") {
                                                    message = "This System has been Successfully Soft-Deleted!";
                                                    this.setState({
                                                        selectedHomeId: this.state.selectedHomeId,
                                                        selectedHVACId: "",
                                                        currentHVACSystem: jsHouse,
                                                        deviceList: [],
                                                        currentInstallTechStr: "",
                                                        message: message,
                                                        showMessage: true,
                                                        loading: false,
                                                    }, () => { 
                                                        this.loadInfo();
                                                        setTimeout(() => {
                                                            this.setState({
                                                                message: "",
                                                                showMessage: false
                                                            });
                                                        }, 3000);
                                                    });
                                                }
                                                else {
                                                    message = "Failed to delete the account! Reason: " + response2.data;
                                                    this.setState({
                                                        message: message,
                                                        showMessage: true,
                                                        loading: false
                                                    });
                                                }
                                            })
                                            .catch(e => {
                                                console.log(e);
                                            })
                                    }else{
                                        AccountService.deletehvac(this.props.currentCustomerId)
                                            // implement this when in real environment - untested 
                                            //await AssetService.deleteContractAssetss(this.props.currentCustomerId, this.props.currentCustomerId, this.state.selectedHomeId, this.state.selectedHVACId, this.state.assetList)
                                            .then(response2 => {
                                                if (response2.status === 200 && response2.data !== undefined && response2.data !== null && response2.data === "Success") {
                                                    message = "Successfully updated moved assets to DeletedAssets and deleted the user!";
                                                }
                                                else {
                                                    message = "Failed to delete the account! Reason: " + response2.data;
                                                }

                                                this.setState({
                                                    message: message,
                                                    showMessage: true,
                                                    loading: false
                                                });
                                            })
                                            .catch(e => {
                                                console.log(e);
                                            })
                                    }
                            });
                        }
                        else {
                            message = "An unexpected error occured. Please try a second time. If it fails a second time, please contact support";
                            this.setState({ message: message, showMessage: true });
                        }

                        setTimeout(() => {
                            this.setState({
                                message: "",
                                showMessage: false
                            });
                        }, 3000);
                    })
                    .catch(e => {
                        console.log(e);
                    })

                this.setState({
                    loading: false
                });
            });
    }
    getAssetNicknames(names) {
        let nameStr = "";

        if (names.length > 1) {
            for (let i = 0; i < names.length; i++) {
                let name = names[i];
                nameStr = nameStr + name;

                if (i !== names.length - 1)
                    nameStr = nameStr + "\n";
            }
        }
        else
            nameStr = names[0];

        console.log("returning nameStr: " + nameStr); //todo delete 
        return nameStr;
    }

    renderContent() {
        const { classes } = this.props;

        let loader = this.state.tableLoading ?
            <Loader />
            : <div>{null}</div>;

        let tableContent = this.state.deviceList.length ?
            <tbody>
                {this.state.deviceList.map((device, index) =>
                    <tr className="alerts-table-row cancel-table" key={`${device.deviceId}-tableRow`} data={index}
                        value={index}>
                        <td style={{borderLeft: '1px solid #ebeef0'}}>{device.type}</td>
                        <td>{device.deviceId}</td>
                        <td style={{borderRight: '1px solid #ebeef0'}}>{device.assetNicknames.map((name, index) => <span
                            key={`${index}-{name}-name`}>{name}<br/></span>)/*this.getAssetNicknames(device.assetNicknames)*/}</td>
                        <td>
                            {(!this.state.isCustomerSupport) && (<div>
                                {<button
                                    type="submit"
                                    className="secondary-btn btn-small"
                                    onClick={()=>this.showConfirmationModal(true)}
                                >
                                    Cancel Subscription
                                </button>}
                            </div>)}
                        </td>
                    </tr>
                )}
                {/*<tr className="alerts-table-row cancel-table" value={0} onClick={() => this.onDeviceRowClick(0)}>
                    <td style={{ borderLeft: '1px solid #ebeef0' }}>Gateway</td>
                    <td>f8f0057a2b94</td>
                    <td style={{ borderRight: '1px solid #ebeef0' }}>First Floor Furnace<br/>First Floor AC</td>
                </tr>*/}
            </tbody>
            :
            <tbody>
            <tr>
                <td>{this.state.selectedHVACId == "" ? "Select HVAC System to view devices" : "No HVAC System to Select or No Devices to Show!"}</td>
            </tr>
            </tbody>;

        if (this.props.currentCustomerId == -1) {
            return (
                <div>
                    <div style={{ marginLeft: '20px' }}>
                        <p className="status-msg">
                            {this.state.loading || this.state.tableLoading || this.props.loading ? "No customer device information to display" : "Loading Customer Info..."}
                        </p>
                    </div>
                </div>
            );
        }
        else {
            return (
                <div>
                    <div className="white-container">
                        <div className="row no-gutters">
                            <div className="col">
                                <div className="select-container-3" style={{ marginLeft: '20px' }}>
                                    <SelectHome homes={this.state.homeList} onChange={this.onHomeSelect} organizationId={this.state.selectedHomeId}/>
                                    <SelectHVACSystem style={{ width: 300 + 'px' }} hvacList={this.state.hvacList} onChange={this.onHVACSystemSelect} houseAssetId={this.state.selectedHVACId} techStr={this.state.currentInstallTechStr} />
                                </div>
                            </div>
                            {(!this.state.isCustomerSupport) && (
                                <div className="col">
                                    <div className="btn-right" style={{ marginTop: '15px' }}>
                                        {/*<button className="red-btn btn-large" onClick={()=>this.showConfirmationModal(false)} style={{ borderRadius: '0.75rem', padding: '8px 25px', width: '100%' }}>Delete Customer</button>*/}  
                                    </div>
                                </div>)}
                        </div>
                    </div>
                    <div style={{ margin: '20px' }}>

                        <div className="table-container-2">
                            <table className='table-striped table-bordered table-sm table-hover' aria-labelledby="tabelLabel" style={{ width: '100%' }}>
                                <thead>
                                <tr className="alerts-table-row cancel-table">
                                    <th>Type</th>
                                    <th>Device ID</th>
                                    <th>Asset Nicknames</th>
                                    <th>Actions</th>
                                </tr>
                                </thead>
                                {tableContent}
                            </table>
                        </div>
                    </div>

                    <ConfirmationModal
                        show={this.state.showConfirmation}
                        onYes={this.showPasswordConfirmationModal}
                        onClick={this.cancelAccountDelete}
                        message={<UserAssetList data={this.state.userassethierarchy}/>}
                        loading={this.state.loading}
                        title={this.state.modalTitle} />
                    
                    <ConfirmPasswordModal
                        show={this.state.showPasswordConfirmation}
                        onCancelClick={this.showPasswordConfirmationModal}
                        onYes={this.onCancelClick}
                        onLogOff={this.props.onLogOff}/>

                    <CancelModal show={this.state.showCancelModal} loading={this.state.cancelLoading} onClick={this.showCancelModal} onCancel={this.onCancel} customerName={this.state.currentCustomerName} deviceContracts={this.state.deviceContracts} />
                    <ConfirmCancelModal show={this.state.showConfirmCancelModal} onClick={this.showConfirmCancelModal} onConfirmCancel={this.onConfirmCancel} customerName={this.state.currentCustomerName} deviceContracts={this.state.deviceContracts} />
                </div>
            );
        }
    }

    render() {
        const { classes } = this.props;

        let loader = this.state.loading ?
            <Loader />
            : null;

        let contents = this.renderContent();

        return (
            <div sx={(theme)=>({
                [theme.breakpoints.down('md')]: {
                    marginLeft: '-225px !important',
                },
            })}>
                <div className='infoPage'>
                    <h2 className="pageTitle" id="tabelLabel" style={{ padding: '20px 0px 10px 30px' }}>Cancellations</h2>
                    {contents}
                    {loader}

                    <Snackbar open={this.state.showMessage} message={this.state.message} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={6000} />
                </div>
            </div>
        );
    }
}

class SelectHome extends React.Component {
    getPrettyName(name) {
        if (name !== null && name !== undefined) {

            var split = name.split("__");
            var name = split[0].replace("?", "'");

            const indexOfSlash = name.indexOf(' / ');
            if (indexOfSlash !== -1) {
                name = name.substring(indexOfSlash + 3);
            }
            return name;
        }
        else {
            return "";
        }
    }

    render() {
        if (this.props.homes != null) {
            return (
                <div className="select-container-2">
                    <span className="select-message">Select which house<br />
                        <select className="selectpicker" style={{ width: 300 + 'px' }} value={this.props.organizationId} onChange={this.props.onChange}>
                            <option value="" hidden>Select Home...</option>
                            {this.props.homes.map((obj, index) =>
                                <option key={`${index}-${obj.orgId}`} id={`${index}-${obj.orgId}`} value={obj.orgId}>{this.getPrettyName(obj.nickname)}</option>
                            )}
                        </select>
                    </span>
                </div>
            );
        }
        else {
            return (<div className="select-container-2">
                <span className="select-message">Select which house<br />
                    <select className="selectpicker" style={{ width: 300 + 'px' }} >

                    </select>
                </span>
            </div>);
        }
    }
}

class SelectHVACSystem extends React.Component {
    getPrettyName(name) {
        if (name !== null && name !== undefined) {

            var split = name.split("__");
            return split[0];
        }
        else {
            return "";
        }
    }

    render() {
        if (this.props.hvacList != null) {
            let techInfo = this.props.techStr !== "" && this.props.techStr !== null && this.props.techStr !== undefined ?
                <span style={{ paddingLeft: '20px', color: 'slategrey' }}>Installed By: {this.props.techStr}</span>
                : null;

            return (
                <div className="select-container-2">
                    <span className="select-message">Select which HVAC System<br />
                        <select className="selectpicker" style={{ width: 300 + 'px' }} value={this.props.houseAssetId} onChange={this.props.onChange}>
                            <option value={""} hidden>Select HVAC System...</option>
                            {this.props.hvacList.map((obj, index) =>
                                <option key={`${index}-${obj.assetId}`} id={`${index}-${obj.assetId}`} value={obj.assetId}>{this.getPrettyName(obj.name)}</option>
                            )}
                        </select>
                    </span>
                    {techInfo}
                </div>
            );
        }
        else {
            return (null);
        }
    }
}

export default BillingInfo;
