/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';

import axios from "axios";
import CryptoJS from "crypto-js";

import { Spinner } from 'react-bootstrap';
import CssBaseline from '@mui/material/CssBaseline';
import PropTypes from 'prop-types';
import memoize from "memoize-one";

import { Event, PageView, ModalView, Timing } from "../GoogleAnalytics";
import { jsAccount } from '../../componentObjects';

import AccountKeyToolService from "../../services/accesskeytool.service";
import devicecertificateService from "../../services/devicecertificate.service";

class AccessKeyTool extends Component {

    static displayName = AccessKeyTool.name;

    constructor(props) {
        super(props);

        this.generateAccessKey = this.generateAccessKey.bind(this);
        this.validateNewAccessKey = this.validateNewAccessKey.bind(this);
        this.saveNewAccessKey = this.saveNewAccessKey.bind(this);

        this.state = {            
            dataDisplay: "",
            loading: false,
            currentAccessKey: "",
            isKeyValid: false,            
            accessKeyTimeToLive: "",
            newAccessKey: "",
            newIsKeyValid: false,
            newAccessKeyTimeToLive: "",
            newAccessKeySaved: false,
            validateMessage: "",
            saveMessage: ""
        };
    }

    componentDidMount() {
        this._isMounted = true;

        if (this._isMounted === true) {
            this.setState({
                loading: true
            }, () => {
                this.getCurrentAccessKeyInfo();
            });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    memAccessKeyInfo = memoize(this.getCurrentAccessKeyInfo);

    getCurrentAccessKeyInfo() {
        devicecertificateService.getcurrentKey().then(response => {
            if (this._isMounted === true)
                this.setState({ currentAccessKey: response }, () => {

                    AccountKeyToolService.getaccesskeymodeldetails(this.state.currentAccessKey).then(res => {
                        if (this._isMounted === true)
                            this.setState({
                                isKeyValid: res.isValid === true ? "true" : "false", accessKeyTimeToLive: res.daysRemaining, loading: false
                            });
                    });
                });
        });
    }

    generateAccessKey() {
        AccountKeyToolService.generateaccesskey().then(response => {
            this.setState({ newAccessKey: response })
        });
    }

    validateNewAccessKey() {

        if (this.state.newAccessKey !== "" && this.state.newAccessKey !== null) {
            this.setState({ loading: true }, () => {
                AccountKeyToolService.getaccesskeymodeldetails(this.state.newAccessKey).then(res => {
                    if (this._isMounted === true)
                        this.setState({
                            newIsKeyValid: res.isValid === true ? "true" : "false", newAccessKeyTimeToLive: res.daysRemaining, loading: false
                        });
                });
            });
        }
        else {
            this.setState({validateMessage: "You must generate a new key before validating."});
        }
    }

    saveNewAccessKey() {

        if (this.state.newAccessKey !== "" && this.state.newAccessKey !== null && this.state.newIsKeyValid == "true") {
            this.setState({ loading: true }, () => {
                devicecertificateService.saveaccesskey(this.state.newAccessKey).then(res => {
                    if (this._isMounted === true)
                        this.setState({
                            newAccessKeySaved: res.isActive === true ? "true" : "false", loading: false
                        });
                });
            });
        }
        else {
            this.setState({saveMessage: "You must " + (this.state.newAccessKey === "" ? "generate a new key and " : "") + (!this.state.newIsKeyValid ? "validate before saving." : "")});            
        }
    }
        
    render() {

        const { classes } = this.props;


        //if (this.state.loading !== true) {
            return (
                <div sx={(theme)=>({
                    [theme.breakpoints.down('md')]: {
                        marginLeft: '-225px !important',
                    },
                })}>
                    <div className='infoPage pad-left-15'>
                        <h1>Access Key Tool</h1>
                        <p>Welcome to the Access Key Tool</p>

                        <label>Current Access Key</label>
                        <br />
                        <div>{this.state.currentAccessKey}</div>

                        <label>Current Access Key Is Valid:</label>
                        <div>{this.state.isKeyValid}</div>

                        <label>Current Access Key Time To Live:</label>
                        <div>{this.state.accessKeyTimeToLive}</div>

                        <div>====================================</div>

                        <label>Generate New Access Key</label>
                        <div>New AccessKey: {this.state.newAccessKey}</div>                        
                        <button
                            type="button"
                            className="btn-main"
                            onClick={() => this.generateAccessKey() }>
                            Generate
                        </button>

                        <br />

                        <label>Validate New Access Key</label>
                        <br/>
                        <label>New Access Key Is Valid:</label>
                        <div>{this.state.newIsKeyValid}</div>
                        <label>New Access Key Time To Live:</label>
                        <div>{this.state.newAccessKeyTimeToLive}</div>
                        <br />
                        <div>{this.state.validateMessage}</div>
                        <button
                            type="button"
                            className="btn-main"
                            onClick={() => this.validateNewAccessKey() }
                        >
                            Validate
                        </button>

                        <br />

                        <label>Update and Save to Database</label>
                        <div>Key Saved: {this.state.newAccessKeySaved}</div>
                        <br />
                        <div>{this.state.saveMessage}</div>
                        <button
                            type="button"
                            className="btn-main"
                            onClick={() => this.saveNewAccessKey()}
                        >
                            Save
                        </button>

                    </div>
                </div>
            );
        //}
        //else {
        //    return (null);
        //}
    }
}
export default AccessKeyTool;
